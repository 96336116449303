import React, { useEffect, useState } from 'react';
import cn from 'classnames';
import style from './faq.module.scss';
import useFetch from '../../utils/UseFetch';
import { GET_BLOCK_DATA } from '../../utils/Api';

const Faq = () => {
  const [content, setContent] = useState(null);
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const [activeAccordionIndex, setActiveAccordionIndex] = useState(null);

  const getContent = async () => {
    try {
      const response = await useFetch('get', GET_BLOCK_DATA('faq'));

      setContent(JSON.parse(response.fields[0].html));
    } catch (error) {
      console.error(error);
    }
  };

  const tabOnClick = (index) => {
    setActiveTabIndex(index);
    setActiveAccordionIndex(null);
  };

  const accordionOnClick = (index) => {
    if (index === activeAccordionIndex) {
      setActiveAccordionIndex(null);
    } else {
      setActiveAccordionIndex(index);
    }
  };

  useEffect(() => {
    getContent();
  }, []);

  return (
    <div className={cn(style.container)}>
      <div className={cn(style.inner)}>
        <div className={cn(style.uptitle)}>Chicago River Tours</div>
        <div className={cn(style.title)}>{content?.title}</div>
        <div className={cn(style.description)} dangerouslySetInnerHTML={{ __html: content?.description }} />
        <div className={cn(style.tabs)}>
          {content?.blocks?.length
            ? content.blocks.map(({ title, id }, blockIndex) => (
                <div className={cn(style.tab, activeTabIndex === blockIndex && style.active)} key={'tab' + id} onClick={() => tabOnClick(blockIndex)}>
                  {title}
                </div>
              ))
            : null}
        </div>
        {content?.blocks?.length
          ? content.blocks.map(({ list, id: blockId }, blockIndex) => (
              <div className={cn(style.block)} key={'block' + blockId} style={{ display: activeTabIndex === blockIndex ? 'block' : 'none' }}>
                {list.map(({ question, answer, id: questionId }, questionIndex) => (
                  <div className={cn(style.accordion)} key={questionId}>
                    <div className={cn(style.accordionTitle, activeAccordionIndex === questionIndex ? style.active : '')} onClick={() => accordionOnClick(questionIndex)}>
                      {question}
                    </div>
                    <div className={cn(style.accordionContent, activeAccordionIndex === questionIndex ? style.active : '')} dangerouslySetInnerHTML={{ __html: answer }} />
                  </div>
                ))}
              </div>
            ))
          : null}
      </div>
    </div>
  );
};

export default Faq;
