import React from 'react';
import { Marker as LeafletMarker } from 'react-leaflet';
import PropTypes from 'prop-types';
import iconDefault from './iconDefault';
import iconActive from './iconActive';

const Marker = ({ lng, lat, active }) => {
  return <LeafletMarker position={[lat, lng]} icon={active ? iconActive : iconDefault} />;
};

Marker.propTypes = {
  lng: PropTypes.number,
  lat: PropTypes.number,
  active: PropTypes.bool,
};

export default Marker;
