import React from 'react';
import { Link } from 'react-router-dom';
import logoGreen from './logo-green.svg';
import PropTypes from 'prop-types';
import cn from 'classnames';
import style from './logo.module.scss';

const Logo = ({ type }) => {
  let img = null;

  if (type === 'white') {
    img = logoGreen;
  }

  return img ? (
    <Link to="/" className={cn(style.link)}>
      <img src={img} alt="" className={cn(style.img)} />
    </Link>
  ) : null;
};

Logo.propTypes = {
  type: PropTypes.string,
};

Logo.defaultProps = {
  type: 'white',
};

export default Logo;
