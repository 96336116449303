import { useEffect, useState } from 'react';
import { createContainer } from 'unstated-next';
import useFetch from '../../utils/UseFetch';
import { GET_AVAILABLE_CRUISES, GET_BOAT_GROUP_AVAILABLE_CRUISES, GET_BOAT_GROUPS_MAX_CAPACITY, GET_GROUP_MONTH_AVAILABLE_DAYS, GET_MONTH_AVAILABLE_DAYS, GET_SETTINGS } from '../../utils/Api';
import { timeByMinutes } from '../../utils/TimeByMinutes';
import moment from 'moment';
import { usePersistState } from 'persist-state';

const useContainer = () => {
  const [isPrivateAvailable, setIsPrivateAvailable] = usePersistState(false, 'bookingIsPrivateAvailable');
  const [isPublicAvailable, setIsPublicAvailable] = usePersistState(false, 'bookingIsPublicAvailable');
  const [type, setType] = usePersistState(null, 'bookingType');
  const [date, setDate] = usePersistState(null, 'bookingDate');
  const [length, setLength] = usePersistState(null, 'bookingLength');
  const [numberOfSeats, setNumberOfSeats] = usePersistState(null, 'bookingNumberOfSeats');
  const [boatGroup, setBoatGroup] = usePersistState(null, 'bookingBoatGroup');
  const [location, setLocation] = usePersistState(null, 'bookingLocation');
  const [availableCruises, setAvailableCruises] = usePersistState([], 'bookingAvailableCruises');
  const [loadingAvailableCruises, setLoadingAvailableCruises] = usePersistState(false, 'bookingLoadingAvailableCruises');
  const [timeSlots, setTimeSlots] = usePersistState([], 'bookingTimeSlots');
  const [timeSlotsLoading, setTimeSlotsLoading] = usePersistState(false, 'bookingTimeSlotsLoading');
  const [selectedTimeSlot, setSelectedTimeSlot] = usePersistState(null, 'bookingSelectedTimeSlot');
  const [markers, setMarkers] = usePersistState([], 'bookingMarkers');
  const [selectedMarker, setSelectedMarker] = usePersistState(null, 'bookingSelectedMarker');
  const [availableDays, setAvailableDays] = usePersistState([], 'bookingAvailableDays');
  const [availableDaysLoading, setAvailableDaysLoading] = usePersistState(false, 'bookingAvailableDaysLoading');
  const [groupMonthAvailableDays, setGroupMonthAvailableDays] = usePersistState([], 'bookingGroupMonthAvailableDays');
  const [groupMonthAvailableDaysLoading, setGroupMonthAvailableDaysLoading] = usePersistState(false, 'bookingGroupMonthAvailableDaysLoading');
  const [settings, setSettings] = usePersistState(null, 'bookingSettings');
  const [settingsLoading, setSettingsLoading] = useState(true);
  const [lengthSelectData, setLengthSelectData] = usePersistState([], 'bookingLengthSelectData');
  const [seatsSelectData, setSeatsSelectData] = usePersistState(
    Array.from({ length: 8 }).map((_, index) => ({
      id: 700 + index,
      value: index + 1,
      label: (index + 1).toString(),
    })),
    'bookingSeatsSelectData',
  );
  const [typeList, setTypeList] = usePersistState([], 'bookingTypeList');

  const getAvailableDates = async (monthDate) => {
    if (!type) return;
    if (!monthDate) return;

    setAvailableDaysLoading(true);

    const response = await useFetch('get', GET_MONTH_AVAILABLE_DAYS, {
      type,
      monthDate,
    });

    setAvailableDays(response.map(({ date }) => date));
    setAvailableDaysLoading(false);
  };

  const getGroupMonthAvailableDates = async (date) => {
    if (!type) return;
    if (!boatGroup) return;

    setGroupMonthAvailableDaysLoading(true);

    const response = await useFetch('get', GET_GROUP_MONTH_AVAILABLE_DAYS, {
      type,
      monthDate: date || moment(new Date()).format('MM/DD/YYYY'),
      boatGroupId: boatGroup.id,
    });

    setGroupMonthAvailableDays(response.map(({ date }) => date));
    setGroupMonthAvailableDaysLoading(false);
  };

  const getTimeSlots = async () => {
    setTimeSlotsLoading(true);
    setTimeSlots([]);

    const [lengthFrom, lengthTo] = length.split('-');

    const data = {
      boatGroupId: boatGroup.id,
      type: type,
      date: date,
      lengthFrom: +lengthFrom,
      lengthTo: +lengthTo,
    };

    if (type === 'public') {
      data.seats = numberOfSeats;
    }

    const response = await useFetch('get', GET_BOAT_GROUP_AVAILABLE_CRUISES, data);

    if (!response.length) {
      setTimeSlotsLoading(false);
      return;
    }

    const obj = {};

    for (let i = 0; i < response.length; i++) {
      const length = response[i].length;

      if (!length) break;

      if (!obj.hasOwnProperty(length)) {
        obj[length] = [];
      }

      obj[length].push(response[i]);
    }

    setTimeSlots(
      Object.entries(obj).map(([key, list]) => ({
        name: timeByMinutes(key),
        length: +key,
        list,
      })),
    );
    setTimeSlotsLoading(false);
  };

  const getAvailableCruises = async () => {
    if (!length || !type || !date) return;
    if (type === 'public' && !numberOfSeats) return;
    setLoadingAvailableCruises(true);

    const [lengthFrom, lengthTo] = length.split('-');

    const data = {
      type: type,
      date: date,
      lengthFrom: +lengthFrom,
      lengthTo: +lengthTo,
    };

    if (type === 'public') {
      data.seats = numberOfSeats;
    }

    const response = await useFetch('get', GET_AVAILABLE_CRUISES, data);

    const coordinates = [];
    response.forEach(({ location }) => {
      coordinates.push({
        lat: location.coordinates.latitude,
        lng: location.coordinates.longitude,
        active: false,
      });
    });
    setMarkers(coordinates);

    setLoadingAvailableCruises(false);
    setAvailableCruises(response);
  };

  const getMaxNumberOfSeats = async () => {
    try {
      const data = await useFetch('get', GET_BOAT_GROUPS_MAX_CAPACITY, { type: 'public' });

      if (data.maxCapacity) {
        setSeatsSelectData(
          Array.from({ length: +data.maxCapacity }).map((_, index) => ({
            id: 700 + index,
            value: index + 1,
            label: (index + 1).toString(),
          })),
        );
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getSettings = async () => {
    setSettingsLoading(true);

    const data = await useFetch('get', GET_SETTINGS);
    setSettings(data);
    setIsPublicAvailable(data.isPublicAvailable);
    setIsPrivateAvailable(data.isPrivateAvailable);
    setLengthSelectData(
      data.cruiseLengths.map(({ lengthFrom, lengthTo }) => ({
        value: `${lengthFrom}-${lengthTo}`,
        label: timeByMinutes(lengthFrom || 0) === timeByMinutes(lengthTo || 0) ? timeByMinutes(lengthFrom || 0) : `${timeByMinutes(lengthFrom || 0)} - ${timeByMinutes(lengthTo || 0)}`,
        id: lengthFrom + lengthTo,
      })),
    );

    if (data.isPublicAvailable && !data.isPrivateAvailable) {
      setType('public');
      setTypeList([
        {
          id: 601,
          label: 'Public Cruise',
          value: 'public',
        },
      ]);
    } else if (!data.isPublicAvailable && data.isPrivateAvailable) {
      setType('private');
      setTypeList([
        {
          id: 602,
          label: 'Private Cruise',
          value: 'private',
        },
      ]);
    } else {
      setTypeList([
        {
          id: 601,
          label: 'Public Cruise',
          value: 'public',
        },
        {
          id: 602,
          label: 'Private Cruise',
          value: 'private',
        },
      ]);
    }

    setSettingsLoading(false);
  };

  const clear = () => {
    setType(null);
    setDate(null);
    setLength(null);
    setNumberOfSeats(null);
    setBoatGroup(null);
    setLocation(null);
    setAvailableCruises([]);
    setLoadingAvailableCruises(false);
    setTimeSlots([]);
    setSelectedTimeSlot(null);
  };

  useEffect(() => {
    if (!length) return;
    if (!boatGroup) return;
    if (!type) return;
    if (!date) return;
    if (type === 'public' && !numberOfSeats) return;

    setTimeSlots([]);
    getTimeSlots();
  }, [length, boatGroup, type, date, numberOfSeats]);

  useEffect(() => {
    if (type === 'private') {
      setNumberOfSeats(null);
    }
  }, [type]);

  useEffect(() => {
    getMaxNumberOfSeats();
    getSettings();
  }, []);

  useEffect(() => {
    getAvailableDates(moment(new Date()).format('MM/DD/YYYY'));
  }, [type]);

  return {
    isPrivateAvailable,
    setIsPrivateAvailable,
    isPublicAvailable,
    setIsPublicAvailable,

    settings,
    settingsLoading,

    date,
    setDate,
    type,
    setType,
    length,
    setLength,
    numberOfSeats,
    setNumberOfSeats,
    boatGroup,
    setBoatGroup,
    location,
    setLocation,
    markers,
    selectedMarker,
    setSelectedMarker,

    seatsSelectData,
    lengthSelectData,
    typeList,

    availableCruises,
    setAvailableCruises,
    getAvailableCruises,
    loadingAvailableCruises,
    setLoadingAvailableCruises,
    timeSlots,
    selectedTimeSlot,
    setSelectedTimeSlot,
    setTimeSlots,
    timeSlotsLoading,

    availableDays,
    availableDaysLoading,
    getAvailableDates,

    groupMonthAvailableDays,
    groupMonthAvailableDaysLoading,
    getGroupMonthAvailableDates,

    clear,
  };
};

export default createContainer(useContainer);
