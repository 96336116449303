import React, { useEffect, useState } from 'react';
import { PaymentElement, useStripe, useElements, Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import cn from 'classnames';
import style from './paymentForm.module.scss';
import ReservationStore from '../../../store/ReservationStore';
import Button from '../../../componenets/Button';
import PropTypes from 'prop-types';
import Receipt from '../../../componenets/Receipt';

const PaymentFormBlock = ({ callback, purchaseData }) => {
  const stripe = useStripe();
  const elements = useElements();

  const [message, setMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const paymentElementOptions = { layout: 'tabs' };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) return;

    setIsLoading(true);

    const { error, paymentIntent } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: '',
      },
      redirect: 'if_required',
    });

    setIsLoading(false);

    if (error) {
      if (error?.type === 'card_error' || error?.type === 'validation_error') {
        setMessage(error?.message || '');
      } else {
        setMessage('An unexpected error occurred.');
      }
    }

    window.dataLayer?.push('event', 'purchase', { transaction_id: paymentIntent.id, ...purchaseData });

    callback({ transactionId: paymentIntent.id });
  };

  return (
    <>
      <div className={cn(style.formBlock)}>
        <form className={cn(style.form)} noValidate onSubmit={handleSubmit}>
          <div className={cn(style.paymentElement)}>
            <PaymentElement options={paymentElementOptions} />
          </div>
          <div className={cn(style.button)}>
            <Button type="submit" text={isLoading ? 'Loading...' : 'Pay now'} />
          </div>
          {!!message && <div className={cn(style.message)}>{message}</div>}
        </form>
      </div>
      <div className={cn(style.receiptBlock)}>
        <div className={cn(style.receiptBlockTitle)}>Receipt</div>
        <Receipt type="mini" />
      </div>
    </>
  );
};

PaymentFormBlock.propTypes = {
  callback: PropTypes.func.isRequired,
  purchaseData: PropTypes.object,
};

const PaymentForm = ({ callback }) => {
  const reservationStore = ReservationStore.useContainer();
  const [stripePromise, setStripePromise] = useState(null);

  useEffect(() => {
    if (reservationStore?.settings?.stripeApiPublishableKey) {
      setStripePromise(loadStripe(reservationStore.settings.stripeApiPublishableKey));
    }
  }, [reservationStore.settings]);

  if (!stripePromise || !reservationStore.stripeClientSecret) return <div>Loading...</div>;

  const options = {
    locale: 'en',
    clientSecret: reservationStore.stripeClientSecret,
    appearance: {
      theme: 'night',
    },
  };

  const { allCost, promoCode } = reservationStore;
  const purchaseData = {
    value: allCost.total,
    tax: allCost.tax,
    shipping: allCost.bookingFee,
    currency: 'USD',
    coupon: promoCode,
    // items: allCost.addons.map((addon) => ({
    //   item_id: addon.addon,
    //   item_name: addon.addon,
    //   price: addon.addonPrice,
    //   quantity: addon.subtotal / addon.addonPrice,
    // })),
  };

  return (
    <div className={cn(style.container)}>
      <div className={cn(style.inner)}>
        <Elements stripe={stripePromise} options={options}>
          <PaymentFormBlock callback={callback} purchaseData={purchaseData} />
        </Elements>
      </div>
    </div>
  );
};

PaymentForm.propTypes = {
  callback: PropTypes.func.isRequired,
};

export default PaymentForm;
