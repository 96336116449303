import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import style from './insuranceRadio.module.scss';
import Radio from '../Radio';

const InsuranceRadio = ({ label, name, price, description, value, onChange, checked }) => {
  return (
    <label className={cn(style.container)}>
      <div className={cn(style.header)}>
        <div className={cn(style.checkbox)}>
          <Radio label={label} value={value} name={name} onChange={onChange} checked={checked} />
        </div>
        <div className={cn(style.price)}>{price}</div>
      </div>
      <div className={cn(style.description)}>{description}</div>
    </label>
  );
};

InsuranceRadio.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  price: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.string.isRequired,
  checked: PropTypes.string.isRequired,
};

export default InsuranceRadio;
