import React, { useState, useEffect } from 'react';
import cn from 'classnames';
import style from './aboutCycleboats.module.scss';
import Card from 'componenets/Card';
import useFetch from '../../utils/UseFetch';
import { GET_BLOCK_DATA } from '../../utils/Api';
import getBlockDataByKey from '../../utils/GetBlockDataByKey';

const AboutCycleboats = () => {
  const [fields, setFields] = useState([]);

  const getContent = async () => {
    try {
      const response = await useFetch('get', GET_BLOCK_DATA('details'));

      setFields(response.fields);
    } catch (error) {
      console.error(error);
    }
  };

  const getImageSrc = (key) => {
    const relativeSrc = getBlockDataByKey(fields, key)?.image?.contentUrl;

    if (!relativeSrc) return '';

    return process.env.REACT_APP_API_URL + relativeSrc;
  };

  useEffect(() => {
    getContent();
  }, []);

  return (
    <div className={cn(style.container)}>
      <div className={cn(style.inner)}>
        <div className={cn(style.uptitle)}>Chicago River Tours</div>
        <div className={cn(style.title)}>{getBlockDataByKey(fields, 'title')?.text}</div>
        <div className={cn(style.list)}>
          <div className={cn(style.item)}>
            <Card title={getBlockDataByKey(fields, 'card1_title')?.text} description={getBlockDataByKey(fields, 'card1_description')?.html} image={getImageSrc('card1_image')} />
          </div>
          <div className={cn(style.item)}>
            <Card title={getBlockDataByKey(fields, 'card2_title')?.text} description={getBlockDataByKey(fields, 'card2_description')?.html} image={getImageSrc('card2_image')} />
          </div>
          <div className={cn(style.item)}>
            <Card title={getBlockDataByKey(fields, 'card3_title')?.text} description={getBlockDataByKey(fields, 'card3_description')?.html} image={getImageSrc('card3_image')} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutCycleboats;
