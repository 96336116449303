export const personalDetailRules = {
  firstName: {
    required: 'First name is required',
  },
  lastName: {
    required: 'Last name is required',
  },
  email: {
    required: 'Email is required',
    email: 'Not valid email',
  },
  confirmEmail: {
    required: 'Confirm email is required',
    email: 'Not valid email',
    equal: 'Emails must be equal',
  },
  address: {
    required: 'Address is required',
  },
  address2: {},
  city: {
    required: 'City is required',
  },
  state: {
    required: 'State is required',
  },
  phone: {
    required: 'Phone is required',
  },
  country: {
    required: 'Country is required',
  },
};

export const setUpRules = {
  occasion: {
    required: 'Occasion is required',
  },
  foundType: {
    required: 'How did you hear about us name is required',
  },
  cancellationProtection: {},
  insurance: {
    required: 'Insurance is required',
  },
};

export const settingRules = {
  tips: (min, max) => ({
    required: 'Tips is required',
    min: `Tips should be more or equal to ${min}%`,
    max: `Tips should be less or equal to ${max}%`,
    integer: 'Tips must be a integer',
  }),
  agreeTerms: {
    required: 'Required field',
  },
};

export const settingRulesForPublic = {
  tips: (min, max) => ({
    required: 'Tips is required',
    min: `Tips should be more or equal to ${min}%`,
    max: `Tips should be less or equal to ${max}%`,
    integer: 'Tips must be a integer',
  }),
  agreeTerms: {
    required: 'Required field',
  },
};

export const settingRulesForPrivate = {
  tips: (min, max) => ({
    required: 'Tips is required',
    min: `Tips should be more or equal to ${min}%`,
    max: `Tips should be less or equal to ${max}%`,
    integer: 'Tips must be a integer',
  }),
  agreeTerms: {
    required: 'Required field',
  },
  securityDeposit: {
    required: 'Required field',
  },
};
