import React from 'react';
import Lightbox from 'react-image-lightbox';
import PropTypes from 'prop-types';
import 'react-image-lightbox/style.css';

const LightBox = ({ isOpen, setIsOpen, list, activeIndex, setActiveIndex }) => {
  if (!isOpen) return null;

  return (
    <Lightbox
      mainSrc={list[activeIndex]}
      nextSrc={list[(activeIndex + 1) % list.length]}
      prevSrc={list[(activeIndex + list.length - 1) % list.length]}
      onCloseRequest={() => setIsOpen(false)}
      onMoveNextRequest={() => setActiveIndex((activeIndex + 1) % list.length)}
      onMovePrevRequest={() => setActiveIndex((activeIndex + list.length - 1) % list.length)}
    />
  );
};

LightBox.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.func.isRequired,
  list: PropTypes.arrayOf(PropTypes.string).isRequired,
  activeIndex: PropTypes.number.isRequired,
  setActiveIndex: PropTypes.func.isRequired,
};

export default LightBox;
