import React, { useState, useEffect } from 'react';
import cn from 'classnames';
import style from '../cruiseContent.module.scss';
import Select from 'componenets/Select';
import Button from 'componenets/Button';
import Dropdown from '../../Dropdown';
import Datepicker from '../../Datepicker';
import BookingStore from '../../../store/BookingStore';

const CommonCruise = () => {
  const bookingStore = BookingStore.useContainer();
  const [type, setType] = useState(bookingStore.type || '');
  const [date, setDate] = useState(bookingStore.date || '');
  const [numberOfSeats, setNumberOfSeats] = useState(bookingStore.numberOfSeats || '');
  const [length, setLength] = useState(bookingStore.length || '');
  const [counter, setCounter] = useState(0);

  const typeOnChange = (data) => {
    setType(data?.value);
  };

  const dateOnChange = (data) => {
    setDate(data);
  };

  const seatsOnChange = (data) => {
    setNumberOfSeats(data?.value);
  };

  const lengthOnChange = (data) => {
    setLength(data?.value);
  };

  const isButtonDisable = () => {
    if (type === 'public' && !numberOfSeats) return false;
    return !(type && date && length);
  };

  const setDataToStore = () => {
    bookingStore.setType(type);
    bookingStore.setDate(date);
    bookingStore.setNumberOfSeats(numberOfSeats);
    bookingStore.setLength(length);
  };

  const buttonOnClick = async () => {
    setDataToStore();
    setCounter(counter + 1);
  };

  useEffect(() => {
    bookingStore.getAvailableCruises();
  }, [counter]);

  return (
    <div className={cn(style.container, style[type])}>
      <div className={cn(style.item)}>
        <Select label={'Type of cruise'} list={bookingStore.typeList} onChange={typeOnChange} value={type} />
      </div>
      <div className={cn(style.separator)} />
      <div className={cn(style.item)}>
        <Dropdown event={date} Content={<Datepicker inline={true} minDate={new Date()} onChange={dateOnChange} availableDays={bookingStore.availableDays} monthOnChange={bookingStore.getAvailableDates} loading={bookingStore.availableDaysLoading} />} label={'When'} hint={date} />
      </div>
      <div className={cn(style.separator)} />
      <div className={cn(style.item)}>
        <Select label={'cruise length'} list={bookingStore.lengthSelectData} onChange={lengthOnChange} value={length} />
      </div>
      {type === 'public' && (
        <>
          <div className={cn(style.separator)} />
          <div className={cn(style.item)}>
            <Select label={'number of seats'} list={bookingStore.seatsSelectData} onChange={seatsOnChange} value={numberOfSeats} />
          </div>
        </>
      )}
      <div className={cn(style.separator)} />
      <div className={cn(style.button)}>
        <Button text={bookingStore.loadingAvailableCruises ? 'Loading...' : 'Search'} radius={'13px'} onClick={buttonOnClick} disable={isButtonDisable() || bookingStore.loadingAvailableCruises} />
      </div>
    </div>
  );
};

export default CommonCruise;
