import React from 'react';
import cn from 'classnames';
import style from './mobile.module.scss';
import Button from '../../Button';
import SelectBorder from '../../SelectBorder';
import BookingStore from '../../../store/BookingStore';
import Datepicker from '../../Datepicker';
import DropdownBorder from '../../DropdownBorder';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';

const Mobile = ({ onClick }) => {
  const bookingStore = BookingStore.useContainer();
  const navigate = useNavigate();

  const typeOnChange = (data) => {
    bookingStore.setType(data?.value);
  };

  const dateOnChange = (data) => {
    bookingStore.setDate(data);
  };

  const seatsOnChange = (data) => {
    bookingStore.setNumberOfSeats(data?.value);
  };

  const cruiseLengthOnChange = (data) => {
    bookingStore.setLength(data?.value);
  };

  const isButtonDisable = () => {
    if (bookingStore.type === 'private') {
      return !(bookingStore.date && bookingStore.length);
    }

    if (bookingStore.type === 'public') {
      return !(bookingStore.date && bookingStore.numberOfSeats && bookingStore.length);
    }

    return true;
  };

  const buttonOnClick = async () => {
    await bookingStore.getAvailableCruises();

    if (onClick) {
      onClick();
      return;
    }

    navigate('booking');
  };

  return (
    <div className={cn(style.container)}>
      <div className={cn(style.list)}>
        {bookingStore.typeList.length > 1 ? (
          <div className={cn(style.item)}>{bookingStore.settingsLoading ? <div className={cn(style.loading)}>Loading...</div> : <SelectBorder label="Select type of cruise" list={bookingStore.typeList} onChange={typeOnChange} required={true} value={bookingStore.type} />}</div>
        ) : null}
        {bookingStore.settingsLoading ? null : (
          <>
            <div className={cn(style.item, !bookingStore.type && style.disable)}>
              <DropdownBorder
                event={bookingStore.date}
                Content={<Datepicker inline={true} minDate={new Date()} onChange={dateOnChange} availableDays={bookingStore.availableDays} monthOnChange={bookingStore.getAvailableDates} loading={bookingStore.availableDaysLoading} />}
                label={'When'}
                required={true}
                hint={bookingStore.date}
              />
            </div>
            <div className={cn(style.item, !bookingStore.date && style.disable)}>
              <SelectBorder label="Cruise Length" list={bookingStore.lengthSelectData} onChange={cruiseLengthOnChange} required={true} value={bookingStore.length} />
            </div>
            {bookingStore.type === 'public' && (
              <div className={cn(style.item, !bookingStore.length && style.disable)}>
                <SelectBorder label="Number of Seats" list={bookingStore.seatsSelectData} onChange={seatsOnChange} required={true} value={bookingStore.numberOfSeats} />
              </div>
            )}
            <div className={cn(style.item)}>
              <Button text={bookingStore.loadingAvailableCruises ? 'Loading...' : 'Search'} disable={isButtonDisable() || bookingStore.loadingAvailableCruises} onClick={buttonOnClick} />
            </div>
          </>
        )}
      </div>
    </div>
  );
};

Mobile.propTypes = {
  onClick: PropTypes.func,
};

export default Mobile;
