import React from 'react';
import cn from 'classnames';
import style from './terms.module.scss';
import '../../assets/scss/componenets/content.scss';

const TermsAndConditions = () => {
  return (
    <div className={cn(style.container)}>
      <div className={cn(style.inner)}>
        <div className="content" style={{ maxWidth: 780, margin: 'auto' }}>
          <h1>
            Chicago Electric Boat Company
            <br /> Terms and Conditions
          </h1>
          <h2 style={{ color: '#bf5a5a' }}>PLEASE READ THESE TERMS AND CONDITIONS CAREFULLY BEFORE ACCEPTING</h2>
          <p>
            <strong>NOTE:</strong> By agreeing to these Terms and Conditions, you assent to the Terms and Conditions of use expressly and implicitly stated herein. The Terms and Conditions expressly and implicitly stated herein form part of a contract with Chicago Duffy, LLC DBA Chicago Cycleboats
            (registered office: 300 North State Street, Unit EE, Chicago, Illinois, 60654) for all arrangements entered into by the party accepting these Terms and Conditions and Chicago Cycleboats, LLC DBA. By submitting your reservation, you accept our pecuniary quotation and you accept, on behalf
            of your party, these Terms and Conditions.
          </p>
          <p>
            <strong>Reservation Details:</strong> Once you have completed and submitted a reservation, you will receive a confirmation email, within 5 minutes to 3 hours, to the email address entered. The payer of the reservation serves as the responsible party and signatory, therefore, must be
            present at the time of the reservation. For questions on this, please reach out to the rental office via email (info@chicagocycleboats.com). You must thoroughly and carefully read the content of the confirmation email prior to your scheduled reservation to ensure its accuracy. If there
            are any errors or discrepancies between the confirmation email you received and the reservation you submitted, you must inform Chicago Cycleboats via email or phone call. If you wish to change the timing of your scheduled reservation or change the boat model, then you, and only you as
            the signatory, must contact Chicago Cycleboats via phone or email at least 7 days prior to the scheduled departure time of your cruise. See below for the cancellation policy in full. Purchasers of Cancellation Protection, will be able to cancel their cruise for any reason, up to
            twenty-four hours prior to your departure time for a full refund - minus any add-on fees including tip and booking fee. The twenty-four-hour period is also applicable for rescheduling cruises. Changes to your original reservation will be accommodated, with reasonable effort, by Chicago
            Cycleboats and are in no way guaranteed. You will receive both SMS and email updates regarding your reservation and promotions. Message and data rates may apply.
          </p>
          <p>
            <strong>Payment Agreement:</strong> The total fee of the reservation, indicated in the payment screen of the online reservation process, will be automatically charged upon completion of booking the reservation. If you wish to cancel or reschedule a reservation, this must be done 7 days
            prior to your reservation date. For reservations submitted within 24 hours of the scheduled reservation, all sales are final. Refunds or cancellations for reservations of the preceding nature will occur at the sole discretion of Chicago Cycleboats. Chicago Cycleboats reserves the right
            to cancel a reservation at the time of rental due to dangerous weather, this decision is to be made by the company. In the event a cancellation is made by Chicago Cycleboats, another reservation will be scheduled at a mutually agreeable time subject to availability. No refunds will be
            granted. If, for whatever reason, the total payment of the reservation, stated in the payment screen of the online reservation process, cannot be processed at the time such processing is set to occur (See above for payment policy), Chicago Cycleboats reserves the right to cancel said
            reservation, based solely on these grounds, without prior notice. All efforts will be made on behalf of Chicago Cycleboats to contact the renter however if attempts fail, the reservation will be canceled so as not to limit availability to other parties looking to book a cruise.
          </p>
          <p>
            <strong>Cancellation Policy:</strong> All parties wishing to cancel their reservation must notify Chicago Cycleboats via email of their desire to cancel their reservation at least 7 days prior to the reservation’s scheduled departure time. There are absolutely no exceptions. A
            confirmation-of-cancellation email will be sent to the email address you provided Chicago Cycleboats during the process of reserving your cruise. If you purchased the cancellation insurance, you are able to cancel 24 hours in advance via email. You, as the signatory, are the only one who
            can cancel this reservation. If the reservation is canceled within 7 days of the scheduled departure time, you acknowledge that Chicago Cycleboats is entitled to the full payment. <strong>Full payment</strong> will include any additional fees <strong>excluding</strong> booking fee,
            including, but not limited to tips, etc. If you are using a voucher or gift certificate, you agree to the redemption of the full value of such voucher or gift certificate. Cancellations due to weather will not be accepted outside of the Cancellation Policy, however, a reschedule or time
            change may occur at the discretion of Chicago Cycleboats at the time of the rental. If extreme circumstances occur, and Chicago Cycleboats allows a reschedule outside of the cancellation policy, the sale is final regardless of the date of the rescheduled cruise. I understand that 24
            hours prior to my cruise, my credit card will have a $250 security deposit pre-authorization. A charge will occur if I, the renter, am found to be negligent. A $250 charge will be taken from the security deposit if there are any hazardous fluids or illegal/dangerous behaviors. I am
            responsible to pay for, but not limited to, any damages, late returns, clean-up fees, towing fees, and fines. Before the scheduled departure of your cruise, you will be required to thoroughly and carefully read and complete the rental waiver on your phone. All passengers must agree to
            the full range of responsibilities and duties expressly and implicitly stated in any and all contracts entered into between the signatory and Chicago Cycleboats. For public cruises, Chicago Cycleboats requires a major credit card to be authorized at the time of a rental, with available
            funds of $250, to be placed on file for a security deposit. The security deposit will be released in its full amount not more than 48 hours after the vessel has been returned in the same condition as it departed. The rental agreement is available for review prior to your arrival at your
            request, via chicagocycleboats.com. All passengers, including the responsible party (signatory of the rental agreement, terms and conditions, and cardholder of the payment), will be mandated to complete the rental waiver. The signatory and all passengers are required to carefully watch a
            safety orientation given by the captain before departing the dock.
          </p>
          <p>
            <strong>Right of Reservation Changes:</strong> Chicago Cycleboats reserves the right to make changes to all reservations at its sole discretion. Chicago Cycleboats will make a reasonable effort to advise affected parties of any changes to their reservation as soon as notification becomes
            feasible. Chicago Cycleboats will make all reasonable efforts to minimize the necessity of such changes. Chicago Cycleboats reserves the right to cancel a reservation at its sole discretion as a result of, but not limited to, adverse weather, war, riots, terrorist activities, natural
            disasters, water closures [Chicago River or Lake Michigan] due to government agency decisions, or other unforeseen circumstances.
          </p>
          <p>
            <strong>Right to Terminate Reservation:</strong> Chicago Cycleboats reserves the right, at its sole and absolute discretion, to terminate the reservation of any party who refuses or fails to comply with the instructions or orders of staff or other persons identified by Chicago Electric
            Boat Company or Chicago Cycleboats as having power to enforce its rules and regulations and whose behavior, in the opinion aforementioned persons, is likely to cause distress, damage, danger, or annoyance to other customers, staff, third party or property. Upon such termination, Chicago
            Cycleboats is waived of any legal, or otherwise binding, responsibility or affiliation to and for the terminated party. No refund will be given.
          </p>
          <p>
            <strong>Conditions of Travel Arrangements:</strong> These Terms and Conditions apply only to Chicago Cycleboats and are, in no way, affiliated or representative of any Terms and Conditions established by third-party vendors operating in conjunction with or in relation to Chicago Electric
            Boat Company.
          </p>
          <p>
            <strong>Contracts/Protocol at the Time of Reservation:</strong> Before the scheduled departure of your cruise, you and all other passengers will be required to thoroughly and carefully read through and fill out a rental agreement, the signatory must be present at the time of the
            reservation, as well as the card used to pay for the rental and a form of identification. As signatory, you assent to the full range of responsibilities and duties expressly and implicitly stated in any and all contracts entered into between you, as the signatory, and Chicago Cycleboats.
            Chicago Cycleboats requires a major credit card to be authorized at the time of a rental, with available funds of at least $250.00 USD, to be placed on file to hold a security deposit. The security deposit will be released in its full amount, 24 hours after the vessel has been returned
            in the same condition as it departed and no rules have been violated. The rental agreement is available for review prior to your arrival at your request, via email, phone, or chicagocycleboats.com. All passengers are required to view an onboard captain verbal safety orientation before
            departing the dock.
          </p>
          <p>
            <strong>Responsible Party:</strong> As a signatory and responsible party, it is solely your responsibility to ensure all members of your party are familiar with and consent to their obligations as stipulated by this contract and any contract entered into between the signatory/responsible
            party and Chicago Cycleboats.
          </p>
          <p>
            <strong>External links:</strong> External links on our website may be provided for your convenience, but they are beyond the control of Chicago Cycleboats and the views and policies of these links are in no way representative of the views and beliefs held by Chicago Cycleboats. Use or
            reliance on any external links and the content therein provided is solely at the risk of the person utilizing these links
          </p>
          <p>
            <strong>Warranties:</strong> Chicago Cycleboats makes no warranties, representations, statements, or guarantees (whether express, implied, in law, or residual) regarding the website.
          </p>
          <p>
            <strong>Disclaimer of liability:</strong> Chicago Cycleboats shall not be responsible for and waives all liability from any loss, liability, damage (whether direct, indirect, or consequential), personal injury, or expense of any nature whatsoever, which may be incurred by you or any
            third party (including your company), as a result of, in conjunction with or in relation to, either directly or indirectly, your access and use of the website, any information contained therein, your or your company's personal information or material and information transmitted through
            our servers. In particular, neither the Chicago Cycleboats nor any third party or data or content provider shall be liable in any way to you or to any other person, firm, or corporation whatsoever for any loss, liability, damage (whether direct or consequential), personal injury or
            expense of any nature whatsoever arising from any delays, inaccuracies, errors in, or omission of any information or the transmission thereof, or for any actions taken in reliance thereon or occasioned thereby or by reason of non-performance or interruption, or termination thereof,
            content viewed on servers maintained, owned, and operated by Chicago Duffy LLC.
          </p>
          <p>
            <strong>Conflict of terms:</strong> If there is a conflict or contradiction between the provisions of this website’s terms and conditions and any other relevant terms and conditions, policies or notices, the former terms and conditions, policies or notices, which relate specifically to a
            particular section or module of the website shall prevail in respect of your use of the relevant section or module of the website.
          </p>
          <p>
            <strong>Severability:</strong> Any provision of any relevant terms and conditions, policies and notices, which is or becomes unenforceable in any jurisdiction, whether due to being void, invalid, illegal, unlawful, or for any reason whatsoever, shall, in such jurisdiction only and only
            to the extent that it is so unenforceable, be treated as void and the remaining provisions of any relevant terms and conditions, policies and notices shall remain in full force and effect. Applicable laws (choice of venue and forum): Use of this website shall in all respects be governed
            by the laws of the state of Illinois, USA regardless of the laws that might be applicable under principles of conflicts of law. The parties agree that the Illinois courts located in Cook County, Illinois shall have exclusive jurisdiction over all controversies arising under this
            agreement and agree this venue is proper in those courts.
          </p>
        </div>
      </div>
    </div>
  );
};

export default TermsAndConditions;
