import React, { useState } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import style from './video.module.scss';
import playIcon from './img/play-icon.png';

const Video = ({ src, poster }) => {
  const [isPlayed, setIsPlayed] = useState(false);

  const play = () => {
    setIsPlayed(true);
  };

  return (
    <div className={cn(style.container)}>
      <div className={cn(style.inner)}>
        {isPlayed ? (
          <video src={src} controls={true} autoPlay={true} className={cn(style.video)} />
        ) : (
          <>
            <img src={poster} alt="video" className={cn(style.poster)} />
            <img src={playIcon} alt="play trigger" className={cn(style.icon)} onClick={play} />
          </>
        )}
      </div>
    </div>
  );
};

Video.propTypes = {
  src: PropTypes.string,
  poster: PropTypes.string,
};

export default Video;
