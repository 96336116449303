import React, { useEffect, useState } from 'react';
import Modal from '../../../../componenets/Modal';
import PropTypes from 'prop-types';
import cn from 'classnames';
import BookingStore from '../../../../store/BookingStore';
import style from './cruiseTimeModal.module.scss';
import clockImage from '../../img/clock.svg';
import getTimeSlotString from '../../../../utils/GetTimeSlotString';

const compliance = {
  '0-60': '0 - 1 hr',
  '60-120': '1 hr - 2 hrs',
  '120-180': '2 hrs - 3 hrs',
  '180-240': '3 hrs - 4 hrs',
  '240-300': '4 hrs - 5 hrs',
  300: 'more than 5 hrs',
};

const CruiseTimeModal = ({ active, onClose, onSuccess, onError }) => {
  const bookingStore = BookingStore.useContainer();
  const [activeTab, setActiveTab] = useState(null);
  const [tabs, setTabs] = useState({});

  const getTabs = () => {
    let data = {};

    bookingStore.timeSlots.forEach((timeSlot) => {
      const { length } = timeSlot;

      if (length >= 0 && length < 60) {
        if (!data.hasOwnProperty('0-60')) {
          data['0-60'] = [];
        }
        data['0-60'].push(timeSlot);
      }

      if (length >= 60 && length < 120) {
        if (!data.hasOwnProperty('60-120')) {
          data['60-120'] = [];
        }
        data['60-120'].push(timeSlot);
      }

      if (length >= 120 && length < 180) {
        if (!data.hasOwnProperty('120-180')) {
          data['120-180'] = [];
        }
        data['120-180'].push(timeSlot);
      }

      if (length >= 180 && length < 240) {
        if (!data.hasOwnProperty('180-240')) {
          data['180-240'] = [];
        }
        data['180-240'].push(timeSlot);
      }

      if (length >= 240 && length < 300) {
        if (!data.hasOwnProperty('240-300')) {
          data['240-300'] = [];
        }
        data['240-300'].push(timeSlot);
      }

      if (length >= 300) {
        if (!data.hasOwnProperty('300')) {
          data['300'] = [];
        }
        data['300'].push(timeSlot);
      }
    });

    setTabs(data);
    setActiveTab(Object.keys(data)[0]);
  };

  const onCloseWrapper = () => {
    bookingStore.setBoatGroup(null);

    onClose();
  };

  useEffect(() => {
    getTabs();
    if (active && !bookingStore.timeSlotsLoading && bookingStore.timeSlots.length === 0) {
      onError(true);
    }
  }, [bookingStore.timeSlotsLoading, bookingStore.timeSlots, active]);

  if (!active) return null;

  return (
    <Modal active={active} title="Select Cruise Time" subtitle="Check available time slots options." onClose={onCloseWrapper} onSuccess={onSuccess} successButtonDisable={!bookingStore.selectedTimeSlot} buttonCloseText="Cancel" buttonSuccessText="Next">
      <div className={cn(style.container)}>
        {bookingStore.timeSlotsLoading && 'Loading...'}
        {activeTab ? (
          <>
            {Object.keys(tabs).length > 1 ? (
              <div className={cn(style.tabs)}>
                {Object.keys(tabs).map((name) => (
                  <div className={cn(style.tab, name === activeTab ? style.active : null)} key={name} onClick={() => setActiveTab(name)}>
                    {compliance[name]}
                  </div>
                ))}
              </div>
            ) : null}
            <div className={cn(style.slots)}>
              {tabs[activeTab].map(({ name, list }) => (
                <div className={cn(style.block)} key={name}>
                  <div className={cn(style.title)}>
                    <img src={clockImage} alt="" />
                    <div>{name}</div>
                  </div>
                  <div className={cn(style.list)}>
                    {list.map((item) => (
                      <div className={cn(style.item, bookingStore.selectedTimeSlot === item && style.active)} onClick={() => bookingStore.setSelectedTimeSlot(item)} key={item['@id']}>
                        {getTimeSlotString(item)} (${item.price})
                      </div>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          </>
        ) : null}
      </div>
    </Modal>
  );
};

CruiseTimeModal.propTypes = {
  active: PropTypes.bool,
  onClose: PropTypes.func,
  onSuccess: PropTypes.func,
  onError: PropTypes.func,
};

export default CruiseTimeModal;
