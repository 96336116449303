import React, { useState } from 'react';
import Input from './Input';
import Check from 'componenets/Check';
import PropTypes from 'prop-types';
import cn from 'classnames';
import style from './field.module.scss';

const Field = ({ type, required, placeholder, label, value, name, error, postfix, mask, onChange: propOnChange, min, max, disable }) => {
  if (type === 'checkbox') return <Check value={!!value} label={label} name={name} onChange={propOnChange} disabled={disable} error={error} />;

  const [isFocus, setIsFocus] = useState(false);

  const onFocus = () => {
    setIsFocus(true);
  };

  const onBlur = () => {
    setIsFocus(false);
  };

  const onChange = (e) => {
    if (!propOnChange) return;

    propOnChange(e.target.value);
  };

  return (
    <label
      className={cn(style.container, {
        [style.focus]: isFocus,
        [style.error]: error,
        [style.offset]: value || isFocus,
      })}
    >
      <span
        className={cn(style.label, {
          [style.hide]: value || isFocus,
        })}
      >
        {placeholder}
        {required ? <span className={cn(style.star)}>*</span> : null}
      </span>
      <div className={cn(style.inputBox)}>
        <Input type={type} value={value} name={name} onFocus={onFocus} onBlur={onBlur} onChange={onChange} mask={mask} min={min} max={max} disable={disable} />
        {value || isFocus ? <span>{postfix}</span> : null}
      </div>
    </label>
  );
};

Field.propTypes = {
  type: PropTypes.string,
  required: PropTypes.bool,
  placeholder: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]).isRequired,
  name: PropTypes.string.isRequired,
  error: PropTypes.bool,
  postfix: PropTypes.string,
  mask: PropTypes.string,
  onChange: PropTypes.func,
  min: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  max: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  disable: PropTypes.bool,
};

Field.defaultProps = {
  type: 'text',
  required: false,
  placeholder: '',
  label: '',
  value: '',
  min: 0,
  disable: false,
};

export default Field;
