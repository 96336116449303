import React from 'react';
import PropTypes from 'prop-types';
import Modal from '../../../Modal';
import cn from 'classnames';
import style from './cruiseDataModal.module.scss';
import BookingStore from '../../../../store/BookingStore';
import DropdownBorder from '../../../DropdownBorder';
import Datepicker from '../../../Datepicker';
import SelectBorder from '../../../SelectBorder';

const CruiseDataModal = ({ title, capacity, active, onClose, onSuccess }) => {
  const bookingStore = BookingStore.useContainer();

  const seatsSelectData = Array.from({ length: capacity }, (_, index) => ({
    id: 999 + index,
    value: index + 1,
    label: (index + 1).toString(),
  }));

  const dateOnChange = (data) => {
    bookingStore.setDate(data);
  };

  const seatsOnChange = (data) => {
    bookingStore.setNumberOfSeats(data?.value);
  };

  const isSuccessButtonDisable = () => {
    if (bookingStore.type === 'public') {
      return !bookingStore?.date || !bookingStore.length || !bookingStore.numberOfSeats;
    }

    if (bookingStore.type === 'private') {
      return !bookingStore?.date || !bookingStore.length;
    }

    return false;
  };

  if (!active) return null;

  return (
    <Modal active={active} title="Book The Boat" subtitle={title} buttonCloseText="Cancel" buttonSuccessText="Book Now" onClose={onClose} onSuccess={onSuccess} successButtonDisable={isSuccessButtonDisable()}>
      {active && (
        <div className={cn(style.container)}>
          <div className={cn(style.list)}>
            <div className={cn(style.item, !bookingStore.type && style.disable)}>
              <DropdownBorder
                event={bookingStore.date}
                Content={<Datepicker inline={true} minDate={new Date()} onChange={dateOnChange} availableDays={bookingStore.groupMonthAvailableDays} monthOnChange={bookingStore.getGroupMonthAvailableDates} loading={bookingStore.groupMonthAvailableDaysLoading} />}
                label={'When'}
                required={true}
                hint={bookingStore.date}
              />
            </div>
            {bookingStore.type === 'public' && (
              <div className={cn(style.item, !bookingStore.length && style.disable)}>
                <SelectBorder label="Number of Seats" list={seatsSelectData} onChange={seatsOnChange} required={true} value={bookingStore.numberOfSeats} />
              </div>
            )}
          </div>
        </div>
      )}
    </Modal>
  );
};

CruiseDataModal.propTypes = {
  title: PropTypes.string,
  capacity: PropTypes.number,
  active: PropTypes.bool,
  onClose: PropTypes.func,
  onSuccess: PropTypes.func,
};

export default CruiseDataModal;
