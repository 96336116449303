import React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import style from './card.module.scss';

const Card = ({ title, image, description }) => {
  return (
    <div className={cn(style.container)}>
      <img src={image} className={cn(style.image)} />
      <div className={cn(style.title)}>{title}</div>
      <div className={cn(style.description)} dangerouslySetInnerHTML={{ __html: description }} />
    </div>
  );
};

Card.propTypes = {
  title: PropTypes.string,
  image: PropTypes.string,
  description: PropTypes.string,
};

export default Card;
