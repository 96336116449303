import React from 'react';
import Modal from '../../../componenets/Modal';
import cn from 'classnames';
import PropTypes from 'prop-types';
import style from './success.module.scss';

const SuccessModal = ({ active, onClose }) => {
  return (
    <Modal active={active} title="Success" subtitle="Thank you for reaching out" buttonSuccessText="Close" hideCloseButton={true} onSuccess={onClose}>
      <div className={cn(style.container)}>
        <div className={cn(style.list)}>
          <div className={cn(style.item)}>
            <div className={cn(style.title)}>A team member will get back to you shortly</div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

SuccessModal.propTypes = {
  active: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
};

export default SuccessModal;
