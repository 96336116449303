import React, { useEffect, useRef, useState } from 'react';
import cn from 'classnames';
import moment from 'moment';
import PropTypes from 'prop-types';
import { Tooltip } from 'react-tippy';
import useFetch from '../../../utils/UseFetch';
import simpleValidator from '../../../utils/SimpleValidator';
import ReservationStore from '../../../store/ReservationStore';
import BookingStore from '../../../store/BookingStore';
import { setUpRules } from '../ValidationSchema';
import { RESERVATION_BOAT_PASS_VALIDATION, RESERVATION_PROMO_CODE_VALIDATION } from '../../../utils/Api';
import Button from '../../Button';
import Field from '../../Field';
import AddonCheckbox from '../../AddonCheckbox';
import Check from '../../Check';
import SelectBorder from '../../SelectBorder';
import BoatPassErrorModal from './BoatPassErrorModal';
import PromoCodeErrorModal from './PromoCodeErrorModal';
import style from '../form.module.scss';
import InsuranceRadio from '../../InsuranceRadio';

const CancellationProtectionTooltipContentForPrivate = (
  <>
    <h4>
      <strong>Cancellation Protection will allow any guest to cancel their cruise for any reason.</strong>
    </h4>
    <p>
      Cancellation Protection allows a reservation to be cancelled up to <strong>24 HOURS prior</strong> to the scheduled departure time.
    </p>
    <p>
      Otherwise, private cruises must be canceled <strong>one week (7 Days) prior</strong> to the scheduled departure time to receive a full refund.
    </p>
    <p>
      <strong>All cancellations must be submitted via email to</strong>
    </p>
    <p>
      <a href="mailto:info@chicagocycleboats.com">info@chicagocycleboats.com</a>
    </p>
  </>
);

const CancellationProtectionTooltipContentForPublic = (
  <>
    <h4>
      <strong>Cancellation Protection will allow any guest to cancel their cruise for any reason.</strong>
    </h4>
    <p>
      Cancellation Protection allows a reservation to be cancelled up to <strong>24 HOURS prior</strong> to the scheduled departure time.
    </p>
    <p>
      Otherwise, public cruises must be canceled <strong>72 hours (3 Days) prior</strong> to the scheduled departure time to receive a full refund.
    </p>
    <p>
      <strong>All cancellations must be submitted via email to</strong>
    </p>
    <p>
      <a href="mailto:info@chicagocycleboats.com">info@chicagocycleboats.com</a>
    </p>
  </>
);

const PromoCodeFlow = ({ reservationStore, bookingStore }) => {
  const [response, setResponse] = useState({
    status: '',
    message: '',
  });
  const [isErrorModalShow, setIsErrorModalShow] = useState(false);

  const sendPromoCode = async () => {
    if (!reservationStore.email || !!reservationStore.formError.email?.error) {
      setResponse({
        status: 'error',
        message: 'Enter your email to apply promo code',
      });
      return;
    }

    if (!reservationStore.promoCode) return;
    if (!reservationStore.email) return;
    if (!bookingStore.boatGroup?.['@id']) return;
    if (!bookingStore.type) return;
    if (!bookingStore.selectedTimeSlot?.startAt) return;
    if (!bookingStore.selectedTimeSlot?.endAt) return;

    reservationStore.setPromoCodeLoading(true);
    reservationStore.setIsEmailDisable(true);

    const data = await useFetch('get', RESERVATION_PROMO_CODE_VALIDATION, {
      json: JSON.stringify({
        promocode: reservationStore.promoCode,
        email: reservationStore.email,
        startAt: moment(bookingStore.selectedTimeSlot.startAt).format('MM/DD/YYYY hh:mm A'),
        endAt: moment(bookingStore.selectedTimeSlot.endAt).format('MM/DD/YYYY hh:mm A'),
        boatGroup: bookingStore.boatGroup['@id'],
        cruiseType: bookingStore.type,
      }),
    });

    if (data.isValid) {
      setResponse({
        status: 'success',
        message: 'The promo code has been successfully applied',
      });
      reservationStore.setIsPromoCodeValid(true);
    } else {
      reservationStore.setIsEmailDisable(false);
      setResponse({
        status: 'error',
        message: 'The promo code not applied',
      });
      reservationStore.setIsPromoCodeValid(false);
      setIsErrorModalShow(true);
    }

    if (data.isTipOptional) {
      reservationStore.setIsTipOptional(true);
      reservationStore.setTips(0);
    }

    reservationStore.setPromoCodeLoading(false);
  };

  const clearPromoCode = () => {
    reservationStore.setIsPromoCodeValid(false);
    reservationStore.setPromoCode('');
    reservationStore.setIsEmailDisable(false);
    setResponse({
      status: '',
      message: '',
    });
    reservationStore.setIsTipOptional(false);
    reservationStore.setTips(reservationStore.settings?.tipPercent);
  };

  return (
    <>
      <div className={cn(style.item2)}>
        <div className={cn(style.codeContainer)}>
          <div>
            <Field
              type="text"
              placeholder="Promo code"
              name="promoCode"
              disable={!!reservationStore.isPromoCodeValid || !!reservationStore.isBoatPassValid}
              error={!!reservationStore.formError.promoCode?.touched && !!reservationStore.formError.promoCode?.error}
              value={reservationStore.promoCode}
              onChange={reservationStore.setPromoCode}
            />
            {!!reservationStore.formError.promoCode?.touched && !!reservationStore.formError.promoCode?.error && <div className={cn(style.error)}>{reservationStore.formError.promoCode.error}</div>}
          </div>
          {!reservationStore.isPromoCodeValid ? (
            <>
              <Button text={reservationStore.promoCodeLoading ? 'Loading...' : 'Apply'} disable={reservationStore.promoCodeLoading || !reservationStore.promoCode} onClick={sendPromoCode} />
            </>
          ) : (
            <>
              <Button text="Change" disable={!reservationStore.promoCode} onClick={clearPromoCode} />
            </>
          )}
        </div>
        {!!response.status && <div className={cn(style.promoCodeResponse, style[response.status])}>{response.message}</div>}
      </div>
      <PromoCodeErrorModal active={isErrorModalShow} onClose={() => setIsErrorModalShow(false)} />
    </>
  );
};

PromoCodeFlow.propTypes = {
  reservationStore: PropTypes.object.isRequired,
  bookingStore: PropTypes.object.isRequired,
};

const BoatPassFlow = ({ reservationStore, bookingStore }) => {
  const [response, setResponse] = useState({
    status: '',
    message: '',
  });
  const [isErrorModalShow, setIsErrorModalShow] = useState(false);
  const [errorText, setErrorText] = useState('');

  const sendBoatPass = async () => {
    if (!reservationStore.email || !!reservationStore.formError.email?.error) {
      setResponse({
        status: 'error',
        message: 'Enter your email to apply boat pass',
      });
      return;
    }

    if (!reservationStore.boatPass) return;
    if (!reservationStore.email) return;
    if (!bookingStore.boatGroup?.['@id']) return;
    if (!bookingStore.type) return;
    if (!bookingStore.selectedTimeSlot?.startAt) return;
    if (!bookingStore.selectedTimeSlot?.endAt) return;

    setErrorText('');
    reservationStore.setBoatPassLoading(true);
    reservationStore.setIsEmailDisable(true);

    const data = await useFetch('get', RESERVATION_BOAT_PASS_VALIDATION, {
      json: JSON.stringify({
        code: reservationStore.boatPass,
        email: reservationStore.email,
        startAt: moment(bookingStore.selectedTimeSlot.startAt).format('MM/DD/YYYY hh:mm A'),
        endAt: moment(bookingStore.selectedTimeSlot.endAt).format('MM/DD/YYYY hh:mm A'),
        boatGroup: bookingStore.boatGroup['@id'],
        cruiseType: bookingStore.type,
      }),
    });

    if (data.isValid) {
      setResponse({
        status: 'success',
        message: 'The boat pass has been successfully applied',
      });
      reservationStore.setIsBoatPassValid(true);
      reservationStore.setBoatPassType(data.boatPassType);
    } else {
      reservationStore.setIsEmailDisable(false);
      setResponse({
        status: 'error',
        message: 'The boat pass not applied',
      });
      reservationStore.setIsBoatPassValid(false);
      setIsErrorModalShow(true);
      setErrorText(typeof data.error === 'string' ? data.error : `Boat pass "${reservationStore.boatPass}" not found!`);
    }

    reservationStore.setIsTipOptional(true);
    reservationStore.setTips(0);

    reservationStore.setBoatPassLoading(false);
  };

  const clearBoatPass = () => {
    reservationStore.setIsBoatPassValid(false);
    reservationStore.setBoatPass('');
    reservationStore.setIsEmailDisable(false);
    setResponse({
      status: '',
      message: '',
    });
    reservationStore.setIsTipOptional(false);
    reservationStore.setTips(reservationStore.settings?.tipPercent);
  };

  return (
    <>
      <div className={cn(style.item2)}>
        <div className={cn(style.codeContainer)}>
          <div>
            <Field
              type="text"
              placeholder="Boat pass"
              name="boatPass"
              disable={reservationStore.isBoatPassValid || reservationStore.isPromoCodeValid}
              error={!!reservationStore.formError.boatPass?.touched && !!reservationStore.formError.boatPass?.error}
              value={reservationStore.boatPass}
              onChange={reservationStore.setBoatPass}
            />
            {!!reservationStore.formError.boatPass?.touched && !!reservationStore.formError.boatPass?.error && <div className={cn(style.error)}>{reservationStore.formError.boatPass.error}</div>}
          </div>
          {!reservationStore.isBoatPassValid ? (
            <>
              <Button text={reservationStore.boatPassLoading ? 'Loading...' : 'Apply'} disable={reservationStore.boatPassLoading || !reservationStore.boatPass} onClick={sendBoatPass} />
            </>
          ) : (
            <>
              <Button text="Change" disable={!reservationStore.boatPass} onClick={clearBoatPass} />
            </>
          )}
        </div>
        {!!response.status && <div className={cn(style.promoCodeResponse, style[response.status])}>{response.message}</div>}
      </div>
      <BoatPassErrorModal active={isErrorModalShow} onClose={() => setIsErrorModalShow(false)} errorText={errorText} />
    </>
  );
};

BoatPassFlow.propTypes = {
  reservationStore: PropTypes.object.isRequired,
  bookingStore: PropTypes.object.isRequired,
};

const SetUpCruiseForm = ({ currentBoatGroup }) => {
  const reservationStore = ReservationStore.useContainer();
  const bookingStore = BookingStore.useContainer();
  const formRef = useRef(null);
  const [isShow, setIsShow] = useState(true);
  const [cancellationProtectionCost, setCancellationProtectionCost] = useState('');

  const foundTypeOnChange = (data) => {
    if (!data.id) return;

    reservationStore.setFoundType(data.id);

    reservationStore.setFormErrors({
      ...reservationStore.formError,
      foundType: { touched: true, error: simpleValidator(setUpRules.foundType, data.id) },
    });
  };

  const occasionOnChange = (data) => {
    if (!data.id) return;

    reservationStore.setOccasion(data.id);

    reservationStore.setFormErrors({
      ...reservationStore.formError,
      occasion: { touched: true, error: simpleValidator(setUpRules.occasion, data.id) },
    });
  };

  const cancellationProtectionOnChange = (value) => {
    reservationStore.setCancellationProtection(value);
  };

  const addonOnChange = (id) => {
    const addons = [...reservationStore.selectedAddons];
    const index = addons.findIndex((item) => item?.addon?.['@id'] === id);

    if (index === -1) {
      const findAddon = reservationStore.addons.find((item) => item?.addon?.['@id'] === id);
      addons.push(findAddon);
    } else {
      addons.splice(index, 1);
    }

    reservationStore.setSelectedAddons(addons);
  };

  const CancellationProtectionTooltipContent = () => {
    if (bookingStore.type === 'private') return CancellationProtectionTooltipContentForPrivate;
    if (bookingStore.type === 'public') return CancellationProtectionTooltipContentForPublic;

    return null;
  };

  useEffect(() => {
    if (!currentBoatGroup?.[`${bookingStore.type}Type`]?.cancellationCost) return;

    setCancellationProtectionCost(currentBoatGroup[`${bookingStore.type}Type`]?.cancellationCost);
  }, [currentBoatGroup]);

  return (
    <>
      <form ref={formRef} className={cn(style.container)}>
        <div className={cn(style.title, !isShow && style.titleMobileCollapse, style.titleMobileBorder)} onClick={() => setIsShow(!isShow)}>
          2. Set Up Your Cruise
        </div>
        <div className={cn(style.content, !isShow && style.contentHide)}>
          <div className={cn(style.list)}>
            <div className={cn(style.item2)}>
              <SelectBorder required={true} error={!!reservationStore.formError.foundType?.touched && !!reservationStore.formError.foundType?.error} radius={8} label="How did you hear about us" list={reservationStore.foundTypeData} onChange={foundTypeOnChange} value={reservationStore.foundType} />
              {!!reservationStore.formError.foundType?.touched && !!reservationStore.formError.foundType?.error && <div className={cn(style.error)}>{reservationStore.formError.foundType.error}</div>}
            </div>
            <div className={cn(style.item2)}>
              <SelectBorder required={true} error={!!reservationStore.formError.occasion?.touched && !!reservationStore.formError.occasion?.error} radius={8} label="Occasion" list={reservationStore.occasionData} onChange={occasionOnChange} value={reservationStore.occasion} />
              {!!reservationStore.formError.occasion?.touched && !!reservationStore.formError.occasion?.error && <div className={cn(style.error)}>{reservationStore.formError.occasion.error}</div>}
            </div>
            {bookingStore.type === 'private' && (
              <div className={cn(style.item2)}>
                <div className={cn(style.check2)}>
                  <div className={cn(style.check2Label)}>
                    Cancellation protection
                    <Tooltip html={<CancellationProtectionTooltipContent />} trigger="mouseenter" theme="light" animation="none">
                      <span className="tooltip-trigger" />
                    </Tooltip>
                  </div>
                  <Check label={`Add cancellation protection ($${cancellationProtectionCost})`} name="cancellationProtection" value={reservationStore.cancellationProtection} onChange={cancellationProtectionOnChange} />
                </div>
              </div>
            )}
            <PromoCodeFlow reservationStore={reservationStore} bookingStore={bookingStore} />
            {bookingStore.type === 'private' && <BoatPassFlow reservationStore={reservationStore} bookingStore={bookingStore} />}
          </div>
          {!!reservationStore.addons.length && (
            <>
              <div className={cn(style.subtitle)}>Addons</div>
              <div className={cn(style.list)}>
                {reservationStore.addons.map(({ addon, isRequired }) => (
                  <div className={cn(style.item2, style.itemFullHeight)} key={addon['@id']}>
                    <AddonCheckbox
                      label={addon.name}
                      price={`$${addon.price}${addon.priceType === 'hourly' ? '/hour' : ''}`}
                      description={addon.description}
                      required={isRequired}
                      value={reservationStore.selectedAddons.findIndex((item) => item?.addon?.['@id'] === addon['@id']) !== -1}
                      onChange={() => addonOnChange(addon['@id'])}
                    />
                  </div>
                ))}
              </div>
            </>
          )}
          {!!reservationStore.insuranceList.length && bookingStore.type === 'private' && (
            <>
              <div className={cn(style.subtitle)}>Insurance</div>
              <div className={cn(style.list)}>
                {reservationStore.insuranceList.map((item) => (
                  <div className={cn(style.item2, style.itemFullHeight)} key={item['@id']}>
                    <InsuranceRadio name="insurances" price={`$${item.price}`} description={item.description} checked={reservationStore.selectedInsurance === item['@id']} value={item['@id']} label={item.name} onChange={(value) => reservationStore.setSelectedInsurance(value)} />
                  </div>
                ))}
              </div>
            </>
          )}
        </div>
      </form>
    </>
  );
};

SetUpCruiseForm.propTypes = {
  currentBoatGroup: PropTypes.object,
};

export default SetUpCruiseForm;
