import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import style from '../PromoCodeErrorModal/promoCodeErrorModal.module.scss';
import ErrorIcon from '../PromoCodeErrorModal/icon.svg';
import Modal from '../../../Modal';

const BoatPassErrorModal = ({ active, onClose, errorText }) => {
  const title = (
    <div className={cn(style.title)}>
      <img src={ErrorIcon} alt="" className={cn(style.titleIcon)} />
      <div className={cn(style.titleText)}>Error</div>
    </div>
  );

  return <Modal active={active} title={title} subtitle={errorText} buttonSuccessText="Close" hideCloseButton={true} onSuccess={onClose} />;
};

BoatPassErrorModal.propTypes = {
  active: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  errorText: PropTypes.string,
};

export default BoatPassErrorModal;
