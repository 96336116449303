import React, { useEffect, useState } from 'react';
import cn from 'classnames';
import style from './detailModal.module.scss';
import PropTypes from 'prop-types';
import CloseIcon from '../../../BoatGroupCard/Modal/closeIcon.svg';
import LightBox from '../../../LightBox';
import Button from '../../../Button';

const DetailModal = ({ status, setStatus, title, gallery, image, description, list1, list2, additionalInformation, contentPriceTable, buttonOnClick }) => {
  const [isLightBoxOpen, setIsLightBoxOpen] = useState(false);
  const [activeLightBoxIndex, setActiveLightBoxIndex] = useState(0);

  const galleryUrls = gallery.map((item) => `${process.env.REACT_APP_API_URL}${item.contentUrl}`);

  useEffect(() => {
    document.body.style.overflow = status ? 'hidden' : '';

    return () => {
      document.body.style.overflow = '';
    };
  }, [status]);

  if (!status) return null;

  return (
    <>
      <div className={cn(style.container)}>
        <div className={cn(style.back)} onClick={() => setStatus(false)}></div>
        <div className={cn(style.inner)}>
          <div className={cn(style.header)}>
            <div className={cn(style.title)}>Preview The Boat</div>
            <div className={cn(style.name)} dangerouslySetInnerHTML={{ __html: title }} />
            <div className={cn(style.close)} onClick={() => setStatus(false)}>
              <img src={CloseIcon} alt="" />
            </div>
          </div>
          <div className={cn(style.main)}>
            <div className={cn(style.imagesBlock)}>
              <img
                className={cn(style.mainImage)}
                src={image}
                alt=""
                onClick={() => {
                  setIsLightBoxOpen(true);
                  setActiveLightBoxIndex(0);
                }}
              />
              <div className={cn(style.list)}>
                {galleryUrls.slice(0, 4).map((item, idx) => (
                  <div key={item} className={cn(style.listItem)}>
                    <img
                      src={item}
                      alt=""
                      onClick={() => {
                        setIsLightBoxOpen(true);
                        setActiveLightBoxIndex(idx + 1);
                      }}
                    />

                    {idx === 3 && galleryUrls.length >= 4 && (
                      <div className={cn(style.previewButton)} onClick={() => setIsLightBoxOpen(true)}>
                        Show all photos
                      </div>
                    )}
                  </div>
                ))}
              </div>
            </div>
            <div className={cn(style.buttons)}>
              <Button text="Book Now" onClick={buttonOnClick} />
            </div>
            <div className={cn(style.content)}>
              <div className={cn(style.left)}>
                <div className={cn(style.contentBlock)}>
                  <div className={cn(style.contentBlockTitle)}>Description</div>
                  <div className={cn(style.contentBlockDescription)} dangerouslySetInnerHTML={{ __html: description }} />
                </div>
                {!!list1 && (
                  <div className={cn(style.contentBlock, style.contentBlockHalf)}>
                    <div className={cn(style.contentBlockTitle)}>What’s Included</div>
                    <div className={cn(style.contentBlockDescription)} dangerouslySetInnerHTML={{ __html: list1 }} />
                  </div>
                )}
                {!!list2 && (
                  <div className={cn(style.contentBlock, style.contentBlockHalf)}>
                    <div className={cn(style.contentBlockTitle)}>What to Bring</div>
                    <div className={cn(style.contentBlockDescription)} dangerouslySetInnerHTML={{ __html: list2 }} />
                  </div>
                )}
              </div>
              <div className={cn(style.right)}>
                {!!contentPriceTable && (
                  <div className={cn(style.contentBlock)}>
                    <div className={cn(style.contentBlockTitle)}>Price table</div>
                    <div className={cn(style.contentBlockDescription)} dangerouslySetInnerHTML={{ __html: contentPriceTable }} />
                  </div>
                )}
                {!!additionalInformation && (
                  <div className={cn(style.contentBlock)}>
                    <div className={cn(style.contentBlockTitle)}>Important Information</div>
                    <div className={cn(style.contentBlockDescription)} dangerouslySetInnerHTML={{ __html: additionalInformation }} />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <LightBox isOpen={isLightBoxOpen} setIsOpen={setIsLightBoxOpen} list={[image, ...galleryUrls]} activeIndex={activeLightBoxIndex} setActiveIndex={setActiveLightBoxIndex} />
    </>
  );
};

DetailModal.propTypes = {
  status: PropTypes.bool,
  setStatus: PropTypes.func,
  title: PropTypes.string,
  gallery: PropTypes.array,
  image: PropTypes.string,
  description: PropTypes.string,
  list1: PropTypes.string,
  list2: PropTypes.string,
  additionalInformation: PropTypes.string,
  contentPriceTable: PropTypes.string,
  buttonOnClick: PropTypes.func,
};

export default DetailModal;
