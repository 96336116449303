import React, { useState, useRef, useEffect } from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import useOnClickOutside from 'utils/UseOnClickOutside';
import style from './dropdownBorder.module.scss';
import icon from '../Select/img/chevron-down.png';

const Dropdown = ({ Trigger, Content, label, hint, required, event }) => {
  const [open, setOpen] = useState(false);

  const containerRef = useRef();

  const onClick = () => {
    setOpen(!open);
  };

  useOnClickOutside(containerRef, () => setOpen(false));

  useEffect(() => {
    setOpen(false);
  }, [event]);

  return (
    <div
      className={cn(style.container, {
        [style.open]: open,
      })}
      ref={containerRef}
    >
      <div className={cn(style.trigger)} onClick={onClick}>
        {Trigger ? (
          Trigger
        ) : (
          <>
            <div className={cn(style.triggerBlock)}>
              <div className={cn(style.triggerLabel, hint && style.triggerLabelTop)}>
                {label}
                {required && <mark>*</mark>}
              </div>
              <div className={cn(style.triggerHint)}>{hint}</div>
            </div>
            <img src={icon} className={cn(style.triggerIcon)} />
          </>
        )}
      </div>
      <div className={cn(style.content)}>{Content}</div>
    </div>
  );
};

Dropdown.propTypes = {
  Trigger: PropTypes.element,
  Content: PropTypes.element.isRequired,
  label: PropTypes.string,
  required: PropTypes.bool,
  hint: PropTypes.string,
  event: PropTypes.any,
};

export default Dropdown;
