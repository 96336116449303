import React, { useRef, useState } from 'react';
import cn from 'classnames';
import style from '../form.module.scss';
import simpleValidator from '../../../utils/SimpleValidator';
import ReservationStore from '../../../store/ReservationStore';
import capitalizeFirstLetter from '../../../utils/CapitalizeFirstLetter';
import Field from '../../Field';
import BookingStore from '../../../store/BookingStore';
import { settingRulesForPrivate, settingRulesForPublic } from '../ValidationSchema';
import PropTypes from 'prop-types';

const SettingForm = ({ currentBoatGroup }) => {
  const reservationStore = ReservationStore.useContainer();
  const bookingStore = BookingStore.useContainer();
  const formRef = useRef(null);
  const [isShow, setIsShow] = useState(true);

  /**
   * @param type {string} "increase" | "decrease"
   * */
  const tipsOnChange = (type) => {
    const types = {
      increase: () => {
        const min = +reservationStore.settings.tipPercent;
        const amount = +reservationStore.tips + +reservationStore.settings.tipStep;

        return amount <= min ? min : amount >= +reservationStore.settings.tipMax ? +reservationStore.settings.tipMax : amount;
      },
      decrease: () => {
        let amount = 0;
        const min = reservationStore.isTipOptional ? 0 : +reservationStore.settings.tipPercent;
        if (reservationStore.isTipOptional) {
          amount = +reservationStore.tips <= +reservationStore.settings.tipPercent ? 0 : +reservationStore.tips - +reservationStore.settings.tipStep;
        } else {
          amount = +reservationStore.tips - +reservationStore.settings.tipStep <= min ? min : +reservationStore.tips - +reservationStore.settings.tipStep;
        }

        return amount;
      },
    };

    if (!Object.keys(types).includes(type)) return;

    reservationStore.setTips(types[type]());

    if (bookingStore.type === 'private') {
      reservationStore.setFormErrors({
        ...reservationStore.formError,
        tips: { touched: true, error: simpleValidator(settingRulesForPrivate.tips(reservationStore.settings.tipPercent, reservationStore.settings.tipMax), types[type](), { min: +reservationStore?.settings?.tipPercent, max: reservationStore.settings.tipMax }) },
      });
    }

    if (bookingStore.type === 'public') {
      reservationStore.setFormErrors({
        ...reservationStore.formError,
        tips: { touched: true, error: simpleValidator(settingRulesForPublic.tips(reservationStore.settings.tipPercent, reservationStore.settings.tipMax), types[type](), { min: +reservationStore?.settings?.tipPercent, max: reservationStore.settings.tipMax }) },
      });
    }
  };

  const isTipsDecreaseDisable = () => {
    if (reservationStore.isTipOptional) {
      return reservationStore.tips <= 0;
    }
    return reservationStore.tips <= reservationStore?.settings?.tipPercent;
  };

  const isTipsIncreaseDisable = () => reservationStore.tips >= reservationStore?.settings?.tipMax;

  const onChange = (e) => {
    const { value, name, checked, type } = e.target;

    let _value = value;

    if (type === 'checkbox') {
      _value = checked;
    }

    if (name === 'tips') {
      if (bookingStore.type === 'private') {
        reservationStore.setFormErrors({
          ...reservationStore.formError,
          [name]: { touched: true, error: simpleValidator(settingRulesForPrivate[name], _value, { min: +reservationStore?.settings?.tipPercent || 10, max: 100 }) },
        });
      }

      if (bookingStore.type === 'public') {
        reservationStore.setFormErrors({
          ...reservationStore.formError,
          [name]: { touched: true, error: simpleValidator(settingRulesForPublic[name], _value, { min: +reservationStore?.settings?.tipPercent || 10, max: 100 }) },
        });
      }
    } else {
      if (bookingStore.type === 'private') {
        reservationStore.setFormErrors({
          ...reservationStore.formError,
          [name]: { touched: true, error: simpleValidator(settingRulesForPrivate[name], _value) },
        });
      }

      if (bookingStore.type === 'public') {
        reservationStore.setFormErrors({
          ...reservationStore.formError,
          [name]: { touched: true, error: simpleValidator(settingRulesForPublic[name], _value) },
        });
      }
    }

    reservationStore[`set${capitalizeFirstLetter(name)}`](_value);
  };

  return (
    <form ref={formRef} className={cn(style.container)} onChange={onChange}>
      <div className={cn(style.title, !isShow && style.titleMobileCollapse, style.titleMobileBorder)} onClick={() => setIsShow(!isShow)}>
        3. Settings
      </div>
      <div className={cn(style.content, !isShow && style.contentHide)}>
        <div className={cn(style.list)}>
          {currentBoatGroup?.isTipAvailable ? (
            <>
              <div className={cn(style.item)}>
                <div className={cn(style.tipsBlock)}>
                  <div className={cn(style.tipsBlockTitle)}>Tips:</div>
                  <div className={cn(style.tipsBlockAction, isTipsDecreaseDisable() && style.tipsBlockActionDisabled)} onClick={() => tipsOnChange('decrease')}>
                    -
                  </div>
                  <div className={cn(style.tipsBlockAmount)}>{reservationStore.tips} %</div>
                  <div className={cn(style.tipsBlockAction, isTipsIncreaseDisable() && style.tipsBlockActionDisabled)} onClick={() => tipsOnChange('increase')}>
                    +
                  </div>
                </div>
              </div>
              {reservationStore.isTipOptional ? null : (
                <div className={cn(style.item, style.tips)}>
                  <div className={cn(style.tipsText)}>{reservationStore?.settings?.tipPercent}% minimum.</div>
                </div>
              )}
            </>
          ) : null}
          <div className={cn(style.item)}>
            <Field
              type="checkbox"
              value={reservationStore.agreeTerms}
              required={true}
              label={
                <>
                  I agree to Chicago Electric Boat Company{' '}
                  <a href="/terms-and-conditions" target="_blank">
                    Terms and Conditions
                  </a>{' '}
                  and{' '}
                  <a href="/privacy-policy" target="_blank">
                    Privacy Policy
                  </a>
                  . I also agree to receive promotional messages and emails sent via Twilio and Mailchimp. Msg & Data rates may apply.
                </>
              }
              name="agreeTerms"
              error={!!reservationStore.formError.agreeTerms?.touched && !!reservationStore.formError.agreeTerms?.error}
            />
            {!!reservationStore.formError.agreeTerms?.touched && !!reservationStore.formError.agreeTerms?.error && <div className={cn(style.error)}>{reservationStore.formError.agreeTerms.error}</div>}
          </div>
          {bookingStore.type === 'private' && (
            <div className={cn(style.item)}>
              <Field
                type="checkbox"
                value={reservationStore.securityDeposit}
                required={true}
                label={
                  <>
                    {'I understand that 24 hours prior to my cruise, my credit card will have a '}
                    <a href="/security-deposit" target={'_blank'}>
                      ${reservationStore.settings?.securityDeposit} security deposit pre-authorization.
                    </a>{' '}
                    This charge will be released 24 hrs after my cruise has been completed if no damages occur.
                  </>
                }
                name="securityDeposit"
                error={!!reservationStore.formError.securityDeposit?.touched && !!reservationStore.formError.securityDeposit?.error}
              />
              {!!reservationStore.formError.securityDeposit?.touched && !!reservationStore.formError.securityDeposit?.error && <div className={cn(style.error)}>{reservationStore.formError.securityDeposit.error}</div>}
            </div>
          )}
          <div className={cn(style.item)}>
            <Field
              type="checkbox"
              value={reservationStore.receiveEmails}
              required={true}
              label={<>I want to receive exclusive deals and updates from Chicago Cycleboats.</>}
              name="receiveEmails"
              error={!!reservationStore.formError.receiveEmails?.touched && !!reservationStore.formError.receiveEmails?.error}
            />
            {!!reservationStore.formError.receiveEmails?.touched && !!reservationStore.formError.receiveEmails?.error && <div className={cn(style.error)}>{reservationStore.formError.receiveEmails.error}</div>}
          </div>
        </div>
      </div>
    </form>
  );
};

SettingForm.propTypes = {
  currentBoatGroup: PropTypes.object,
};

export default SettingForm;
