import React, { useState } from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import style from './form.module.scss';
import Field from 'componenets/Field';
import Button from 'componenets/Button';
import simpleValidator from '../../utils/SimpleValidator';

const Form = ({ fields, title, subtitle, buttonText, validationSchema, onFormSubmit }) => {
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [form, setForm] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    message: '',
    agree: false,
  });

  const clearForm = () => {
    setForm({
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      message: '',
      agree: false,
    });
  };

  const onChange = (e) => {
    const { name, value, checked, type } = e.target;

    let _value = value;

    if (type === 'checkbox') {
      _value = checked;
    }

    if (validationSchema) {
      setErrors({
        ...errors,
        [name]: simpleValidator(validationSchema[name], _value),
      });
    }

    setForm({
      ...form,
      [name]: _value,
    });
  };

  const formOnSubmit = async (e) => {
    e.preventDefault();

    let _errors = {};

    if (validationSchema) {
      Object.entries(form).forEach(([name, value]) => {
        _errors[name] = simpleValidator(validationSchema[name], value);
      });

      setErrors({
        ...errors,
        ..._errors,
      });
    }

    if (!Object.values(_errors).every((item) => item === null)) return;

    setLoading(true);

    let responseHasError = false;

    if (onFormSubmit) {
      responseHasError = await onFormSubmit(form);
    }

    setLoading(false);

    if (!responseHasError) {
      clearForm();
    }
  };

  return (
    <form className={cn(style.container)} onChange={onChange}>
      {!!title && <div className={cn(style.title)} dangerouslySetInnerHTML={{ __html: title }} />}
      {!!subtitle && <div className={cn(style.subtitle)} dangerouslySetInnerHTML={{ __html: subtitle }} />}
      <div className={cn(style.list)}>
        {fields.map((item) => (
          <div className={cn(style.item)} key={item.id}>
            <Field value={form[item.name]} {...item} />
            {errors[item.name] && <div className={cn(style.error)}>{errors[item.name]}</div>}
          </div>
        ))}
      </div>
      <Button text={buttonText} loading={loading} onClick={formOnSubmit} />
    </form>
  );
};

Form.propTypes = {
  fields: PropTypes.array,
  validationSchema: PropTypes.object,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  buttonText: PropTypes.string.isRequired,
  onFormSubmit: PropTypes.func,
};

export default Form;
