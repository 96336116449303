import React, { useState } from 'react';
import cn from 'classnames';
import { Carousel } from 'react-responsive-carousel';
import LightBox from '../../LightBox';
import PropTypes from 'prop-types';
import style from './slider.module.scss';
import sliderImage1 from './img/1.webp';
import sliderImage2 from './img/2.webp';
import sliderImage3 from './img/3.webp';

const list = [
  {
    id: 7890,
    src: sliderImage1,
  },
  {
    id: 7891,
    src: sliderImage2,
  },
  {
    id: 7892,
    src: sliderImage3,
  },
];

const Slide = ({ src, label, onClick }) => {
  return (
    <div className={cn(style.slide)} onClick={onClick}>
      <img className={cn(style.image)} src={src} alt={label} />
    </div>
  );
};

Slide.propTypes = {
  src: PropTypes.string,
  label: PropTypes.string,
  onClick: PropTypes.func,
};

const Slider = () => {
  const [isLightBoxOpen, setIsLightBoxOpen] = useState(false);
  const [activeLightBoxIndex, setActiveLightBoxIndex] = useState(0);

  const openLightBox = (index) => {
    setActiveLightBoxIndex(index);
    setIsLightBoxOpen(true);
  };

  return (
    <>
      <div className={cn(style.container)}>
        <Carousel showThumbs={false} showStatus={false} swipeable={true}>
          {list.map((item, index) => (
            <Slide {...item} key={item.id} onClick={() => openLightBox(index)} />
          ))}
        </Carousel>
      </div>
      <LightBox activeIndex={activeLightBoxIndex} setActiveIndex={setActiveLightBoxIndex} isOpen={isLightBoxOpen} setIsOpen={setIsLightBoxOpen} list={list.map((item) => item.src)} />
    </>
  );
};

export default Slider;
