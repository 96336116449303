import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import style from './modal.module.scss';
import cn from 'classnames';
import Button from '../Button';
import toggleBodyHidden from '../../utils/ToggleBodyHidden';

const Modal = ({ children, active, title, subtitle, onClose, onSuccess, successButtonDisable, buttonSuccessText, buttonCloseText, hideCloseButton, hideSuccessButton, isError }) => {
  const onCloseHandle = () => {
    toggleBodyHidden(false);
    onClose();
  };

  const onSuccessHandle = () => {
    toggleBodyHidden(false);
    onSuccess();
  };

  useEffect(() => {
    if (active) {
      toggleBodyHidden(true);
    }
  }, [active]);

  useEffect(() => {
    if (active) {
      toggleBodyHidden(true);
    }
  }, []);

  return (
    <div className={cn(style.container, active && style.active)}>
      <div className={cn(style.overlay)} onClick={onCloseHandle} />
      <div className={cn(style.content)}>
        <div className={cn(style.inner)}>
          {(!title && !subtitle) || (
            <div className={cn(style.header)}>
              <div className={cn(style.title)}>{title}</div>
              <div className={cn(style.subtitle)}>{subtitle}</div>
            </div>
          )}
          <div className={cn(style.body, hideSuccessButton && hideCloseButton && style.bodyNoFooter)}>{children}</div>
          {hideSuccessButton && hideCloseButton ? null : (
            <div className={cn(style.footer)}>
              {hideSuccessButton || (
                <div className={cn(style.button)}>
                  <Button text={buttonSuccessText} disable={successButtonDisable} error={isError} onClick={onSuccessHandle} />
                </div>
              )}
              {hideCloseButton || (
                <div className={cn(style.button)}>
                  <Button text={buttonCloseText} outline={true} onClick={onCloseHandle} />
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

Modal.propTypes = {
  children: PropTypes.node,
  active: PropTypes.bool.isRequired,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  subtitle: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  onClose: PropTypes.func,
  onSuccess: PropTypes.func,
  successButtonDisable: PropTypes.bool,
  hideCloseButton: PropTypes.bool,
  hideSuccessButton: PropTypes.bool,
  buttonCloseText: PropTypes.string,
  buttonSuccessText: PropTypes.string,
  isError: PropTypes.bool,
};

Modal.defaultProps = {
  active: true,
  onClose: () => {},
  onSuccess: () => {},
};

export default Modal;
