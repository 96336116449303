import React from 'react';
import { MapContainer, TileLayer, ZoomControl } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import Marker from './Marker';
import PropTypes from 'prop-types';

const LeafletMap = ({ center, markers, zoom }) => {
  return (
    <MapContainer center={center} zoom={zoom} zoomControl={false} style={{ height: '100%' }}>
      <ZoomControl position="bottomright"> </ZoomControl>
      <TileLayer attribution={'&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'} url="https://{s}.basemaps.cartocdn.com/rastertiles/voyager/{z}/{x}/{y}{r}.png" />
      {markers.map((item) => (
        <Marker {...item} key={JSON.stringify(item)} />
      ))}
    </MapContainer>
  );
};

LeafletMap.propTypes = {
  center: PropTypes.array.isRequired,
  markers: PropTypes.array.isRequired,
  zoom: PropTypes.number.isRequired,
};

export default LeafletMap;
