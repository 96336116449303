import React from 'react';
import Modal from '../../../componenets/Modal';
import cn from 'classnames';
import PropTypes from 'prop-types';
import style from './success.module.scss';

const ErrorModal = ({ active, onClose, error }) => {
  return (
    <Modal active={active} title="Error" subtitle="" buttonSuccessText="Close" isError={true} hideCloseButton={true} onSuccess={onClose}>
      <div className={cn(style.container)}>
        <div className={cn(style.list)}>
          <div className={cn(style.item)}>
            <div className={cn(style.title)}>An error occurred</div>
            <br />
            <div className={cn(style.description)}>{error}</div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

ErrorModal.propTypes = {
  active: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  error: PropTypes.string.isRequired,
};

export default ErrorModal;
