import React from 'react';
import cn from 'classnames';
import style from './footer.module.scss';
import Socials from 'componenets/Socials';
import { Link, useLocation } from 'react-router-dom';

const Footer = () => {
  const location = useLocation();
  const dark = location.pathname !== '/';
  return (
    <footer className={cn(style.container, dark && style.containerDark)}>
      <div className={cn(style.inner)}>
        <div className={cn(style.block)}>
          <div className={cn(style.item)}>© 2022 Chicago Duffy, LLC</div>
          <div className={cn(style.item)}>DBA Chicago Electric Boat Company</div>
          <div className={cn(style.item)}>Chicago Boat Rentals</div>
          <Link to="terms-and-conditions" className={cn(style.item, style.link)}>
            Terms & Conditions
          </Link>
          <Link to="privacy-policy" className={cn(style.item, style.link)}>
            Privacy Policy
          </Link>
        </div>
        <div className={cn(style.socials)}>
          <Socials />
        </div>
      </div>
    </footer>
  );
};

export default Footer;
