import React from 'react';
import Modal from '../../../componenets/Modal';
import cn from 'classnames';
import PropTypes from 'prop-types';
import style from './errorModal.module.scss';
import ErrorIcon from './icon.svg';

// eslint-disable-next-line no-unused-vars
const ErrorModal = ({ active, onClose, title, description }) => {
  const _title = (
    <div className={cn(style.title)}>
      <img src={ErrorIcon} alt="" className={cn(style.titleIcon)} />
      <div className={cn(style.titleText)}>Error</div>
    </div>
  );
  /**
   * Temporarily
   * We are waiting for the backend
   * */
  const subtitle =
    description === 'The time slot is no longer available.' ? (
      <div className={cn(style.subtitle)}>
        The time slot is no longer available.
        <br />
        Select another time or contact our support team.
        <br />
        <br />
        <a href="mailto:info@cycleboats.com">info@cycleboats.com</a>
        <br />
        <a href="tel:3126446411">(312) 644-6411</a>
      </div>
    ) : (
      <div className={cn(style.subtitle)} dangerouslySetInnerHTML={{ __html: description }} />
    );

  return <Modal active={active} title={_title} subtitle={subtitle} buttonSuccessText="Select another time" hideCloseButton={true} onSuccess={onClose} />;
};

ErrorModal.propTypes = {
  active: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  title: PropTypes.string,
  description: PropTypes.string.isRequired,
};

export default ErrorModal;
