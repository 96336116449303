import cn from 'classnames';
import style from '../ourBoats.module.scss';
import yachtIcon from '../img/yacht.svg';
import React from 'react';
import PropTypes from 'prop-types';
import Card from '../Card';
import BookingStore from '../../../store/BookingStore';

const PublicBoats = ({ list }) => {
  const bookingStore = BookingStore.useContainer();
  const setBoatGroup = (id) => {
    const findItem = list.find((item) => +item.id === +id);

    if (!findItem) return;

    bookingStore.setBoatGroup(findItem);
    bookingStore.setLocation(findItem?.location || null);
    bookingStore.setSelectedMarker(
      findItem?.location?.coordinates?.latitude && findItem?.location?.coordinates?.longitude
        ? {
            lat: findItem.location.coordinates.latitude,
            lng: findItem.location.coordinates.longitude,
          }
        : null,
    );
  };

  return (
    <div className={cn(style.block)}>
      <div className={cn(style.blockTitle)}>
        <img src={yachtIcon} alt="" />
        <span>Public Boats</span>
      </div>
      <div className={cn(style.list)}>
        {list.map((item) => (
          <div className={cn(style.item)} key={`public-${item.name}`}>
            <Card {...item} type={'public'} setBoatGroup={setBoatGroup} />
          </div>
        ))}
      </div>
    </div>
  );
};

PublicBoats.propTypes = {
  list: PropTypes.array.isRequired,
};

export default PublicBoats;
