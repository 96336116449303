import React from 'react';
import Modal from '../../../componenets/Modal';
import PropTypes from 'prop-types';
import cn from 'classnames';
import BookingStore from '../../../store/BookingStore';
import style from './cruiseTimeModal.module.scss';
import clockImage from '../img/clock.svg';
import getTimeSlotString from '../../../utils/GetTimeSlotString';

const CruiseTimeModal = ({ active, onClose, onSuccess }) => {
  const bookingStore = BookingStore.useContainer();

  const onCloseWrapper = () => {
    bookingStore.setBoatGroup(null);

    onClose();
  };

  if (!active) return null;

  return (
    <Modal active={active} title="Select Cruise Time" subtitle="Check available time slots options." onClose={onCloseWrapper} onSuccess={onSuccess} successButtonDisable={!bookingStore.selectedTimeSlot} buttonCloseText="Cancel" buttonSuccessText="Next">
      <div className={cn(style.container)}>
        {bookingStore.timeSlotsLoading && 'Loading...'}
        {bookingStore.timeSlots.map(({ name, list }) => (
          <div className={cn(style.block)} key={name}>
            <div className={cn(style.title)}>
              <img src={clockImage} alt="" />
              <div>{name}</div>
            </div>
            <div className={cn(style.list)}>
              {list.map((item) => (
                <div className={cn(style.item, bookingStore.selectedTimeSlot === item && style.active)} onClick={() => bookingStore.setSelectedTimeSlot(item)} key={item['@id']}>
                  {getTimeSlotString(item)} (${item.price})
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    </Modal>
  );
};

CruiseTimeModal.propTypes = {
  active: PropTypes.bool,
  onClose: PropTypes.func,
  onSuccess: PropTypes.func,
};

export default CruiseTimeModal;
