/*eslint-disable*/
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { useNavigate } from 'react-router-dom';
import BookingStore from '../../../store/BookingStore';
import { timeByMinutes } from '../../../utils/TimeByMinutes';
import toggleBodyHidden from '../../../utils/ToggleBodyHidden';
import CruiseTimeModal from '../modals/CruiseTimeModal';
import BookingModal from '../../../pages/Booking/BookingModal';
import DetailModal from '../modals/DetailModal';
import CruiseDataModal from '../modals/CruiseDataModal';
import NotFoundTimeSlotsModal from '../modals/NotFoundTimeSlotsModal';
import Button from '../../Button';
import style from './card.module.scss';

const Card = ({ type, name: title, description, image, privateType, publicType, gallery, content, setBoatGroup, id, capacity }) => {
  const bookingStore = BookingStore.useContainer();
  const navigate = useNavigate();
  const [isDetailModalShow, setIsDetailModalShow] = useState(false);
  const [isCruiseDataModalShow, setIsCruiseDataModalShow] = useState(false);
  const [isCruisesTimeSlotModalShow, setIsCruisesTimeSlotModalShow] = useState(false);
  const [isBookingModalShow, setIsBookingModalShow] = useState(false);
  const [isNFTSModalShow, setIsNFTSModalShow] = useState(false);

  const openCruiseDataModal = () => {
    clearData();

    if (type === 'public') {
      if (!publicType?.reservationTime) {
        bookingStore.setLength(`15-600`);
      } else {
        const { min, max } = publicType.reservationTime;

        bookingStore.setLength(`${min}-${max}`);
      }
    }
    if (type === 'private') {
      if (!privateType?.reservationTime) {
        bookingStore.setLength(`15-600`);
      } else {
        const { min, max } = privateType.reservationTime;

        bookingStore.setLength(`${min}-${max}`);
      }
    }

    setIsDetailModalShow(false);
    bookingStore.setType(type);
    setBoatGroup(id);

    setTimeout(() => {
      setIsCruiseDataModalShow(true);
    }, 50);
  };

  const cruiseDataModalOnSuccess = () => {
    setIsCruiseDataModalShow(false);
    setIsCruisesTimeSlotModalShow(true);
  };

  const cruiseTimeModalOnSuccess = () => {
    setIsCruisesTimeSlotModalShow(false);
    setIsBookingModalShow(true);
  };

  const bookingModalOnSuccess = () => {
    if (process.env.REACT_APP_GOOGLE_TAG_MANAGER_ID && window.dataLayer.push) {
      window.dataLayer.push({
        event: 'CHECKOUT_PAGE',
        dataLayer: {
          date: bookingStore.date,
          type: bookingStore.type,
          length: bookingStore.length,
          numberOfSeats: bookingStore.numberOfSeats,
          boatGroup: bookingStore.boatGroup,
          location: bookingStore.location,
          timeSlot: bookingStore.selectedTimeSlot,
        },
      });
    }

    setTimeout(() => {
      navigate('/reservation');
    }, 15);
  };

  const nFTSModalOnSuccess = () => {
    if (bookingStore.timeSlots?.length) {
      setIsCruisesTimeSlotModalShow(true);

      setIsNFTSModalShow(false);
    } else {
      setIsNFTSModalShow(true);
    }
  };

  const cruiseDataModalOnClose = () => {
    setIsCruiseDataModalShow(false);

    clearData();

    setTimeout(() => {
      toggleBodyHidden(false);
    }, 150);
  };

  const cruiseTimeModalOnClose = () => {
    setIsCruisesTimeSlotModalShow(false);

    clearData();

    setTimeout(() => {
      toggleBodyHidden(false);
    }, 150);
  };

  const bookingModalOnClose = () => {
    setIsBookingModalShow(false);

    clearData();

    setTimeout(() => {
      toggleBodyHidden(false);
    }, 150);
  };

  const nFTSModalOnClose = () => {
    setIsNFTSModalShow(false);

    clearData();

    setTimeout(() => {
      toggleBodyHidden(false);
    }, 150);
  };

  const cruiseTimeModalOnError = () => {
    setIsCruisesTimeSlotModalShow(false);
    setIsNFTSModalShow(true);
  };

  const clearData = () => {
    bookingStore.setType(null);
    bookingStore.setDate(null);
    bookingStore.setLength(null);
    bookingStore.setBoatGroup(null);
    bookingStore.setNumberOfSeats(null);
    bookingStore.setSelectedTimeSlot(null);
    bookingStore.setSelectedMarker(null);

    bookingStore.setLocation(null);
    bookingStore.setSelectedMarker(null);
  };

  const getPriceFromByType = () => {
    if (type === 'public') {
      return publicType.priceFrom;
    }

    if (type === 'private') {
      return privateType.priceFrom;
    }

    return null;
  };

  const getContentPriceTable = () => {
    if (type === 'public') {
      return publicType.contentPriceTable;
    }

    if (type === 'private') {
      return privateType.contentPriceTable;
    }

    return null;
  };

  useEffect(() => {
    if (!!isDetailModalShow && !isCruiseDataModalShow && !isCruisesTimeSlotModalShow && !isBookingModalShow && !isNFTSModalShow) {
      setTimeout(() => {
        toggleBodyHidden(false);
      }, 200);
    }
  }, [isDetailModalShow, isCruiseDataModalShow, isCruisesTimeSlotModalShow, isBookingModalShow, isNFTSModalShow]);

  return (
    <>
      <div className={cn(style.container)}>
        <div className={cn(style.header)}>
          <div className={cn(style.image)}>
            <img src={process.env.REACT_APP_API_URL + image.contentUrl} alt="" />
          </div>
          <div className={cn(style.price)}>
            Starting at <span>${getPriceFromByType()?.amount}</span> / {timeByMinutes(getPriceFromByType()?.timeStep)}
          </div>
        </div>
        <div className={cn(style.main)}>
          <div className={cn(style.title)}>{title}</div>
          <div className={cn(style.description)} dangerouslySetInnerHTML={{ __html: content?.shortDescription }} />
        </div>
        <div className={cn(style.footer)}>
          <div className={cn(style.buttons)}>
            <Button text="Details" outline={true} onClick={() => setIsDetailModalShow(true)} />
            <Button text="Book Now" onClick={openCruiseDataModal} />
          </div>
        </div>
      </div>
      <DetailModal
        status={isDetailModalShow}
        setStatus={setIsDetailModalShow}
        title={title}
        gallery={gallery}
        image={process.env.REACT_APP_API_URL + image.contentUrl}
        description={description}
        list1={content?.list1 || ''}
        list2={content?.list2 || ''}
        additionalInformation={content?.additionalInformation || ''}
        contentPriceTable={getContentPriceTable() || ''}
        buttonOnClick={openCruiseDataModal}
      />
      <CruiseDataModal active={isCruiseDataModalShow} onClose={cruiseDataModalOnClose} onSuccess={cruiseDataModalOnSuccess} title={title} capacity={capacity} />
      <CruiseTimeModal active={isCruisesTimeSlotModalShow} onClose={cruiseTimeModalOnClose} onSuccess={cruiseTimeModalOnSuccess} onError={cruiseTimeModalOnError} />
      <NotFoundTimeSlotsModal title={title} active={isNFTSModalShow} onClose={nFTSModalOnClose} onSuccess={nFTSModalOnSuccess} />
      <BookingModal active={isBookingModalShow} onClose={bookingModalOnClose} onSuccess={bookingModalOnSuccess} />
    </>
  );
};

Card.propTypes = {
  type: PropTypes.string,
  name: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  privateType: PropTypes.object,
  publicType: PropTypes.object,
  gallery: PropTypes.array,
  content: PropTypes.object,
  setBoatGroup: PropTypes.func,
  id: PropTypes.number,
  capacity: PropTypes.number,
};

export default Card;
