import L from 'leaflet';
import icon from './marker.png';

const iconDefault = new L.Icon({
  iconUrl: icon,
  iconRetinaUrl: icon,
  iconAnchor: [11, 36],
  popupAnchor: null,
  shadowUrl: null,
  shadowSize: null,
  shadowAnchor: null,
  iconSize: new L.Point(22, 36),
});

export default iconDefault;
