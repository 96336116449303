import React, { useEffect } from 'react';
import style from './mobileMenu.module.scss';
import PropTypes from 'prop-types';
import cn from 'classnames';
import Link from '../Link';
import Button from '../Button';

const MobileMenu = ({ status, setStatus }) => {
  const onClickCallback = () => {
    setStatus(false);
  };

  useEffect(() => {
    if (!document.body) return;

    document.body.style.overflow = status ? 'hidden' : '';
  }, [status]);

  return (
    <div
      className={cn(style.container, {
        [style.active]: status,
      })}
    >
      <div className={cn(style.inner)}>
        <div className={cn(style.list)}>
          <Link anchor="home" to={'/'} label={'Home'} type={'anchor'} className={cn(style.link)} onClickCallback={onClickCallback} />
          <Link anchor="about" to={'/'} label={'About'} type={'anchor'} className={cn(style.link)} onClickCallback={onClickCallback} />
          <Link anchor="contact" to={'/'} label={'Contact'} type={'anchor'} className={cn(style.link)} onClickCallback={onClickCallback} />
          <Link anchor="faq" to={'/'} label={'Policies & FAQ'} type={'anchor'} className={cn(style.link)} onClickCallback={onClickCallback} />
          <Link to="https://waiver.smartwaiver.com/w/5af3175fcfa01/web/" label="Waiver" type="link" target="_blank" className={cn(style.link)} />
        </div>
        <Link anchor="home" to={'/'} label={'Book your cruise right now'} type={'anchor'} className={cn(style.findTheBoatButton)} onClickCallback={onClickCallback} />
        <div className={style.buttons}>
          <div className={style.button}>
            <Button text="Call Us" outline={true} />
          </div>
          <div className={style.button}>
            <Button text="Mail Us" outline={true} />
          </div>
          <div className={style.button}>
            <Button text="Find Us" outline={true} />
          </div>
        </div>
      </div>
    </div>
  );
};

MobileMenu.propTypes = {
  status: PropTypes.bool.isRequired,
  setStatus: PropTypes.func.isRequired,
};

export default MobileMenu;
