import React from 'react';
import style from './menu.module.scss';
import cn from 'classnames';
import Link from '../Link';

const Menu = () => {
  return (
    <nav className={cn(style.container)}>
      <ul className={cn(style.list)}>
        <li className={cn(style.item)}>
          <Link anchor="home" to="/" label="Home" type="anchor" className={cn(style.link)} />
        </li>
        <li className={cn(style.item)}>
          <Link anchor="about" to="/" label="About" type="anchor" className={cn(style.link)} />
        </li>
        <li className={cn(style.item)}>
          <Link anchor="contact" to="/" label="Contact" type="anchor" className={cn(style.link)} />
        </li>
        <li className={cn(style.item)}>
          <Link anchor="faq" to="/" label="Policies & FAQ" type="anchor" className={cn(style.link)} />
        </li>
        {process.env.REACT_APP_HIDE_BOAT_PASS ? null : (
          <li className={cn(style.item)}>
            <Link to="/boat-pass" label="Boat Pass" type="link" className={cn(style.link)} />
          </li>
        )}
        <li className={cn(style.item)}>
          <Link to="https://waiver.smartwaiver.com/w/5af3175fcfa01/web/" label="Waiver" type="link" target="_blank" className={cn(style.link)} />
        </li>
      </ul>
    </nav>
  );
};

export default Menu;
