import React, { useEffect, useState } from 'react';
import cn from 'classnames';
import style from './ourBoats.module.scss';
import PublicBoats from './PublicBoats';
import PrivateBoats from './PrivateBoats';
import notification from '../../utils/Notification';
import { GET_BOAT_GROUPS } from '../../utils/Api';
import useFetch from '../../utils/UseFetch';
import BookingStore from '../../store/BookingStore';

const OurBoats = () => {
  const bookingStore = BookingStore.useContainer();
  const [publicBoatGroups, setPublicBoatGroups] = useState([]);
  const [privateBoatGroups, setPrivateBoatGroups] = useState([]);
  const [loading, setLoading] = useState(true);

  const getBoatGroups = async () => {
    setLoading(true);

    try {
      const data = await useFetch('get', GET_BOAT_GROUPS, {
        pagination: false,
      });

      setPublicBoatGroups(data.filter((item) => item.isPublicType));
      setPrivateBoatGroups(data.filter((item) => item.isPrivateType));
    } catch (error) {
      console.error(error);
      notification({ type: 'error', message: error.message });
    }

    setLoading(false);
  };

  useEffect(() => {
    getBoatGroups();
  }, []);

  useEffect(() => {
    bookingStore.getGroupMonthAvailableDates();
  }, [bookingStore.type, bookingStore.boatGroup]);

  return (
    <div className={cn(style.container)}>
      <div className={cn(style.inner)}>
        <div className={cn(style.uptitle)}>Chicago River Tours</div>
        <div className={cn(style.title)}>Our Boats</div>
        {!loading ? (
          <>
            <PublicBoats list={publicBoatGroups} />
            <PrivateBoats list={privateBoatGroups} />
          </>
        ) : (
          <div className={cn(style.loader)}>Loading...</div>
        )}
      </div>
    </div>
  );
};

export default OurBoats;
