import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import React from 'react';

const Link = ({ type, to, label, target, anchor, className, onClickCallback }) => {
  if (type === 'route') {
    return (
      <RouterLink to={to} className={className}>
        {label}
      </RouterLink>
    );
  }

  if (type === 'link') {
    return (
      <a href={to} className={className} target={target}>
        {label}
      </a>
    );
  }

  if (type === 'anchor') {
    if (!anchor) {
      console.error('"anchor" is required');
      return;
    }
    const goToAnchor = () => {
      setTimeout(() => {
        const element = document.getElementById(anchor);
        window.scrollTo({
          top: window.scrollY + element.getBoundingClientRect().top,
          behavior: 'smooth',
        });

        if (onClickCallback) onClickCallback();
      }, 50);
    };

    return (
      <RouterLink to={to} className={className} onClick={goToAnchor}>
        {label}
      </RouterLink>
    );
  }
};

Link.propTypes = {
  type: PropTypes.string.isRequired,
  to: PropTypes.string,
  label: PropTypes.string,
  target: PropTypes.string,
  anchor: PropTypes.string,
  className: PropTypes.any,
  onClickCallback: PropTypes.func,
};

export default Link;
