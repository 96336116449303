import React from 'react';
import PropTypes from 'prop-types';
import Modal from '../../../Modal';
import style from './nFTSModal.module.scss';
import cn from 'classnames';
import Datepicker from '../../../Datepicker';
import BookingStore from '../../../../store/BookingStore';
import moment from 'moment';

const NotFoundTimeSlotsModal = ({ title, active, onClose, onSuccess }) => {
  const bookingStore = BookingStore.useContainer();
  const dateOnChange = (data) => {
    bookingStore.setDate(data);
  };

  if (!active) return null;

  return (
    <Modal active={active} title="Book The Boat" subtitle={title} buttonCloseText="Cancel" buttonSuccessText="Next" onClose={onClose} onSuccess={onSuccess}>
      <div className={cn(style.container)}>
        <div className={cn(style.title)}>There are not available boats</div>
        <div className={cn(style.description)}>Choose another date</div>
        <div className={cn(style.list)}>
          <div className={cn(style.item)}>
            <div className={cn(style.label)}>
              Date <span>*</span>
            </div>
            <div className={cn(style.field)}>
              <Datepicker
                inline={true}
                initialValue={moment(bookingStore.date, 'MM/DD/YYYY').toDate()}
                minDate={new Date()}
                onChange={dateOnChange}
                availableDays={bookingStore.groupMonthAvailableDays}
                monthOnChange={bookingStore.getGroupMonthAvailableDates}
                loading={bookingStore.groupMonthAvailableDaysLoading}
              />
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

NotFoundTimeSlotsModal.propTypes = {
  title: PropTypes.string,
  active: PropTypes.bool,
  onClose: PropTypes.func,
  onSuccess: PropTypes.func,
};

export default NotFoundTimeSlotsModal;
