import React, { useEffect, useState } from 'react';
import cn from 'classnames';
import style from './contact.module.scss';
import Map from 'componenets/Map';
import Form from 'componenets/Form';
import ContactCard from 'componenets/ContactCard';
import emailIcon from './img/email.png';
import phoneIcon from './img/phone.png';
import mapIcon from './img/map.png';
import PropTypes from 'prop-types';
import Success from './Modal/Success';
import Error from './Modal/Error';
import useFetch from '../../utils/UseFetch';
import { CONTACT_FORM, GET_LOCATIONS } from '../../utils/Api';

const formFields = [
  {
    id: 801,
    type: 'text',
    required: true,
    placeholder: 'First Name',
    name: 'firstName',
  },
  {
    id: 802,
    type: 'text',
    required: true,
    placeholder: 'Last Name',
    name: 'lastName',
  },
  {
    id: 803,
    type: 'email',
    required: true,
    placeholder: 'Email',
    name: 'email',
  },
  {
    id: 804,
    type: 'phone',
    required: true,
    placeholder: 'Phone Number',
    name: 'phone',
    mask: '999-999-9999',
  },
  {
    id: 805,
    type: 'textarea',
    required: true,
    placeholder: 'Message',
    name: 'message',
  },
  {
    id: 806,
    type: 'checkbox',
    required: true,
    label: 'I Agree to the Privacy Policy',
    name: 'agree',
  },
];

const validationSchema = {
  firstName: {
    required: 'First name is required',
  },
  lastName: {
    required: 'Last name is required',
  },
  email: {
    required: 'Email is required',
    email: 'Not valid email',
  },
  phone: {
    phone: 'No valid phone number',
    required: 'Phone number is required',
  },
  message: {
    required: 'Message is required',
  },
  agree: {
    required: 'Agree with Private Policy is required',
  },
};

const contactCards = [
  {
    id: 650,
    icon: emailIcon,
    title: 'Chat with Us',
    description: 'Our team is here to help',
    link: {
      label: 'info@chicagocycleboats.com',
      href: 'mailto:info@chicagocycleboats.com',
    },
    hint: '',
  },
  {
    id: 651,
    icon: phoneIcon,
    title: 'Give Us a Call',
    description: 'Monday - Sunday 9pm - 8pm',
    link: {
      label: '(312) 644-6411',
      href: 'tel:(312) 644-6411',
    },
    hint: '',
  },
  {
    id: 652,
    icon: mapIcon,
    title: 'Visit us',
    link: {
      label: '300 N LaSalle Dr, Chicago IL, 60654',
      href: '',
    },
    hint: 'On the Riverwalk between LaSalle Street and Wells Street Bridges right next to Sweet Home Gelato',
  },
];

const Contact = ({ dark }) => {
  const [isModalSuccessActive, setIsModalSuccessActive] = useState(false);
  const [isModalErrorActive, setIsModalErrorActive] = useState(false);
  const [errorText, setErrorText] = useState('');
  const [locations, setLocation] = useState([]);

  const onFormSubmit = async (data) => {
    const requestBody = {
      firstName: data.firstName,
      lastName: data.lastName,
      email: data.email,
      phone: data.phone,
      message: data.message,
    };

    const response = await useFetch('post', CONTACT_FORM, requestBody);

    if (response.error) {
      setErrorText(response.description);
      setIsModalErrorActive(true);

      return true;
    } else {
      setIsModalSuccessActive(true);

      return false;
    }
  };

  const getLocation = async () => {
    const response = await useFetch('get', GET_LOCATIONS);

    setLocation(response);
  };

  const getCoordinates = () =>
    !locations?.length
      ? []
      : locations.map((item) => ({
          lat: item.coordinates.latitude,
          lng: item.coordinates.longitude,
        }));

  useEffect(() => {
    getLocation();
  }, []);

  return (
    <>
      <div className={cn(style.container, dark && style.containerDark)}>
        <div className={cn(style.inner)}>
          <div className={cn(style.content)}>
            <div className={cn(style.uptitle)}>Chicago River Tours</div>
            <div className={cn(style.title)}>How can we help?</div>
            <div className={cn(style.subtitle)}>Our team would love to hear from you!</div>
            <div className={cn(style.map)}>{!!getCoordinates().length && <Map markers={getCoordinates()} zoom={14} />}</div>
            <div className={cn(style.list)}>
              {contactCards.map((item) => (
                <div className={cn(style.item)} key={item.id}>
                  <ContactCard {...item} />
                </div>
              ))}
            </div>
          </div>
          <div className={cn(style.form)}>
            <Form title="Contact us" subtitle={'Fill out the form below, and we will get back to you shortly!'} fields={formFields} validationSchema={validationSchema} buttonText={'Send'} onFormSubmit={onFormSubmit} />
          </div>
        </div>
      </div>
      <Success onClose={() => setIsModalSuccessActive(false)} active={isModalSuccessActive} />
      <Error onClose={() => setIsModalErrorActive(false)} active={isModalErrorActive} error={errorText} />
    </>
  );
};

Contact.propTypes = {
  dark: PropTypes.bool,
};

export default Contact;
