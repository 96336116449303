import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import style from './addonsCheckbox.module.scss';
import Check from '../Check';

const AddonCheckbox = ({ label, price, description, required, value, onChange }) => {
  return (
    <label className={cn(style.container, required && style.disable)}>
      <div className={cn(style.header)}>
        <div className={cn(style.checkbox)}>
          <Check label={label} value={value} disabled={required} onChange={onChange} />
        </div>
        <div className={cn(style.price)}>{price}</div>
      </div>
      {required && <div className={cn(style.required)}>This addon is required</div>}
      <div className={cn(style.description)}>{description}</div>
    </label>
  );
};

AddonCheckbox.propTypes = {
  label: PropTypes.string.isRequired,
  price: PropTypes.string,
  description: PropTypes.string,
  required: PropTypes.bool,
  value: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
};

export default AddonCheckbox;
