import { useEffect, useState } from 'react';
import { createContainer } from 'unstated-next';
import { GET_ADDONS, GET_FOUND_TYPES, GET_OCCASIONS, GET_RECEIPTS, GET_SETTINGS, SEND_RESERVATION } from '../../utils/Api';
import useFetch from '../../utils/UseFetch';
import BookingStore from '../BookingStore';
import simpleValidator from '../../utils/SimpleValidator';
import { personalDetailRules, settingRulesForPrivate, settingRulesForPublic, setUpRules } from '../../componenets/Form/ValidationSchema';
import { usePersistState } from 'persist-state';

function useContainer() {
  const bookingStore = BookingStore.useContainer();

  const [settings, setSettings] = useState(null);

  const [email, setEmail] = usePersistState('', 'reservationEmail');
  const [isEmailDisable, setIsEmailDisable] = usePersistState(false, 'reservationIsEmailDisable');
  const [firstName, setFirstName] = usePersistState('', 'reservationFirstName');
  const [lastName, setLastName] = usePersistState('', 'reservationLastName');
  const [confirmEmail, setConfirmEmail] = usePersistState('', 'reservationConfirmEmail');
  const [address, setAddress] = usePersistState('', 'reservationAddress');
  const [address2, setAddress2] = usePersistState('', 'reservationAddress2');
  const [city, setCity] = usePersistState('', 'reservationCity');
  const [state, setState] = usePersistState('', 'reservationState');
  const [phone, setPhone] = usePersistState('', 'reservationPhone');
  const [country, setCountry] = usePersistState('', 'reservationCountry');

  const [promoCode, setPromoCode] = usePersistState('', 'reservationPromoCode');
  const [isPromoCodeValid, setIsPromoCodeValid] = usePersistState(false, 'reservationIsPromoCodeValid');
  const [promoCodeLoading, setPromoCodeLoading] = usePersistState(false, 'reservationPromoCodeLoading');
  const [boatPass, setBoatPass] = usePersistState('', 'reservationBoatPass');
  const [isBoatPassValid, setIsBoatPassValid] = usePersistState('', 'reservationIsBoatPassValid');
  const [boatPassType, setBoatPassType] = usePersistState({}, 'reservationBoatPassType');
  const [boatPassLoading, setBoatPassLoading] = usePersistState('', 'reservationBoatPassLoading');
  const [occasion, setOccasion] = usePersistState(null, 'reservationOccasion');
  const [foundType, setFoundType] = usePersistState(null, 'reservationFoundType');
  const [cancellationProtection, setCancellationProtection] = usePersistState(false, 'reservationCancellationProtection');
  const [isTipOptional, setIsTipOptional] = useState(false);

  const [tips, setTips] = usePersistState('10', 'reservationTips');
  const [agreeTerms, setAgreeTerms] = usePersistState(false, 'reservationAgreeTerms');
  const [securityDeposit, setSecurityDeposit] = usePersistState(false, 'reservationSecurityDeposit');
  const [receiveEmails, setReceiveEmails] = usePersistState(false, 'reservationReceiveEmails');

  const [occasionData, setOccasionData] = usePersistState([], 'reservationOccasionData');
  const [foundTypeData, setFoundTypeData] = usePersistState([], 'reservationFoundTypeData');
  const [isDataLoaded, setIsDataLoaded] = useState(false);

  const [addons, setAddons] = usePersistState([], 'reservationAddons');
  const [selectedAddons, setSelectedAddons] = usePersistState([], 'reservationSelectedAddons');
  const [insuranceList, setInsuranceList] = usePersistState([], 'reservationInsuranceList');
  const [selectedInsurance, setSelectedInsurance] = usePersistState('', 'reservationSelectedInsurance');

  const [allCost, setAllCost] = usePersistState({}, 'reservationAllCost');
  const [allCostLoading, setAllCostLoading] = usePersistState(false, 'reservationAllCostLoading');

  const [formError, setFormErrors] = usePersistState({}, 'reservationFormErrors');

  const [stripeClientSecret, setStripeClientSecret] = useState('');
  const [reservation, setReservation] = useState(null);

  const checkErrors = () => {
    const personalDetailsFields = { email, firstName, lastName, confirmEmail, address, city, state, phone, country };
    const setUpFields = { occasion, foundType, cancellationProtection };
    const settingFields = { agreeTerms, securityDeposit };

    let errors = {};

    Object.entries(personalDetailsFields).forEach(([name, value]) => {
      let param = {};

      switch (name) {
        case 'confirmEmail':
          param = {
            to: email,
          };
          break;
        case 'email':
          param = {
            to: confirmEmail,
          };
          break;
      }

      errors = {
        ...errors,
        [name]: {
          touched: true,
          error: simpleValidator(personalDetailRules[name], value, param),
        },
      };
    });

    Object.entries(setUpFields).forEach(([name, value]) => {
      errors = {
        ...errors,
        [name]: {
          touched: true,
          error: simpleValidator(setUpRules[name], value),
        },
      };
    });

    Object.entries(settingFields).forEach(([name, value]) => {
      if (bookingStore.type === 'private') {
        errors = {
          ...errors,
          [name]: {
            touched: true,
            error: simpleValidator(settingRulesForPrivate[name], value),
          },
        };
      }

      if (bookingStore.type === 'public') {
        errors = {
          ...errors,
          [name]: {
            touched: true,
            error: simpleValidator(settingRulesForPublic[name], value),
          },
        };
      }
    });

    setFormErrors(errors);

    return !Object.values(errors).every((item) => item.error === null);
  };

  const getOccasion = async () => {
    const data = await useFetch('get', GET_OCCASIONS);
    setOccasionData(data);
  };

  const getFoundType = async () => {
    const data = await useFetch('get', GET_FOUND_TYPES);
    setFoundTypeData(data);
  };

  const getAddons = async () => {
    if (!bookingStore.boatGroup?.id || !bookingStore.type || !bookingStore.selectedTimeSlot) return;

    const data = await useFetch('get', GET_ADDONS(bookingStore.boatGroup.id), {
      type: bookingStore.type,
      length: bookingStore.selectedTimeSlot.length,
      startAt: bookingStore.selectedTimeSlot.startAt,
    });

    setAddons(data);
    setSelectedAddons(data.filter((item) => item.isRequired));
  };

  const getAllCost = async () => {
    if (!bookingStore.selectedTimeSlot?.startAt || !bookingStore.selectedTimeSlot?.endAt) return;
    if (!bookingStore.boatGroup?.['@id']) return;
    if (!bookingStore.type) return;
    if (bookingStore.type === 'public' && !bookingStore.numberOfSeats) return;

    setAllCostLoading(true);

    const data = {
      startAt: bookingStore.selectedTimeSlot.startAt,
      endAt: bookingStore.selectedTimeSlot.endAt,
      boatGroup: bookingStore.boatGroup['@id'],
      cruiseType: bookingStore.type,
      addons: selectedAddons.map((item) => item.addon['@id']),
      hasCancellation: cancellationProtection,
      tipPercent: bookingStore?.boatGroup.isTipAvailable ? (tips ? +tips : null) : null,
      promocode: isPromoCodeValid ? promoCode : undefined,
      boatPassCode: isBoatPassValid ? boatPass : undefined,
    };

    if (selectedInsurance && bookingStore.type === 'private') {
      data.insurance = selectedInsurance;
    }

    if (bookingStore.type === 'public') {
      data.seats = bookingStore.numberOfSeats;
    }

    const response = await useFetch('post', GET_RECEIPTS, data);
    setAllCost(response);
    setAllCostLoading(false);
  };

  const getSettings = async () => {
    const data = await useFetch('get', GET_SETTINGS);
    setSettings(data);

    if (data?.tipPercent) {
      setTips(data?.tipPercent);
    }
  };

  const getData = async () => {
    if (isDataLoaded) return;

    await getOccasion();
    await getFoundType();
    await getSettings();

    setIsDataLoaded(true);
  };

  const sendReservation = async () => {
    if (!bookingStore.selectedTimeSlot) return false;
    if (!bookingStore.boatGroup) return false;
    if (!bookingStore.type) return false;
    if (bookingStore.type === 'public' && !bookingStore.numberOfSeats) return false;
    if (insuranceList.length && bookingStore.type === 'private' && !selectedInsurance) return false;
    if (!occasion) return false;
    if (!foundType) return false;
    if (!firstName) return false;
    if (!lastName) return false;
    if (!address) return false;
    if (!city) return false;
    if (!state) return false;
    if (!email) return false;
    if (!phone) return false;
    if (!isTipOptional) {
      if (!tips || +tips < (+settings?.tipPercent || 5) || +tips > 100) return;
    }
    if (!agreeTerms) return false;

    const data = {
      startAt: bookingStore.selectedTimeSlot.startAt,
      endAt: bookingStore.selectedTimeSlot.endAt,
      boatGroup: bookingStore.boatGroup['@id'],
      cruiseType: bookingStore.type,
      addons: selectedAddons.map((item) => item.addon['@id']),
      hasCancellation: cancellationProtection,
      survey: {
        occasion: `/api/occasions/${occasion}`,
        howDidYouHear: `/api/how-did-you-hears/${foundType}`,
      },
      customerAddress: {
        firstName: firstName,
        lastName: lastName,
        address: {
          line1: address,
          line2: address2,
          city: city,
          state: state,
          country: country,
          zip: '02111',
        },
        email: email,
        phone: phone.replaceAll('-', ''),
      },
      billingAddress: {
        firstName: firstName,
        lastName: lastName,
        address: {
          line1: address,
          line2: address2,
          city: city,
          state: state,
          country: country,
          zip: '02111',
        },
        email: email,
        phone: phone.replaceAll('-', ''),
      },
      notes: '',
      tipPercent: tips ? +tips : null,
      promocode: isPromoCodeValid ? promoCode : undefined,
      boatPassCode: isBoatPassValid ? boatPass : undefined,
      hasEmailNewsletter: receiveEmails,
    };

    if (bookingStore.type === 'public') {
      data.seats = +bookingStore.numberOfSeats;
    }
    if (bookingStore.type === 'private') {
      if (selectedInsurance && insuranceList.length) {
        data.insurance = selectedInsurance;
      }
    }

    const response = await useFetch('post', SEND_RESERVATION, data);

    if (response?.error) return response;

    setStripeClientSecret(response.clientSecret);
    setReservation(response.reservation);

    return true;
  };

  const clear = () => {
    setEmail('');
    setFirstName('');
    setLastName('');
    setConfirmEmail('');
    setAddress('');
    setAddress2('');
    setCity('');
    setState('');
    setPhone('');
    setCountry('');

    setPromoCode('');
    setOccasion(null);
    setFoundType(null);
    setCancellationProtection(false);

    setAgreeTerms(false);
    setSecurityDeposit(false);
    setReceiveEmails(false);

    setBoatPass('');
    setIsBoatPassValid(false);

    setIsPromoCodeValid(false);
    setIsEmailDisable(false);
  };

  const clearGoToBack = () => {
    setPromoCode('');
    setIsPromoCodeValid(false);
    setIsEmailDisable(false);
    setSelectedInsurance(null);
  };

  useEffect(() => {
    getAddons();
  }, [bookingStore.type, bookingStore.selectedTimeSlot, bookingStore.boatGroup]);

  useEffect(() => {
    getAllCost();
  }, [cancellationProtection, selectedAddons, selectedInsurance, bookingStore.numberOfSeats, bookingStore.type, bookingStore.boatGroup, bookingStore.selectedTimeSlot, tips, isPromoCodeValid, isBoatPassValid]);

  useEffect(() => {
    getData();
  }, []);

  return {
    settings,
    isDataLoaded,

    email,
    setEmail,
    isEmailDisable,
    setIsEmailDisable,
    firstName,
    setFirstName,
    lastName,
    setLastName,
    confirmEmail,
    setConfirmEmail,
    address,
    setAddress,
    address2,
    setAddress2,
    city,
    setCity,
    state,
    setState,
    phone,
    setPhone,
    country,
    setCountry,

    promoCode,
    setPromoCode,
    isPromoCodeValid,
    setIsPromoCodeValid,
    promoCodeLoading,
    setPromoCodeLoading,
    boatPass,
    setBoatPass,
    isBoatPassValid,
    setIsBoatPassValid,
    boatPassType,
    setBoatPassType,
    boatPassLoading,
    setBoatPassLoading,
    occasion,
    setOccasion,
    foundType,
    setFoundType,
    cancellationProtection,
    setCancellationProtection,
    isTipOptional,
    setIsTipOptional,

    tips,
    setTips,
    agreeTerms,
    setAgreeTerms,
    securityDeposit,
    setSecurityDeposit,
    receiveEmails,
    setReceiveEmails,

    formError,
    setFormErrors,
    checkErrors,

    occasionData,
    foundTypeData,
    addons,
    selectedAddons,
    setSelectedAddons,
    insuranceList,
    setInsuranceList,
    selectedInsurance,
    setSelectedInsurance,
    allCost,
    allCostLoading,

    sendReservation,
    stripeClientSecret,
    reservation,

    clear,
    clearGoToBack,
  };
}

export default createContainer(useContainer);
