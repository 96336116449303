import React from 'react';
import ReactDOM from 'react-dom/client';
import TagManager from 'react-gtm-module';
import { BrowserRouter } from 'react-router-dom';
import BookingStore from './store/BookingStore';
import App from './App';
import 'normalize.css';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import 'react-tippy/dist/tippy.css';
import './index.scss';
import ReservationStore from './store/ReservationStore';

const gtmId = process.env.REACT_APP_GOOGLE_TAG_MANAGER_ID;

if (gtmId) {
  TagManager.initialize({
    gtmId,
  });
}

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <BrowserRouter>
    <BookingStore.Provider>
      <ReservationStore.Provider>
        <App />
      </ReservationStore.Provider>
    </BookingStore.Provider>
  </BrowserRouter>,
);
