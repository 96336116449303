import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import style from './select.module.scss';
import icon from './img/chevron-down.png';
import useOnClickOutside from 'utils/UseOnClickOutside';

const Select = ({ label, hint, list, onChange, value, border }) => {
  const [selected, setSelected] = useState(list.find((item) => item.value === value) || null);
  const [show, setShow] = useState(false);
  const containerRef = useRef();

  useOnClickOutside(containerRef, () => setShow(false));

  const onFocus = () => {
    setShow(true);
  };

  const onBlur = () => {
    setShow(false);
  };

  const onKeyUp = (e, item) => {
    if (e.key !== 'Enter') return;

    setSelected(item);
    setShow(false);
  };

  const onTriggerClick = () => {
    setShow(!show);
  };

  const onItemClick = (item) => {
    setSelected(item);
    setShow(false);
    onChange(item);
  };

  useEffect(() => {
    if (!value) return;

    setSelected(list.find((item) => item.value === value) || null);
  }, []);

  return (
    <div
      className={cn(style.container, border && style.container2, {
        [style.show]: show,
      })}
      ref={containerRef}
    >
      <div className={cn(style.trigger)} onClick={onTriggerClick} onFocus={onFocus} onBlur={onBlur}>
        <div className={cn(style.block)}>
          <div className={cn(style.label)} dangerouslySetInnerHTML={{ __html: label }} />
          <div className={cn(style.hint)} title={selected?.label || selected?.name || hint}>
            {selected?.label || selected?.name || hint}
          </div>
        </div>
        <img src={icon} className={cn(style.icon)} />
      </div>
      <div className={cn(style.list)} onFocus={onFocus} onBlur={onBlur}>
        {list.map((item) => (
          <div
            className={cn(style.item, {
              [style.selected]: selected?.id === item.id,
            })}
            key={item.id}
            onClick={() => onItemClick(item)}
            onKeyUp={(e) => onKeyUp(e, item)}
            onFocus={() => setShow(true)}
            tabIndex={0}
          >
            {item.name || item.label}
          </div>
        ))}
      </div>
    </div>
  );
};

Select.propTypes = {
  label: PropTypes.string.isRequired,
  hint: PropTypes.string,
  list: PropTypes.array.isRequired,
  onChange: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  border: PropTypes.bool,
};

Select.defaultProps = {
  list: [],
  onChange: () => {},
};

export default Select;
