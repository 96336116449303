import React, { useEffect, useState } from 'react';
import cn from 'classnames';
import style from './socials.module.scss';
import facebookIcon from './img/facebook.png';
import twitterIcon from './img/twitter.png';
import instagramIcon from './img/instagram.png';
import tripadvisorIcon from './img/tripadvisor.png';
import PropTypes from 'prop-types';

const icons = {
  facebook: (
    <a href="https://www.facebook.com/chicycleboats/" target="_blank" rel="noreferrer" className={cn(style.link)} key={'facebook'}>
      <img src={facebookIcon} alt="facebook" />
    </a>
  ),
  twitter: (
    <a href="https://twitter.com/chicycleboats" target="_blank" rel="noreferrer" className={cn(style.link)} key={'twitter'}>
      <img src={twitterIcon} alt="twitter" />
    </a>
  ),
  instagram: (
    <a href="https://www.instagram.com/chicycleboats/" target="_blank" rel="noreferrer" className={cn(style.link)} key={'instagram'}>
      <img src={instagramIcon} alt="instagram" />
    </a>
  ),
  tripadvisor: (
    <a href="https://www.tripadvisor.com/Attraction_Review-g35805-d14084948-Reviews-Chicago_Cycleboats-Chicago_Illinois.html" target="_blank" rel="noreferrer" className={cn(style.link)} key={'tripadvisor'}>
      <img src={tripadvisorIcon} alt="tripadvisor" />
    </a>
  ),
};

const Socials = ({ list }) => {
  const [socials, setSocials] = useState([]);

  useEffect(() => {
    if (list && Array.isArray(list)) {
      const array = [];
      list.forEach((item) => {
        array.push(icons[item]);
      });
      setSocials(array);
    }
  }, [list]);

  return <div className={cn(style.container)}>{socials}</div>;
};

Socials.propTypes = {
  list: PropTypes.array,
};

Socials.defaultProps = {
  list: ['facebook', 'twitter', 'instagram', 'tripadvisor'],
};

export default Socials;
