import React, { useEffect, useState } from 'react';
import cn from 'classnames';
import style from './booking.module.scss';
import BookingStore from '../../store/BookingStore';
import Map from '../../componenets/Map';
import CommonCruise from '../../componenets/Booking/CommonCruise';
import { useNavigate } from 'react-router-dom';
import BoatGroupCard from '../../componenets/BoatGroupCard';
import anchorImage from './img/anchor.svg';
import CruiseTimeModal from './CruiseTimeModal';
import BookingModal from './BookingModal';
import Contact from '../../componenets/Contact';
import BookingMobile from '../../componenets/Booking/Mobile';
import searchImage from './img/search.svg';
import filterImage from './img/filter.svg';
import closeImage from './img/close.svg';
import mapDefault from './img/map-default.webp';
import getImageUrl from '../../utils/GetImageUrl';
import toggleBodyHidden from '../../utils/ToggleBodyHidden';

const Booking = () => {
  const bookingStore = BookingStore.useContainer();
  const navigate = useNavigate();
  const [isCruiseTimeModalActive, setIsCruiseTimeModalActive] = useState(false);
  const [isBookingModalActive, setIsBookingModalActive] = useState(false);
  const [isFilterModalShow, setIsFilterModalShow] = useState(false);
  const [triggerText, setTriggerText] = useState('...');

  const boatGroupButtonOnClick = (boatGroup, location) => {
    bookingStore.setBoatGroup(boatGroup);
    bookingStore.setLocation(location);
    bookingStore.setSelectedMarker({
      lat: location.coordinates.latitude,
      lng: location.coordinates.longitude,
    });
    setIsCruiseTimeModalActive(true);
  };

  const cruiseTimeModalOnClose = () => {
    setIsCruiseTimeModalActive(false);
    bookingStore.setSelectedTimeSlot(null);
  };

  const cruiseTimeModalOnSuccess = () => {
    setIsCruiseTimeModalActive(false);
    setIsBookingModalActive(true);
  };

  const bookingModalOnClose = () => {
    setIsBookingModalActive(false);
    setIsCruiseTimeModalActive(true);
  };

  const bookingModalOnSuccess = () => {
    setIsBookingModalActive(false);
    setIsCruiseTimeModalActive(false);

    if (process.env.REACT_APP_GOOGLE_TAG_MANAGER_ID && window.dataLayer.push) {
      window.dataLayer.push({
        event: 'CHECKOUT_PAGE',
        dataLayer: {
          date: bookingStore.date,
          type: bookingStore.type,
          length: bookingStore.length,
          numberOfSeats: bookingStore.numberOfSeats,
          boatGroup: bookingStore.boatGroup,
          location: bookingStore.location,
          timeSlot: bookingStore.selectedTimeSlot,
        },
      });
    }

    setTimeout(() => {
      navigate('/reservation');
    }, 15);
  };

  useEffect(() => {
    const type = bookingStore.typeList.find((item) => item.value === bookingStore.type)?.label;
    const seats = bookingStore.seatsSelectData.find((item) => item.value === bookingStore.numberOfSeats)?.label;
    const length = bookingStore.lengthSelectData.find((item) => item.value === bookingStore.length)?.label;
    setTriggerText(`${type} · ${bookingStore.date} · ${bookingStore.type === 'public' ? `${seats} seats · ` : ''}${length}`);
  }, [bookingStore.type, bookingStore.numberOfSeats, bookingStore.length, bookingStore.date]);

  useEffect(() => {
    if (!bookingStore.type || !bookingStore.date || !bookingStore.length) {
      navigate('/');
    }

    if (bookingStore.type === 'public' && !bookingStore.numberOfSeats) {
      navigate('/');
    }
  }, []);

  useEffect(() => {
    if (!isCruiseTimeModalActive && !isBookingModalActive) {
      setTimeout(() => {
        toggleBodyHidden(false);
      }, 200);
    }
  }, [isCruiseTimeModalActive, isBookingModalActive]);

  return (
    <>
      <div className={cn(style.container)}>
        <div className={cn(style.inner)}>
          <div className={cn(style.list)}>
            <div className={cn(style.booking)}>
              <CommonCruise />
            </div>
            <div className={cn(style.bookingMobile)} onClick={() => setIsFilterModalShow(true)}>
              <img src={searchImage} alt="" />
              <div className={cn(style.bookingMobileContent)}>
                <div>WHEN</div>
                <div>{triggerText}</div>
              </div>
              <img src={filterImage} alt="" />
            </div>
            {!bookingStore.availableCruises.length && (
              <div className={cn(style.notFound)}>
                <div className={cn(style.notFoundTitle)}>There are no available boats.</div>
                <div className={cn(style.notFoundDescription)}>Change search parameters in the form above and try again</div>
              </div>
            )}
            {bookingStore.availableCruises.map(({ '@id': cruiseId, boatGroups, location }) => (
              <div className={cn(style.block)} key={cruiseId}>
                <div className={cn(style.suptitle)}>Cycleboats in</div>
                <div className={cn(style.title)}>
                  <img src={anchorImage} alt="" />
                  <span>{location?.name}</span>
                </div>
                {boatGroups.map(({ '@id': boatGroupId, boatGroup, priceFrom, timeSlotType }) => (
                  <div key={boatGroupId}>
                    <div className={cn(style.separator)}></div>
                    <div className={cn(style.card)}>
                      <BoatGroupCard
                        id={boatGroup['@id']}
                        img={getImageUrl(boatGroup.image.contentUrl)}
                        gallery={boatGroup.gallery}
                        title={boatGroup.name}
                        description={boatGroup.description}
                        price={priceFrom}
                        shortDescription={boatGroup?.content?.shortDescription}
                        list1={boatGroup?.content?.list1}
                        list2={boatGroup?.content?.list2}
                        additionalInformation={boatGroup?.content?.additionalInformation}
                        type={timeSlotType}
                        onClick={() => boatGroupButtonOnClick(boatGroup, location)}
                      />
                    </div>
                  </div>
                ))}
              </div>
            ))}
          </div>
          {!bookingStore.loadingAvailableCruises ? (
            <div className={cn(style.map)}>{!!bookingStore?.markers?.length && <Map markers={bookingStore.markers} zoom={15} />}</div>
          ) : (
            <div className={cn(style.mapDefault)}>
              <img src={mapDefault} alt="Default map" />
            </div>
          )}
        </div>
      </div>
      <Contact dark={true} />
      <CruiseTimeModal active={isCruiseTimeModalActive} onClose={cruiseTimeModalOnClose} onSuccess={cruiseTimeModalOnSuccess} />
      <BookingModal active={isBookingModalActive} onClose={bookingModalOnClose} onSuccess={bookingModalOnSuccess} />
      <div className={cn(style.filterModal, isFilterModalShow && style.filterModalShow)}>
        <div className={cn(style.filterModalInner)}>
          <div className={cn(style.filterModalHeader)}>
            <div className={cn(style.filterModalTitle)}>Filter</div>
            <div className={cn(style.filterModalClose)} onClick={() => setIsFilterModalShow(false)}>
              <img src={closeImage} alt="" />
            </div>
          </div>
          <div className={cn(style.filterModalBody)}>
            <BookingMobile onClick={() => setIsFilterModalShow(false)} />
          </div>
        </div>
      </div>
    </>
  );
};

export default Booking;
