import React from 'react';
import Modal from '../../../componenets/Modal';
import cn from 'classnames';
import PropTypes from 'prop-types';
import style from './bookingModal.module.scss';
import BookingStore from '../../../store/BookingStore';
import Map from '../../../componenets/Map';
import getTimeSlotString from '../../../utils/GetTimeSlotString';

const BookingModal = ({ active, onClose, onSuccess }) => {
  const bookingStore = BookingStore.useContainer();

  if (!active) return null;

  return (
    <Modal active={active} title="Booking Request" subtitle="Check your chosen boat, time and place" onClose={onClose} onSuccess={onSuccess} successButtonDisable={!bookingStore.selectedTimeSlot} buttonCloseText="Back" buttonSuccessText="Book Now">
      <div className={cn(style.container)}>
        <div className={cn(style.map)}>{!!bookingStore.selectedMarker && <Map markers={[bookingStore.selectedMarker]} zoom={15} />}</div>
        <div className={cn(style.list)}>
          <div className={cn(style.item)}>
            <div className={cn(style.title)}>Type of cruise</div>
            <div className={cn(style.description)}>{bookingStore.type}</div>
          </div>
          {bookingStore.type !== 'private' && (
            <div className={cn(style.item)}>
              <div className={cn(style.title)}>Number of seats</div>
              <div className={cn(style.description)}>{bookingStore.numberOfSeats}</div>
            </div>
          )}
          <div className={cn(style.item)}>
            <div className={cn(style.title)}>Date</div>
            <div className={cn(style.description)}>{bookingStore.date}</div>
          </div>
          <div className={cn(style.item)}>
            <div className={cn(style.title)}>Time</div>
            <div className={cn(style.description)}>{getTimeSlotString(bookingStore.selectedTimeSlot)}</div>
          </div>
          <div className={cn(style.item)}>
            <div className={cn(style.title)}>Location</div>
            <div className={cn(style.description)}>{bookingStore.location?.name}</div>
          </div>
          <div className={cn(style.item)}>
            <div className={cn(style.title)}>Boat</div>
            <div className={cn(style.description)}>{bookingStore.boatGroup?.name}</div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

BookingModal.propTypes = {
  active: PropTypes.bool,
  onClose: PropTypes.func,
  onSuccess: PropTypes.func,
};

export default BookingModal;
