import React, { useEffect, useState } from 'react';
import cn from 'classnames';
import style from './reservation.module.scss';
import chevronLeftImage from './img/chevronLeft.svg';
import PersonalDetailsForm from '../../componenets/Form/PersonalDetailsForm';
import SetUpCruiseForm from '../../componenets/Form/SetUpCruiseForm';
import SettingForm from '../../componenets/Form/SettingForm';
import Button from '../../componenets/Button';
import Receipt from '../../componenets/Receipt';
import { useNavigate } from 'react-router-dom';
import BookingStore from '../../store/BookingStore';
import ReservationStore from '../../store/ReservationStore';
import SuccessModal from './SuccessModal';
import PaymentForm from './PaymentForm';
import ErrorModal from './ErrorModal';
import bannerDesktop from './img/banner/970x90.jpg';
import bannerMobile from './img/banner/480x60.jpg';
import useFetch from '../../utils/UseFetch';
import { GET_BOAT_GROUP } from '../../utils/Api';

const Reservation = () => {
  const bookingStore = BookingStore.useContainer();
  const reservationStore = ReservationStore.useContainer();
  const navigate = useNavigate();
  const [successModalShow, setSuccessModalShow] = useState(false);
  const [errorModalShow, setErrorModalShow] = useState(false);
  const [paymentModalShow, setPaymentModalShow] = useState(false);
  const [formLoading, setFormLoading] = useState(false);
  const [formError, setFormError] = useState({
    title: '',
    description: '',
  });
  const [currentBoatGroup, setCurrentBoatGroup] = useState(null);

  const goToBack = () => {
    navigate('/booking');
  };

  const getCurrentBoatGroup = async () => {
    if (!bookingStore?.boatGroup?.id) return;

    try {
      const data = await useFetch('get', GET_BOAT_GROUP(bookingStore.boatGroup.id));

      setCurrentBoatGroup(data);
      reservationStore.setInsuranceList(data?.insurances || []);

      if (data?.insurances?.length) {
        reservationStore.setSelectedInsurance(data.insurances[0]['@id']);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const sendGtagEvent = ({ transactionId }) => {
    if (process.env.REACT_APP_GOOGLE_TAG_MANAGER_ID && window.dataLayer.push) {
      window.dataLayer.push({
        event: 'SUCCESS_RESERVATION',
        dataLayer: {
          purchase: {
            transactionId,
            total: reservationStore.allCost.total,
            tax: reservationStore.allCost.tax,
            bookingFee: reservationStore.allCost.bookingFee,
            addons: reservationStore.allCost.addons?.map((addon) => ({
              id: addon.addon,
              price: addon.addonPrice,
              quantity: addon.subtotal / addon.addonPrice,
            })),
          },
          startAt: bookingStore.selectedTimeSlot.startAt,
          endAt: bookingStore.selectedTimeSlot.endAt,
          boatGroup: bookingStore.boatGroup['@id'],
          cruiseType: bookingStore.type,
          addons: reservationStore.selectedAddons.map((item) => item.addon['@id']),
          hasCancellation: reservationStore.cancellationProtection,
          survey: {
            occasion: `/api/occasions/${reservationStore.occasion}`,
            howDidYouHear: `/api/how-did-you-hears/${reservationStore.foundType}`,
          },
          customerAddress: {
            firstName: reservationStore.firstName,
            lastName: reservationStore.lastName,
            address: {
              line1: reservationStore.address,
              line2: reservationStore.address2,
              city: reservationStore.city,
              state: reservationStore.state,
              country: reservationStore.country,
              zip: '02111',
            },
            email: reservationStore.email,
            phone: reservationStore.phone.replaceAll('-', ''),
          },
          billingAddress: {
            firstName: reservationStore.firstName,
            lastName: reservationStore.lastName,
            address: {
              line1: reservationStore.address,
              line2: reservationStore.address2,
              city: reservationStore.city,
              state: reservationStore.state,
              country: reservationStore.country,
              zip: '02111',
            },
            email: reservationStore.email,
            phone: reservationStore.phone.replaceAll('-', ''),
          },
          notes: '',
          tipPercent: +reservationStore.tips,
          promocode: reservationStore.isPromoCodeValid ? reservationStore.promoCode : undefined,
          hasEmailNewsletter: reservationStore.receiveEmails,
        },
      });
    }
  };

  const sendForm = async () => {
    if (reservationStore.checkErrors()) return;

    setFormLoading(true);

    const response = await reservationStore.sendReservation();

    setFormLoading(false);

    if (response?.error) {
      setErrorModalShow(true);
      setFormError({
        description: response.description,
      });
      return;
    }

    if (!response) return;

    setPaymentModalShow(true);
    document.body.style.overflow = 'hidden';
  };

  const paymentFormCallback = (data) => {
    setPaymentModalShow(false);
    document.body.style.overflow = '';

    setTimeout(() => {
      sendGtagEvent(data);

      setSuccessModalShow(true);
    }, 15);
  };

  const successModalOnClose = () => {
    setSuccessModalShow(false);
    setPaymentModalShow(false);

    reservationStore.clear();
    bookingStore.clear();

    window.location.href = '/';
  };

  const errorModalOnClose = () => {
    setErrorModalShow(false);

    reservationStore.clear();
    bookingStore.clear();

    window.location.href = '/';
  };

  const bannerOnClick = () => {
    navigate('/boat-pass');
  };

  useEffect(() => {
    if (!bookingStore.type || !bookingStore.boatGroup || !bookingStore.date || !bookingStore.length || !bookingStore.selectedTimeSlot) {
      navigate('/');
    }

    if (bookingStore.type === 'public' && !bookingStore.numberOfSeats) {
      navigate('/');
    }

    return () => {
      reservationStore.clearGoToBack();
    };
  }, []);

  useEffect(() => {
    getCurrentBoatGroup();
  }, [bookingStore]);

  return (
    <>
      <div className={cn(style.container)}>
        {!reservationStore.isDataLoaded && <div className={style.loader}></div>}
        {bookingStore.type === 'private' && !process.env.REACT_APP_HIDE_BOAT_PASS && (
          <div className={cn(style.banner)}>
            <img src={bannerDesktop} alt="Save up to 60% with Boat Pass" onClick={bannerOnClick} />
            <img src={bannerMobile} alt="Save up to 60% with Boat Pass" onClick={bannerOnClick} />
          </div>
        )}
        <div className={cn(style.inner)}>
          <div className={cn(style.content)}>
            <div className={cn(style.header)}>
              <div className={cn(style.back)} onClick={goToBack}>
                <img src={chevronLeftImage} alt="" />
              </div>
              <div className={cn(style.title)}>Your boat reservation:</div>
              <div className={cn(style.subtitle)}>Please confirm your details below to complete your reservation.</div>
            </div>
            <div className={cn(style.block)}>
              <PersonalDetailsForm />
            </div>
            <div className={cn(style.block)}>
              <SetUpCruiseForm currentBoatGroup={currentBoatGroup} />
            </div>
            <div className={cn(style.block)}>
              <SettingForm currentBoatGroup={currentBoatGroup} />
            </div>
            {reservationStore.allCost?.total === 0 && bookingStore.type === 'private' ? (
              <div className={cn(style.block, style.blockNoPayment)}>
                Your total order cost is <strong>$0</strong>. To confirm your booking and ensure a security deposit, we'll authorize your credit card.
                <br />A temporary charge of <strong>$1</strong> will be applied and then refunded.
              </div>
            ) : null}
            <div className={cn(style.button)}>
              <Button text={formLoading ? 'Loading...' : 'Reserve'} onClick={sendForm} disable={formLoading} />
            </div>
          </div>
          <div className={cn(style.receipt)}>
            <div className={cn(style.header, style.headerMobile)}>
              <div className={cn(style.back)} onClick={goToBack}>
                <img src={chevronLeftImage} alt="" />
              </div>
              <div className={cn(style.title)}>Your boat reservation:</div>
              <div className={cn(style.subtitle)}>Please confirm your details below to complete your reservation.</div>
            </div>
            <div className={cn(style.receiptBlock)}>
              <Receipt />
            </div>
          </div>
        </div>
      </div>
      <SuccessModal active={successModalShow} onClose={successModalOnClose} />
      <ErrorModal active={errorModalShow} onClose={errorModalOnClose} description={formError.description} title={formError.title} />
      <div className={cn(style.payment, paymentModalShow && style.paymentActive)}>{reservationStore.stripeClientSecret ? <PaymentForm callback={paymentFormCallback} /> : <SuccessModal active={paymentModalShow} onClose={successModalOnClose} />}</div>
    </>
  );
};

export default Reservation;
