const validate = {
  required: (value) => !value,
  email: (value) => !/([+\w0-9._-]+@[\w0-9._-]+\.[\w0-9_-]+)/.test(value),
  phone: (value) => {
    const trimValue = value.replaceAll(/-|_/g, '');
    const length = trimValue.length;

    if (length === 0) return false;

    return !(length === 10);
  },
  equal: (value, params) => value !== params.to,
  min: (value, params) => !(+value >= +params.min),
  max: (value, params) => !(+value <= +params.max),
  integer: (value) => !Number.isInteger(+value),
};

export default function simpleValidator(rules, value, param) {
  const errors = [];

  if (!rules) return null;

  Object.entries(rules).map(([k, v]) => {
    if (validate[k](value, param)) {
      errors.push(v);
    }
  });

  return errors.length ? errors[0] : null;
}
