import React from 'react';
import HomeFirstScreen from 'componenets/HomeFirstScreen';
import CycleboatPartyBarge from 'componenets/CycleboatPartyBarge';
import Video from 'componenets/Video';
import AboutCycleboats from 'componenets/AboutCycleboats';
import AboutUs from 'componenets/AboutUs';
import Contact from 'componenets/Contact';
import Faq from 'componenets/Faq';
import video from './video/video.mp4';
import imagePoster from './img/poster.webp';
import style from './home.module.scss';
import cn from 'classnames';
import OurBoats from '../../componenets/OurBoats';

const Home = () => {
  return (
    <div className={cn(style.container)}>
      <span id="home" />
      <HomeFirstScreen />
      <OurBoats />
      <CycleboatPartyBarge video={video} imagePoster={imagePoster} />
      <div className={cn(style.video)}>
        <Video src={video} poster={imagePoster} />
      </div>
      <span id="about" />
      <AboutCycleboats />
      <AboutUs />
      <span id="faq" />
      <Faq />
      <span id="contact" />
      <Contact />
    </div>
  );
};

export default Home;
