import React from 'react';
import Modal from '../../../Modal';
import PropTypes from 'prop-types';
import ErrorIcon from './icon.svg';
import style from './promoCodeErrorModal.module.scss';
import cn from 'classnames';

const PromoCodeErrorModal = ({ active, onClose }) => {
  const title = (
    <div className={cn(style.title)}>
      <img src={ErrorIcon} alt="" className={cn(style.titleIcon)} />
      <div className={cn(style.titleText)}>Error</div>
    </div>
  );

  return <Modal active={active} title={title} subtitle="We couldn't find your promo code" buttonSuccessText="Close" hideCloseButton={true} onSuccess={onClose} />;
};

PromoCodeErrorModal.propTypes = {
  active: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default PromoCodeErrorModal;
