import React from 'react';
import style from './404.module.scss';
import cn from 'classnames';
import Button from '../../componenets/Button';

const NotFound = () => {
  return (
    <div className={cn(style.container)}>
      <div className={cn(style.inner)}>
        <div className={cn(style.number)}>404</div>
        <div className={cn(style.title)}>
          The page you were looking for
          <br /> does not exist
        </div>
        <div className={cn(style.button)}>
          <Button path={'/'} text="Go to home" />
        </div>
      </div>
    </div>
  );
};

export default NotFound;
