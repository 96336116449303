import React from 'react';
import cn from 'classnames';
import style from './booking.module.scss';
import PublicCruiseContent from './PublicCruiseContent';
import PrivateCruiseContent from './PrivateCruiseContent';
import BookingStore from '../../store/BookingStore';
import SelectBorder from '../SelectBorder';

const Booking = () => {
  const bookingStore = BookingStore.useContainer();

  const typeOnChange = (data) => {
    bookingStore.setType(data?.value);
  };

  return (
    <div className={cn(style.container)}>
      {bookingStore.typeList.length > 1 ? (
        <div className={cn(style.header)}>{bookingStore.settingsLoading ? <div className={cn(style.loading)}>Loading...</div> : <SelectBorder label="Select type of cruise" list={bookingStore.typeList} onChange={typeOnChange} required={true} value={bookingStore.type} />}</div>
      ) : null}
      {bookingStore.type === 'public' && !bookingStore.settingsLoading && <PublicCruiseContent />}
      {bookingStore.type === 'private' && !bookingStore.settingsLoading && <PrivateCruiseContent />}
    </div>
  );
};

export default Booking;
