import React from 'react';
import Modal from '../../../componenets/Modal';
import cn from 'classnames';
import PropTypes from 'prop-types';
import style from './sucessModal.module.scss';
import BookingStore from '../../../store/BookingStore';
import Map from '../../../componenets/Map';
import getTimeSlotString from '../../../utils/GetTimeSlotString';
import ReservationStore from '../../../store/ReservationStore';

const SuccessModal = ({ active, onClose }) => {
  const bookingStore = BookingStore.useContainer();
  const reservationStore = ReservationStore.useContainer();

  return (
    <Modal active={active} title="Success" subtitle={`Your boat is reserved! Reservation ID ${reservationStore?.reservation?.id}`} buttonSuccessText="Close" hideCloseButton={true} onSuccess={onClose}>
      <div className={cn(style.container)}>
        <div className={cn(style.map)}>{!!bookingStore.selectedMarker && <Map markers={[bookingStore.selectedMarker]} />}</div>
        <div className={cn(style.list)}>
          <div className={cn(style.item)}>
            <div className={cn(style.title)}>Type of cruise</div>
            <div className={cn(style.description)}>{bookingStore.type}</div>
          </div>
          {bookingStore.type !== 'private' && (
            <div className={cn(style.item)}>
              <div className={cn(style.title)}>Number of seats</div>
              <div className={cn(style.description)}>{bookingStore.numberOfSeats}</div>
            </div>
          )}
          <div className={cn(style.item)}>
            <div className={cn(style.title)}>Date</div>
            <div className={cn(style.description)}>{bookingStore.date}</div>
          </div>
          <div className={cn(style.item)}>
            <div className={cn(style.title)}>Time</div>
            <div className={cn(style.description)}>{getTimeSlotString(bookingStore.selectedTimeSlot)}</div>
          </div>
          <div className={cn(style.item)}>
            <div className={cn(style.title)}>Location</div>
            <div className={cn(style.description)}>{bookingStore.location?.name}</div>
          </div>
          <div className={cn(style.item)}>
            <div className={cn(style.title)}>Boat</div>
            <div className={cn(style.description)}>{bookingStore.boatGroup?.name}</div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

SuccessModal.propTypes = {
  active: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
};

export default SuccessModal;
