import React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import style from './contacCatd.module.scss';

const ContactCard = ({ icon, title, description, link, hint }) => {
  return (
    <div className={cn(style.container)}>
      <div className={cn(style.icon)}>
        <img src={icon} />
      </div>
      <div className={cn(style.title)}>{title}</div>
      <div className={cn(style.description)}>{description}</div>
      {link.href ? (
        <a href={link.href} target="_blank" className={cn(style.link)}>
          {link.label}
        </a>
      ) : (
        <span target="_blank" className={cn(style.link)}>
          {link.label}
        </span>
      )}
      <div className={cn(style.description)}>{hint}</div>
    </div>
  );
};

ContactCard.propTypes = {
  icon: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  link: PropTypes.object,
  hint: PropTypes.string,
};

ContactCard.defaultProps = {
  icon: '',
  title: '',
  description: '',
  link: {
    label: '',
    href: '',
  },
  hint: '',
};

export default ContactCard;
