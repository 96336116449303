import React, { useEffect } from 'react';
import { Routes, Route } from 'react-router-dom';
import Header from 'componenets/Header';
import Footer from 'componenets/Footer';
import Home from 'pages/Home';
import Booking from 'pages/Booking';
import Reservation from './pages/Reservation';
import TermsAndConditions from 'pages/TermsAndConditions';
import PrivacyPolicy from './pages/PrivacyPolicy';
import SecurityDeposit from 'pages/SecurityDeposit';
import NotFound from './pages/404';
import BoatPass from './pages/BoatPass';
import BoatPassBooking from './pages/BoatPassBooking';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './App.scss';
import axios from 'axios';
import ScrollToTop from './componenets/ScrollToTop';
import notification from './utils/Notification';
import useExternalScripts from './utils/UseExternalScripts';

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    notification({
      type: 'error',
      message: error.message,
    });
    console.error(error);

    return error;
  },
);

const GET_SITE_CONTROL_ID = process.env.REACT_APP_GET_SITE_CONTROL_ID;

const App = () => {
  if (process.env.REACT_APP_SHOW_POPUPS) {
    useExternalScripts({
      body: `(function (w,i,d,g,e,t,s) {w[d] = w[d]||[];t= i.createElement(g);t.async=1;t.src=e;s=i.getElementsByTagName(g)[0];s.parentNode.insertBefore(t, s);})(window, document, '_gscq','script','//widgets.getsitecontrol.com/194389/script.js');`,
    });
  }

  if (GET_SITE_CONTROL_ID) {
    useExternalScripts({
      url: `//l.getsitecontrol.com/${GET_SITE_CONTROL_ID}.js`,
      inHead: true,
    });
  }

  useEffect(() => {
    if (!window.gsc) return;

    window.gsc('trackPage', location.pathname);
  }, [location]);

  return (
    <>
      <div className="app">
        <Header />
        <main className="main">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/booking" element={<Booking />} />
            <Route path="/reservation" element={<Reservation />} />
            <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/security-deposit" element={<SecurityDeposit />} />
            {process.env.REACT_APP_HIDE_BOAT_PASS ? null : (
              <>
                <Route path="/boat-pass" element={<BoatPass />} />
                <Route path="/boat-pass-booking/:id" element={<BoatPassBooking />} />
              </>
            )}
            <Route path="*" element={<NotFound />} />
          </Routes>
        </main>
        <Footer />
      </div>
      <ScrollToTop />
      <ToastContainer position="bottom-right" autoClose={5000} bodyStyle={{ whiteSpace: 'break-spaces' }} hideProgressBar={true} closeOnClick pauseOnFocusLoss draggable pauseOnHover theme="dark" />
    </>
  );
};

export default App;
