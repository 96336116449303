import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import style from './radio.module.scss';

const Radio = ({ value, label, required, name, disabled, onChange: propOnChange, error, checked }) => {
  const onChange = () => {
    if (!propOnChange) return;

    propOnChange(value);
  };

  const triggerChange = (e) => {
    const { keyCode } = e;

    if (keyCode === 13 || keyCode === 32) {
      if (!propOnChange) return;

      propOnChange(!value);
    }
  };

  return (
    <label className={cn(style.container)}>
      <input type="radio" required={required} className={cn(style.input)} checked={checked} value={value} disabled={disabled} name={name} onChange={onChange} />
      <span className={cn(style.circle, error && style.circleError)} onKeyUp={triggerChange} />
      <span className={cn(style.label)}>{label}</span>
    </label>
  );
};

Radio.propTypes = {
  value: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  required: PropTypes.bool,
  name: PropTypes.string,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  error: PropTypes.bool,
  checked: PropTypes.bool,
};

Radio.defaultProps = {
  value: false,
  label: '',
  required: false,
  name: '',
};

export default Radio;
