import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import style from './check.module.scss';

const Check = ({ value, label, required, name, disabled, onChange: propOnChange, error }) => {
  const onChange = (e) => {
    if (!propOnChange) return;

    propOnChange(e.target.checked);
  };

  const triggerChange = (e) => {
    const { keyCode } = e;

    if (keyCode === 13 || keyCode === 32) {
      if (!propOnChange) return;

      propOnChange(!value);
    }
  };

  return (
    <label className={cn(style.container)}>
      <input type="checkbox" required={required} className={cn(style.input)} checked={value} disabled={disabled} name={name} onChange={onChange} />
      <span className={cn(style.rect, error && style.rectError)} onKeyUp={triggerChange} />
      <span className={cn(style.label)}>{label}</span>
    </label>
  );
};

Check.propTypes = {
  value: PropTypes.bool,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  required: PropTypes.bool,
  name: PropTypes.string,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  error: PropTypes.bool,
};

Check.defaultProps = {
  value: false,
  label: '',
  required: false,
  name: '',
};

export default Check;
