import { toast } from 'react-toastify';

const notification = ({ type, message }) => {
  const option = {
    style: {
      whiteSpace: 'pre',
    },
  };

  switch (type) {
    case 'error':
      toast.error(message, option);
      break;
    case 'info':
      toast.info(message, option);
      break;
    case 'warning':
      toast.warning(message, option);
      break;
    case 'success':
      toast.success(message, option);
      break;
    default:
      toast(message);
      break;
  }
};

export default notification;
