import React, { useState, useEffect } from 'react';
import style from './header.module.scss';
import cn from 'classnames';
import Logo from 'componenets/Logo';
import Menu from 'componenets/Menu';
import Socials from 'componenets/Socials';
import burgerIcon from './img/burger.png';
import closeIcon from './img/close.png';
import { useLocation } from 'react-router-dom';
import MobileMenu from '../MobileMenu';

const Header = () => {
  const location = useLocation();
  const [show, setShow] = useState(true);
  const [scrollYPos, setScrollYPos] = useState(0);
  const [lastScrollYPos, setLastScrollYPos] = useState(0);
  const [isMobileMenuShow, setIsMobileMenuShow] = useState(false);
  const withBorder = location.pathname !== '/';

  useEffect(() => {
    const onScroll = () => {
      setScrollYPos(window.scrollY);
    };

    window.addEventListener('scroll', onScroll);

    onScroll();

    return () => {
      window.removeEventListener('scroll', onScroll);
    };
  });

  useEffect(() => {
    setShow(lastScrollYPos > scrollYPos || scrollYPos <= 100);
    setLastScrollYPos(scrollYPos);
  }, [scrollYPos]);

  const toggleMobileMenu = () => {
    setIsMobileMenuShow(!isMobileMenuShow);
  };

  return (
    <>
      <header
        className={cn(style.container, {
          [style.show]: show,
          [style.filed]: show && scrollYPos >= 100,
          [style.border]: withBorder,
        })}
      >
        <div className={cn(style.inner)}>
          <div className={cn(style.logo)}>
            <Logo type={'white'} />
          </div>
          <div className={cn(style.menu)}>
            <Menu />
          </div>
          <div className={cn(style.socials)}>
            <Socials />
          </div>
          <div className={cn(style.menuTrigger)} onClick={toggleMobileMenu}>
            {isMobileMenuShow ? <img src={closeIcon} alt="close menu trigger" /> : <img src={burgerIcon} alt="open menu trigger" />}
          </div>
        </div>
      </header>
      <MobileMenu status={isMobileMenuShow} setStatus={setIsMobileMenuShow} />
    </>
  );
};

export default Header;
