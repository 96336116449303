export const GET_OCCASIONS = `${process.env.REACT_APP_API_URL}/api/occasions`;
export const GET_FOUND_TYPES = `${process.env.REACT_APP_API_URL}/api/how-did-you-hears`;
export const GET_SETTINGS = `${process.env.REACT_APP_API_URL}/api/client/settings`;
export const GET_AVAILABLE_CRUISES = `${process.env.REACT_APP_API_URL}/api/client/available-cruises`;
export const GET_BOAT_GROUP_AVAILABLE_CRUISES = `${process.env.REACT_APP_API_URL}/api/client/group-available-cruises`;
export const GET_ADDONS = (id) => `${process.env.REACT_APP_API_URL}/api/client/boat-groups/${id}/cruise-addons`;
export const GET_RECEIPTS = `${process.env.REACT_APP_API_URL}/api/client/reservation-receipts`;
export const SEND_RESERVATION = `${process.env.REACT_APP_API_URL}/api/client/reservations`;
export const GET_BOAT_GROUPS = `${process.env.REACT_APP_API_URL}/api/client/boat-groups`;
export const GET_BOAT_GROUP = (id) => `${process.env.REACT_APP_API_URL}/api/client/boat-groups/${id}`;
export const GET_BOAT_GROUPS_MAX_CAPACITY = `${process.env.REACT_APP_API_URL}/api/boat-group-settings`;
export const RESERVATION_PROMO_CODE_VALIDATION = `${process.env.REACT_APP_API_URL}/api/client/reservation-promocode-validation`;
export const RESERVATION_BOAT_PASS_VALIDATION = `${process.env.REACT_APP_API_URL}/api/client/reservation-boat-pass-validation`;
export const GET_LOCATIONS = `${process.env.REACT_APP_API_URL}/api/client/locations`;
export const CONTACT_FORM = `${process.env.REACT_APP_API_URL}/api/client/contact-forms`;
export const GET_MONTH_AVAILABLE_DAYS = `${process.env.REACT_APP_API_URL}/api/client/month-available-days`;
export const GET_GROUP_MONTH_AVAILABLE_DAYS = `${process.env.REACT_APP_API_URL}/api/client/group-month-available-days`;
export const GET_BLOCK_DATA = (key) => `${process.env.REACT_APP_API_URL}/api/client/blocks/${key}`;
export const GET_MEDIA_OBJECT_BY_ID = (id) => `${process.env.REACT_APP_API_URL}/api/media-objects/${id}`;
export const GET_BOAT_PASS_TYPES = `${process.env.REACT_APP_API_URL}/api/client/boat-pass-types`;
export const GET_BOAT_PASS_TYPE_BY_ID = (id) => `${process.env.REACT_APP_API_URL}/api/client/boat-pass-types/${id}`;
export const GET_BOAT_PASS_TYPE_RECEIPT_BY_ID = `${process.env.REACT_APP_API_URL}/api/client/boat-pass-receipts`;
export const SEND_BOAT_PASS = `${process.env.REACT_APP_API_URL}/api/client/boat-passes`;
