import React, { useState } from 'react';
import cn from 'classnames';
import style from './boatGroupCard.module.scss';
import PropTypes from 'prop-types';
import Button from '../Button';
import BoatGroupCardPreviewModal from './Modal';

// eslint-disable-next-line no-unused-vars
const BoatGroupCard = ({ id, img, gallery, title, description, onClick, price, shortDescription, list1, list2, additionalInformation, type }) => {
  const buttonOnClick = () => {
    onClick(id);
  };

  const [isPreviewModalOpen, setIsPreviewModalOpen] = useState(false);

  return (
    <>
      <div className={cn(style.container)}>
        <div className={cn(style.image)}>
          <div className={cn(style.previewButton)} onClick={() => setIsPreviewModalOpen(true)}>
            Preview
          </div>
          <img src={img} alt="" onClick={() => setIsPreviewModalOpen(true)} />
        </div>
        <div className={cn(style.content)}>
          <div className={cn(style.title)} onClick={() => setIsPreviewModalOpen(true)}>
            {title}
          </div>
          <div className={cn(style.separator)} />
          <div className={cn(style.description)} dangerouslySetInnerHTML={{ __html: shortDescription }} />
          <div className={cn(style.separator)} />
          <div className={cn(style.meta)}>
            {/*<div className={cn(style.interests)}>6 passengers · Bluetooth Sound System · Built-In Collers · Fun!</div>*/}
            <div className={cn(style.price)}>
              Starting at <mark>${price}</mark>
            </div>
          </div>
          <div className={cn(style.button)}>
            <Button text="Book Now" onClick={buttonOnClick} />
          </div>
        </div>
      </div>
      {isPreviewModalOpen && <BoatGroupCardPreviewModal image={img} isOpen={isPreviewModalOpen} setIsOpen={setIsPreviewModalOpen} description={description} title={title} gallery={gallery} list1={list1} list2={list2} additionalInformation={additionalInformation} />}
    </>
  );
};

BoatGroupCard.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  img: PropTypes.string.isRequired,
  gallery: PropTypes.arrayOf(PropTypes.string).isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  price: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  shortDescription: PropTypes.string,
  list1: PropTypes.string,
  list2: PropTypes.string,
  additionalInformation: PropTypes.string,
  type: PropTypes.string,
};

export default BoatGroupCard;
