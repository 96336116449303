import React from 'react';
import cn from 'classnames';
import style from './securityDeposit.module.scss';
import '../../assets/scss/componenets/content.scss';
import ReservationStore from '../../store/ReservationStore';

const SecurityDeposit = () => {
  const reservationStore = ReservationStore.useContainer();

  return (
    <div className={cn(style.container)}>
      <div className={cn(style.inner)}>
        <div className="content" style={{ maxWidth: 780, margin: 'auto' }}>
          <h1>Security deposit</h1>
          <p>Before the scheduled departure of your cruise, you will be required to thoroughly and carefully read through and fill out a rental agreement.</p>
          <p>
            As signatory, you assent to the full range of responsibilities and duties expressly and implicitly stated in any and all contracts entered into between you, as signatory, and Chicago Electric Boat Company. Chicago Electric Boat Company, LLC requires a major credit card to be authorized
            at the time of a rental, with available funds of at least <strong>${reservationStore.settings?.securityDeposit}</strong>, be placed on file for the purposes of holding a security deposit. The security deposit will be refunded in its full amount, immediately after the vessel has been
            returned in the same condition as it departed.
          </p>
          <p>
            The rental agreement is available for review prior to your arrival at your request, via email or phone. All passengers, including the responsible party (signatory of rental agreement and terms and conditions), will be encouraged to thoroughly and carefully read the agreement before
            signing. All passengers are required to carefully watch a safety video prior to boarding and give their undivided attention to a thorough on-vessel orientation by a Chicago Electric Boat Company employee.
          </p>
        </div>
      </div>
    </div>
  );
};

export default SecurityDeposit;
