import React from 'react';
import cn from 'classnames';
import style from './map.module.scss';
import LeafletMap from './Leaflet';
import PropTypes from 'prop-types';

const getDifference = (a, b) => a - b;

const Map = ({ center, markers, zoom }) => {
  const latMax = Math.max(...markers.map((item) => item.lat));
  const latMin = Math.min(...markers.map((item) => item.lat));
  const lngMax = Math.max(...markers.map((item) => item.lng));
  const lngMin = Math.min(...markers.map((item) => item.lng));

  const latCenter = (latMax + latMin) / 2;
  const lngCenter = (lngMax + lngMin) / 2;

  const latMaxOffset = getDifference(latMax, latMin);
  const lngMaxOffset = getDifference(lngMax, lngMin);

  const _center = markers.length === 1 ? [markers[0].lat, markers[0].lng] : center || [latCenter, lngCenter];

  const _zoom = markers.length === 1 ? 16 : zoom || 10 - (latMaxOffset + lngMaxOffset);

  return (
    <div className={cn(style.container)}>
      <LeafletMap center={_center} markers={markers} zoom={_zoom} />
    </div>
  );
};

Map.propTypes = {
  center: PropTypes.array,
  markers: PropTypes.array,
  zoom: PropTypes.number,
};

export default Map;
