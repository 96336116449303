import L from 'leaflet';
import icon from './marker-active.png';

const iconActive = new L.Icon({
  iconUrl: icon,
  iconRetinaUrl: icon,
  iconAnchor: [16.5, 54],
  popupAnchor: null,
  shadowUrl: null,
  shadowSize: null,
  shadowAnchor: null,
  iconSize: new L.Point(33, 54),
});

export default iconActive;
