import React from 'react';
import InputMask from 'react-input-mask';
import PropTypes from 'prop-types';
import cn from 'classnames';
import style from '../field.module.scss';

const Input = ({ type, value, onFocus, onBlur, onChange, name, mask, min, max, disable }) => {
  if (type === 'textarea') {
    return <textarea value={value} name={name} className={cn(style.input)} onFocus={onFocus} onBlur={onBlur} onInput={onChange} disabled={disable}></textarea>;
  }

  if (mask) {
    return (
      <InputMask mask={mask} value={value} onFocus={onFocus} onBlur={onBlur} onChange={onChange}>
        {(inputProps) => <input {...inputProps} type={type} name={name} className={cn(style.input)} min={min} max={max} />}
      </InputMask>
    );
  }

  return <input type={type} value={value} name={name} className={cn(style.input)} onFocus={onFocus} onBlur={onBlur} onInput={onChange} min={min} max={max} disabled={disable} onWheel={(e) => e.target.blur()} />;
};

Input.propTypes = {
  type: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  name: PropTypes.string,
  mask: PropTypes.string,
  min: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  max: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  disable: PropTypes.bool,
};

export default Input;
