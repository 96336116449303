import React, { useRef, useState, useEffect } from 'react';
import cn from 'classnames';
import style from '../form.module.scss';
import Field from '../../Field';
import simpleValidator from '../../../utils/SimpleValidator';
import ReservationStore from '../../../store/ReservationStore';
import capitalizeFirstLetter from '../../../utils/CapitalizeFirstLetter';
import { personalDetailRules } from '../ValidationSchema';

const PersonalDetails = () => {
  const reservationStore = ReservationStore.useContainer();
  const formRef = useRef(null);
  const [isShow, setIsShow] = useState(true);

  const fieldOnChange = (name, value) => {
    let error = '';

    switch (name) {
      case 'confirmEmail':
        error = simpleValidator(personalDetailRules[name], value, { to: reservationStore.email });
        break;
      default:
        error = simpleValidator(personalDetailRules[name], value);
    }

    reservationStore.setFormErrors({
      ...reservationStore.formError,
      [name]: { touched: true, error },
    });

    reservationStore[`set${capitalizeFirstLetter(name)}`](value);
  };

  useEffect(() => {
    if (!reservationStore.formError.confirmEmail?.touched) return;

    fieldOnChange('confirmEmail', reservationStore.confirmEmail);
  }, [reservationStore.email]);

  return (
    <form ref={formRef} className={cn(style.container)}>
      <div className={cn(style.title, !isShow && style.titleMobileCollapse, style.titleMobileBorder)} onClick={() => setIsShow(!isShow)}>
        1. Your Personal Details
      </div>
      <div className={cn(style.content, !isShow && style.contentHide)}>
        <div className={cn(style.list)}>
          <div className={cn(style.item2)}>
            <Field type="text" required={true} placeholder="First Name" name="firstName" error={!!reservationStore.formError.firstName?.touched && !!reservationStore.formError.firstName?.error} value={reservationStore.firstName} onChange={(value) => fieldOnChange('firstName', value)} />
            {!!reservationStore.formError.firstName?.touched && !!reservationStore.formError.firstName?.error && <div className={cn(style.error)}>{reservationStore.formError.firstName.error}</div>}
          </div>
          <div className={cn(style.item2)}>
            <Field type="text" required={true} placeholder="Last Name" name="lastName" error={!!reservationStore.formError.lastName?.touched && !!reservationStore.formError.lastName?.error} value={reservationStore.lastName} onChange={(value) => fieldOnChange('lastName', value)} />
            {!!reservationStore.formError.lastName?.touched && !!reservationStore.formError.lastName?.error && <div className={cn(style.error)}>{reservationStore.formError.lastName.error}</div>}
          </div>
          <div className={cn(style.item2)}>
            <Field
              type="email"
              required={true}
              placeholder="Email"
              name="email"
              disable={reservationStore.isEmailDisable}
              error={!!reservationStore.formError.email?.touched && !!reservationStore.formError.email?.error}
              value={reservationStore.email}
              onChange={(value) => fieldOnChange('email', value)}
            />
            {!!reservationStore.formError.email?.touched && !!reservationStore.formError.email?.error && <div className={cn(style.error)}>{reservationStore.formError.email.error}</div>}
            {reservationStore.isPromoCodeValid && <div className={cn(style.error)}>You can't change an email, because promo code was applied</div>}
          </div>
          <div className={cn(style.item2)}>
            <Field
              type="email"
              required={true}
              placeholder="Confirm email"
              name="confirmEmail"
              error={!!reservationStore.formError.confirmEmail?.touched && !!reservationStore.formError.confirmEmail?.error}
              value={reservationStore.confirmEmail}
              onChange={(value) => fieldOnChange('confirmEmail', value)}
            />
            {!!reservationStore.formError.confirmEmail?.touched && !!reservationStore.formError.confirmEmail?.error && <div className={cn(style.error)}>{reservationStore.formError.confirmEmail.error}</div>}
          </div>
          <div className={cn(style.item2)}>
            <Field
              type="text"
              required={true}
              placeholder="Address (street name, house, apartment)"
              name="address"
              error={!!reservationStore.formError.address?.touched && !!reservationStore.formError.address?.error}
              value={reservationStore.address}
              onChange={(value) => fieldOnChange('address', value)}
            />
            {!!reservationStore.formError.address?.touched && !!reservationStore.formError.address?.error && <div className={cn(style.error)}>{reservationStore.formError.address.error}</div>}
          </div>
          <div className={cn(style.item2)}>
            <Field type="text" placeholder="Address (optional)" name="address2" error={!!reservationStore.formError.address2?.touched && !!reservationStore.formError.address2?.error} value={reservationStore.address2} onChange={(value) => fieldOnChange('address2', value)} />
            {!!reservationStore.formError.address2?.touched && !!reservationStore.formError.address2?.error && <div className={cn(style.error)}>{reservationStore.formError.address2.error}</div>}
          </div>
          <div className={cn(style.item2)}>
            <Field type="text" required={true} placeholder="City" name="city" error={!!reservationStore.formError.city?.touched && !!reservationStore.formError.city?.error} value={reservationStore.city} onChange={(value) => fieldOnChange('city', value)} />
            {!!reservationStore.formError.city?.touched && !!reservationStore.formError.city?.error && <div className={cn(style.error)}>{reservationStore.formError.city.error}</div>}
          </div>
          <div className={cn(style.item2)}>
            <Field type="text" required={true} placeholder="State" name="state" error={!!reservationStore.formError.state?.touched && !!reservationStore.formError.state?.error} value={reservationStore.state} onChange={(value) => fieldOnChange('state', value)} />
            {!!reservationStore.formError.state?.touched && !!reservationStore.formError.state?.error && <div className={cn(style.error)}>{reservationStore.formError.state.error}</div>}
          </div>
          <div className={cn(style.item2)}>
            <Field type="tel" required={true} placeholder="Phone" name="phone" error={!!reservationStore.formError.phone?.touched && !!reservationStore.formError.phone?.error} mask="999-999-9999" value={reservationStore.phone} onChange={(value) => fieldOnChange('phone', value)} />
            {!!reservationStore.formError.phone?.touched && !!reservationStore.formError.phone?.error && <div className={cn(style.error)}>{reservationStore.formError.phone.error}</div>}
          </div>
          <div className={cn(style.item2)}>
            <Field type="text" required={true} placeholder="Country" name="country" error={!!reservationStore.formError.country?.touched && !!reservationStore.formError.country?.error} value={reservationStore.country} onChange={(value) => fieldOnChange('country', value)} />
            {!!reservationStore.formError.country?.touched && !!reservationStore.formError.country?.error && <div className={cn(style.error)}>{reservationStore.formError.country.error}</div>}
          </div>
        </div>
      </div>
    </form>
  );
};

export default PersonalDetails;
