import React, { useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import cn from 'classnames';
import PropTypes from 'prop-types';
import 'react-datepicker/dist/react-datepicker.css';
import style from './datepicker.module.scss';
import './style.scss';
import moment from 'moment';

const Single = ({ onChangeCallback, initialValue, inline, minDate, maxDate, monthOnChange, includeDates }) => {
  const [startDate, setStartDate] = useState(initialValue);

  const onChange = (data) => {
    setStartDate(data);
    onChangeCallback(data);
  };

  if (!includeDates.length)
    return (
      <DatePicker
        inline={inline}
        showPopperArrow={false}
        selected={startDate}
        onChange={onChange}
        minDate={minDate}
        maxDate={maxDate}
        onMonthChange={monthOnChange}
        customInput={<input type="text" className="datepicker-input form-control form-control-lg form-control-solid" placeholder="Select Date" />}
      />
    );

  return (
    <DatePicker
      inline={inline}
      showPopperArrow={false}
      selected={startDate}
      onChange={onChange}
      minDate={minDate}
      maxDate={maxDate}
      onMonthChange={monthOnChange}
      includeDates={includeDates}
      customInput={<input type="text" className="datepicker-input form-control form-control-lg form-control-solid" placeholder="Select Date" />}
    />
  );
};

Single.propTypes = {
  onChangeCallback: PropTypes.func.isRequired,
  initialValue: PropTypes.object,
  inline: PropTypes.bool,
  minDate: PropTypes.any,
  maxDate: PropTypes.any,
  monthOnChange: PropTypes.func,
  includeDates: PropTypes.array,
};

Single.defaultProps = {
  initialValue: null,
  inline: false,
};

const Multiple = ({ onChangeCallback, initialValue, inline, minDate, maxDate, monthOnChange, includeDates }) => {
  const [dateRange, setDateRange] = useState(initialValue);
  const [startDate, endDate] = dateRange;

  const onChange = (data) => {
    setDateRange(data);
    onChangeCallback(data);
  };

  if (!includeDates.length)
    return (
      <DatePicker
        inline={inline}
        selectsRange
        startDate={startDate}
        endDate={endDate}
        onChange={onChange}
        minDate={minDate}
        maxDate={maxDate}
        onMonthChange={monthOnChange}
        customInput={<input type="text" className="datepicker-input form-control form-control-lg form-control-solid" placeholder="Select Date Range" />}
        isClearable={true}
      />
    );

  return (
    <DatePicker
      inline={inline}
      selectsRange
      startDate={startDate}
      endDate={endDate}
      onChange={onChange}
      minDate={minDate}
      maxDate={maxDate}
      onMonthChange={monthOnChange}
      includeDates={includeDates}
      customInput={<input type="text" className="datepicker-input form-control form-control-lg form-control-solid" placeholder="Select Date Range" />}
      isClearable={true}
    />
  );
};

Multiple.propTypes = {
  onChangeCallback: PropTypes.func.isRequired,
  initialValue: PropTypes.array,
  inline: PropTypes.bool,
  minDate: PropTypes.any,
  maxDate: PropTypes.any,
  monthOnChange: PropTypes.func,
  includeDates: PropTypes.array,
};

Multiple.defaultProps = {
  initialValue: [null, null],
  inline: false,
};

const Datepicker = ({ multiple, initialValue, inline, onChange, minDate, maxDate, availableDays, monthOnChange: _monthOnChange, loading }) => {
  const [data, setData] = useState(initialValue || null);
  const [dateRange, setDateRange] = useState(!Array.isArray(initialValue) ? [initialValue, initialValue] : initialValue);
  const [includeDates, setIncludeDates] = useState([]);

  const onChangeMultiple = (value) => {
    setDateRange(value);
    onChange(moment(value).format('MM/DD/YYYY'));
  };

  const onChangeSingle = (value) => {
    setData(value);
    onChange(moment(value).format('MM/DD/YYYY'));
  };

  const monthOnChange = (date) => {
    if (_monthOnChange) {
      _monthOnChange(moment(date).format('MM/DD/YYYY'));
    }
  };

  useEffect(() => {
    if (!availableDays.length) {
      setIncludeDates([]);
    }

    setIncludeDates([moment(availableDays[1], 'YYYY-MM-DD').subtract(1, 'M').toDate(), ...availableDays.map((item) => moment(item, 'YYYY-MM-DD').toDate()), moment(availableDays[1], 'YYYY-MM-DD').add(1, 'M').toDate()]);
  }, [availableDays]);

  return (
    <div className={cn(style.container)}>
      {!!loading && <div className={cn(style.loading)}>Loading...</div>}
      {multiple && <Multiple initialValue={dateRange} inline={inline} onChangeCallback={onChangeMultiple} minDate={minDate} maxDate={maxDate} monthOnChange={monthOnChange} includeDates={includeDates} />}
      {!multiple && <Single initialValue={data} inline={inline} onChangeCallback={onChangeSingle} minDate={minDate} maxDate={maxDate} monthOnChange={monthOnChange} includeDates={includeDates} />}
    </div>
  );
};

Datepicker.propTypes = {
  multiple: PropTypes.bool,
  initialValue: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  inline: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  minDate: PropTypes.any,
  maxDate: PropTypes.any,
  availableDays: PropTypes.arrayOf(PropTypes.string),
  monthOnChange: PropTypes.func,
  loading: PropTypes.bool,
};

Datepicker.defaultProps = {
  multiple: false,
  inline: false,
};

export default Datepicker;
