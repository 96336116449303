import React, { useEffect, useState } from 'react';
import cn from 'classnames';
import style from './aboutUs.module.scss';
import LightBox from '../LightBox';
import useFetch from '../../utils/UseFetch';
import { GET_BLOCK_DATA } from '../../utils/Api';
import getBlockDataByKey from '../../utils/GetBlockDataByKey';

const AboutUs = () => {
  const [isLightBoxOpen, setIsLightBoxOpen] = useState(false);
  const [activeLightBoxIndex, setActiveLightBoxIndex] = useState(0);
  const [fields, setFields] = useState([]);

  const openLightBox = (index) => {
    setActiveLightBoxIndex(index);
    setIsLightBoxOpen(true);
  };

  const getContent = async () => {
    try {
      const response = await useFetch('get', GET_BLOCK_DATA('about_us'));

      setFields(response.fields);
    } catch (error) {
      console.error(error);
    }
  };

  const getGallery = () => getBlockDataByKey(fields, 'gallery')?.images?.slice(0, 4) || [];

  useEffect(() => {
    getContent();
  }, []);

  return (
    <>
      <div className={cn(style.container)}>
        <div className={cn(style.inner)}>
          <div className={cn(style.content)}>
            <div className={cn(style.uptitle)}>{getBlockDataByKey(fields, 'subtitle')?.text}</div>
            <div className={cn(style.title)}>{getBlockDataByKey(fields, 'title')?.text}</div>
            <div className={cn(style.description)} dangerouslySetInnerHTML={{ __html: getBlockDataByKey(fields, 'description')?.html }} />
          </div>
          <div className={cn(style.gallery)}>
            {getGallery().map((item, index) => (
              <img src={process.env.REACT_APP_API_URL + item.contentUrl} key={item?.['@id']} draggable={false} className={cn(style.galleryItem)} onClick={() => openLightBox(index)} />
            ))}
          </div>
        </div>
      </div>
      <LightBox activeIndex={activeLightBoxIndex} setActiveIndex={setActiveLightBoxIndex} isOpen={isLightBoxOpen} setIsOpen={setIsLightBoxOpen} list={getGallery().map((item) => process.env.REACT_APP_API_URL + item.contentUrl)} />
    </>
  );
};

export default AboutUs;
