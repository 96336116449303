import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import style from './button.module.scss';
import { Link } from 'react-router-dom';

const Button = ({ text, onClick, radius, type = 'button', disable, path, outline, loading, error }) => {
  const buttonStyle = {
    borderRadius: radius,
  };

  if (path)
    return (
      <Link className={cn(style.container, disable && style.disable, outline && style.outline, error && style.error)} to={path} style={buttonStyle}>
        <div className={cn(style.text)}>{text}</div>
      </Link>
    );

  return (
    <button type={type} className={cn(style.container, (disable || loading) && style.disable, outline && style.outline, error && style.error)} onClick={onClick} style={buttonStyle}>
      <div className={cn(style.text)}>{loading ? 'Loading...' : text}</div>
    </button>
  );
};

Button.propTypes = {
  text: PropTypes.string,
  onClick: PropTypes.func,
  radius: PropTypes.string,
  type: PropTypes.string,
  disable: PropTypes.bool,
  path: PropTypes.string,
  outline: PropTypes.bool,
  loading: PropTypes.bool,
  error: PropTypes.bool,
};

Button.defaultProps = {
  text: '',
  onClick: () => {},
  radius: '8px',
  disable: false,
};

export default Button;
