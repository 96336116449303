import axios from 'axios';

export default async function useFetch(method, url, data) {
  let _data = data;
  let _url = url;

  if (method === 'get') {
    _url = new URL(url);

    if (data) {
      Object.entries(data).forEach(([key, value]) => {
        _url.searchParams.append(key, value);
      });
    }

    _data = undefined;
  }

  const response = await axios({
    method,
    url: _url,
    data: _data,
  });

  if (response?.response?.status >= 400) {
    return {
      error: true,
      title: response?.response?.data?.['hydra:title'],
      description: response?.response?.data?.['hydra:description'],
    };
  }

  return response.data?.['hydra:member'] || response.data;
}
