import React from 'react';
import cn from 'classnames';
import Slider from './Slider';
import Booking from 'componenets/Booking';
import style from './homeFirstScreen.module.scss';
import BookingMobile from '../Booking/Mobile';

const HomeFirstScreen = () => {
  return (
    <div className={cn(style.container)}>
      <div className={cn(style.inner)}>
        <div className={cn(style.content)}>
          <h1 className={cn(style.title)}>Celebrate on a Cycleboat</h1>
          <div className={cn(style.booking)}>
            <div className={cn(style.bookingTitle)}>Book your cruise right now:</div>
            <Booking />
          </div>
          <div className={cn(style.bookingMobile)}>
            <BookingMobile />
          </div>
          <div className={cn(style.description)}>
            <p>From bachelor and bachelorette bashes to graduation festivities to birthday or anniversary parties, our boats offer an unforgettable venue for your next celebration. All of our boats include a captain and are BYOB, so everyone is able to celebrate!</p>
            <p>We also offer public cruises for smaller groups, including Chicago Fireworks Cruises as well as guided Chicago Ghost Tours and Chicago History Tours. Our boats serve as a new and exciting backdrop for Corporate Outings and Team Building Events.</p>
            <p>Scroll down to learn more about our many boat and tour options.</p>
          </div>
        </div>
        <div className={cn(style.slider)}>
          <Slider />
        </div>
      </div>
    </div>
  );
};

export default HomeFirstScreen;
