import React from 'react';
import cn from 'classnames';
import style from './receipt.module.scss';
import Map from '../Map';
import BookingStore from '../../store/BookingStore';
import ReservationStore from '../../store/ReservationStore';
import getTimeSlotString from '../../utils/GetTimeSlotString';
import moment from 'moment';
import { timeByMinutes } from '../../utils/TimeByMinutes';
import PropTypes from 'prop-types';

const Receipt = ({ type = 'default' }) => {
  const bookingStore = BookingStore.useContainer();
  const reservationStore = ReservationStore.useContainer();

  const getDate = () => {
    if (!bookingStore.selectedTimeSlot) return '';

    const date = moment(bookingStore.selectedTimeSlot.startAt, 'YYYY-MM-DDTHH:mm:ii').format('dddd MMMM D[th,] YYYY');

    return `${date}<br />${getTimeSlotString(bookingStore.selectedTimeSlot)}`;
  };

  const getCruiseLength = () => {
    if (!bookingStore.selectedTimeSlot) return '';

    return timeByMinutes(bookingStore.selectedTimeSlot.length);
  };

  const getNumberOfSeats = () => bookingStore.numberOfSeats || '';

  const getBoatPrice = () => {
    if (!reservationStore.allCost?.cruiseTotal) return '';
    return reservationStore.allCost.cruiseTotal;
  };

  const getAddonById = (id) => {
    if (!id) return '';

    return reservationStore.addons.find((item) => item?.addon?.['@id'] === id)?.addon;
  };

  const getNumberOfBookingHours = (hourly) => {
    if (!bookingStore.selectedTimeSlot.endAt || !bookingStore.selectedTimeSlot.startAt) return 1;
    const mStartAt = moment(bookingStore.selectedTimeSlot.startAt);
    const mEndAt = moment(bookingStore.selectedTimeSlot.endAt);
    const diffInHours = mEndAt.diff(mStartAt, 'minutes') / 60;

    return hourly ? Math.ceil(diffInHours) : 1;
  };

  if (type === 'mini') {
    return (
      <div className={cn(style.containerMini)}>
        <div className={cn(style.list)}>
          <div className={cn(style.item)}>
            <div className={cn(style.itemTitle)}>{bookingStore.boatGroup?.name}</div>
            <div className={cn(style.itemDescription)}>${getBoatPrice()}</div>
          </div>
          {reservationStore.isBoatPassValid ? null : (
            <div className={cn(style.itemItalic)}>
              <div className={cn(style.itemTitle)}>Tax {!!reservationStore.allCost?.taxRate && <mark>({reservationStore.allCost?.taxRate}%)</mark>}</div>
              <div className={cn(style.itemDescription)}>${reservationStore.allCost?.tax}</div>
            </div>
          )}
          {reservationStore.allCost?.tip?.amount && (
            <div className={cn(style.item)}>
              <div className={cn(style.itemTitle)}>Tips</div>
              <div className={cn(style.itemDescription)}>${reservationStore.allCost.tip.amount}</div>
            </div>
          )}
          {reservationStore.cancellationProtection && (
            <div className={cn(style.item)}>
              <div className={cn(style.itemTitle)}>Cancellation cost</div>
              <div className={cn(style.itemDescription)}>${reservationStore.allCost?.cancellationCost}</div>
            </div>
          )}
          {!!reservationStore.allCost?.insurance?.price && bookingStore.type === 'private' && (
            <>
              <div className={cn(style.item)}>
                <div className={cn(style.itemTitle)}>Insurance</div>
                <div className={cn(style.itemDescription)} />
              </div>
              <div className={cn(style.item, style.itemSmall)}>
                <div className={cn(style.itemTitle2)}>{reservationStore.allCost?.insurance?.name}</div>
                <div className={cn(style.itemDescription)}>${reservationStore.allCost?.insurance?.price}</div>
              </div>
            </>
          )}
          {!!reservationStore.allCost?.promotion?.cruiseDiscount && (
            <div className={cn(style.item)}>
              <div className={cn(style.itemTitle)}>Promo code</div>
              <div className={cn(style.itemDescription)}>- ${reservationStore.allCost.promotion.cruiseDiscount}</div>
            </div>
          )}
          {!reservationStore.isBoatPassValid ? null : (
            <div className={cn(style.item)}>
              <div className={cn(style.itemTitle)}>{reservationStore.boatPassType?.name}</div>
              <div className={cn(style.itemDescription)}>- ${getBoatPrice()}</div>
            </div>
          )}
          <div className={cn(style.item)}>
            <div className={cn(style.itemTitle)}>Booking fee (non-refundable)</div>
            <div className={cn(style.itemDescription)}>${reservationStore.isBoatPassValid ? 0 : reservationStore.allCost?.bookingFee}</div>
          </div>
          {!!reservationStore.allCost?.addons?.length && (
            <>
              <div className={cn(style.item)}>
                <div className={cn(style.itemTitle)}>Addons</div>
                <div className={cn(style.itemDescription)} style={{ opacity: '0.5' }}>
                  ${reservationStore.allCost?.addonsSubtotal}
                </div>
              </div>
              {reservationStore.allCost.addons.map(({ addon: addonId, addonPrice: price, taxes }) => (
                <div className={cn(style.itemAddon, style.itemSmall)} key={addonId}>
                  <div className={cn(style.itemTitle2)}>
                    {getAddonById(addonId)?.name} <mark>{getAddonById(addonId)?.priceType === 'hourly' ? `(${getAddonById(addonId)?.price}/hr)` : ''}</mark>
                  </div>
                  <div className={cn(style.itemDescription)}>${price * getNumberOfBookingHours(getAddonById(addonId)?.priceType === 'hourly')}</div>
                  {taxes.length ? (
                    <div className={cn(style.itemTaxes)}>
                      {taxes.map((tax) => (
                        <div className={cn(style.itemTax)} key={tax.id}>
                          <span>
                            {tax.name} ({tax.rate}%)
                          </span>
                          <span>${tax.amount}</span>
                        </div>
                      ))}
                    </div>
                  ) : null}
                </div>
              ))}
            </>
          )}
          <div className={cn(style.item)}>
            <div className={cn(style.itemTitle)}>
              Subtotal <i>(excl. taxes)</i>
            </div>
            <div className={cn(style.itemDescription)}>${reservationStore.allCost?.subtotal}</div>
          </div>
          <div className={cn(style.item)}>
            <div className={cn(style.itemTitle)}>Taxes total</div>
            <div className={cn(style.itemDescription)}>${reservationStore.allCost?.taxesTotal}</div>
          </div>
        </div>
        <div className={cn(style.separator)} />
        <div className={cn(style.item2)}>
          <div className={cn(style.itemTitle)}>Total</div>
          <div className={cn(style.itemDescription)}>${reservationStore.allCost?.total}</div>
        </div>
      </div>
    );
  }

  return (
    <div className={cn(style.container)}>
      {reservationStore.allCostLoading && <div className={cn(style.loader)} />}
      <div className={cn(style.title)} dangerouslySetInnerHTML={{ __html: getDate() }} />
      <div className={cn(style.data)}>
        <div className={cn(style.block)}>
          <div className={cn(style.blockTitle)}>{bookingStore.location?.name}</div>
          <div className={cn(style.blockDescription)}>
            {bookingStore.location?.address?.line1}
            {bookingStore.location?.address?.line2 ? (
              <>
                <br /> {bookingStore.location?.address?.line2}
              </>
            ) : null}
            <br /> {bookingStore.location?.address?.city}, {bookingStore.location?.address?.state} {bookingStore.location?.address?.zip}
          </div>
        </div>
        <div className={cn(style.block)}>
          <div className={cn(style.blockTitle)}>Hours Rented:</div>
          <div className={cn(style.blockDescription)}>{getCruiseLength()}</div>
          {!!getNumberOfSeats() && (
            <>
              <div className={cn(style.blockTitle)}>Seats:</div>
              <div className={cn(style.blockDescription)}>{getNumberOfSeats()}</div>
            </>
          )}
        </div>
      </div>
      <div className={cn(style.separator)} />
      <div className={cn(style.list)}>
        <div className={cn(style.item)}>
          <div className={cn(style.itemTitle)}>{bookingStore.boatGroup?.name}</div>
          <div className={cn(style.itemDescription)}>${getBoatPrice()}</div>
        </div>
        {reservationStore.isBoatPassValid ? null : (
          <div className={cn(style.itemItalic)}>
            <div className={cn(style.itemTitle)}>Tax {!!reservationStore.allCost?.taxRate && <mark>({reservationStore.allCost?.taxRate}%)</mark>}</div>
            <div className={cn(style.itemDescription)}>${reservationStore.allCost?.tax}</div>
          </div>
        )}
        {reservationStore.allCost?.tip?.amount && (
          <div className={cn(style.item)}>
            <div className={cn(style.itemTitle)}>
              Tips <mark>({reservationStore.allCost.tip?.percent}%)</mark>
            </div>
            <div className={cn(style.itemDescription)}>${reservationStore.allCost.tip?.amount}</div>
          </div>
        )}
        {reservationStore.cancellationProtection && (
          <div className={cn(style.item)}>
            <div className={cn(style.itemTitle)}>Cancellation cost</div>
            <div className={cn(style.itemDescription)}>${reservationStore.allCost?.cancellationCost}</div>
          </div>
        )}
        {!!reservationStore.allCost?.insurance?.price && bookingStore.type === 'private' && (
          <>
            <div className={cn(style.item)}>
              <div className={cn(style.itemTitle)}>Insurance</div>
              <div className={cn(style.itemDescription)} />
            </div>
            <div className={cn(style.item, style.itemSmall)}>
              <div className={cn(style.itemTitle2)}>{reservationStore.allCost?.insurance?.name}</div>
              <div className={cn(style.itemDescription)}>${reservationStore.allCost?.insurance?.price}</div>
            </div>
          </>
        )}
        {!!reservationStore.allCost?.promotion?.cruiseDiscount && (
          <div className={cn(style.item)}>
            <div className={cn(style.itemTitle)}>Promo code</div>
            <div className={cn(style.itemDescription)}>- ${reservationStore.allCost.promotion.cruiseDiscount}</div>
          </div>
        )}
        {!reservationStore.isBoatPassValid ? null : (
          <div className={cn(style.item)}>
            <div className={cn(style.itemTitle)}>{reservationStore.boatPassType?.name}</div>
            <div className={cn(style.itemDescription)}>- ${getBoatPrice()}</div>
          </div>
        )}
        <div className={cn(style.item)}>
          <div className={cn(style.itemTitle)}>Booking fee (non-refundable)</div>
          <div className={cn(style.itemDescription)}>${reservationStore.isBoatPassValid ? 0 : reservationStore.allCost?.bookingFee}</div>
        </div>
        {!!reservationStore.allCost?.addons?.length && (
          <>
            <div className={cn(style.item)}>
              <div className={cn(style.itemTitle)}>Addons</div>
              <div className={cn(style.itemDescription)} style={{ opacity: '0.5' }}>
                ${reservationStore.allCost?.addonsSubtotal}
              </div>
            </div>
            {reservationStore.allCost.addons.map(({ addon: addonId, addonPrice: price, taxes }) => (
              <div className={cn(style.itemAddon, style.itemSmall)} key={addonId}>
                <div className={cn(style.itemTitle2)}>
                  {getAddonById(addonId)?.name} <mark>{getAddonById(addonId)?.priceType === 'hourly' ? `(${getAddonById(addonId)?.price}/hr)` : ''}</mark>
                </div>
                <div className={cn(style.itemDescription)}>${price * getNumberOfBookingHours(getAddonById(addonId)?.priceType === 'hourly')}</div>
                {taxes.length ? (
                  <div className={cn(style.itemTaxes)}>
                    {taxes.map((tax) => (
                      <div className={cn(style.itemTax)} key={tax.id}>
                        <span>
                          {tax.name} ({tax.rate}%)
                        </span>
                        <span>${tax.amount}</span>
                      </div>
                    ))}
                  </div>
                ) : null}
              </div>
            ))}
          </>
        )}
        <div className={cn(style.item)}>
          <div className={cn(style.itemTitle)}>
            Subtotal <i>(excl. taxes)</i>
          </div>
          <div className={cn(style.itemDescription)}>${reservationStore.allCost?.subtotal}</div>
        </div>
        <div className={cn(style.item)}>
          <div className={cn(style.itemTitle)}>Taxes total</div>
          <div className={cn(style.itemDescription)}>${reservationStore.allCost?.taxesTotal}</div>
        </div>
      </div>
      <div className={cn(style.separator)} />
      <div className={cn(style.item2)}>
        <div className={cn(style.itemTitle)}>Total</div>
        <div className={cn(style.itemDescription)}>${reservationStore.allCost?.total}</div>
      </div>
      <div className={cn(style.map)}>{!!bookingStore.selectedMarker && <Map markers={[bookingStore.selectedMarker]} zoom={14} />}</div>
    </div>
  );
};

Receipt.propTypes = {
  type: PropTypes.string,
};

export default Receipt;
