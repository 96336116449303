import React from 'react';
import cn from 'classnames';
import style from '../cruiseContent.module.scss';
import Select from 'componenets/Select';
import Button from 'componenets/Button';
import Datepicker from '../../Datepicker';
import BookingStore from '../../../store/BookingStore';
import Dropdown from '../../Dropdown';
import { useNavigate } from 'react-router-dom';

const PublicCruiseContent = () => {
  const bookingStore = BookingStore.useContainer();
  const navigate = useNavigate();

  const dateOnChange = (data) => {
    bookingStore.setDate(data);
  };

  const seatsOnChange = (data) => {
    bookingStore.setNumberOfSeats(data?.value);
  };

  const lengthOnChange = (data) => {
    bookingStore.setLength(data?.value);
  };

  const isButtonDisable = () => {
    return !(bookingStore.date && bookingStore.numberOfSeats && bookingStore.length);
  };

  const buttonOnClick = async () => {
    await bookingStore.getAvailableCruises();
    navigate('/booking');
  };

  return (
    <div className={cn(style.container, style.containerPublic)}>
      <div className={cn(style.item)}>
        <Dropdown
          event={bookingStore.date}
          Content={<Datepicker inline={true} minDate={new Date()} onChange={dateOnChange} availableDays={bookingStore.availableDays} monthOnChange={bookingStore.getAvailableDates} loading={bookingStore.availableDaysLoading} />}
          label={'When'}
          hint={bookingStore.date}
        />
      </div>
      <div className={cn(style.separator)} />
      <div className={cn(style.item)}>
        <Select label={'cruise length'} list={bookingStore.lengthSelectData} onChange={lengthOnChange} value={bookingStore.length} />
      </div>
      <div className={cn(style.separator)} />
      <div className={cn(style.item)}>
        <Select label={'number of seats'} list={bookingStore.seatsSelectData} onChange={seatsOnChange} value={bookingStore.numberOfSeats} />
      </div>
      <div className={cn(style.separator)} />
      <div className={cn(style.button)}>
        <Button text={bookingStore.loadingAvailableCruises ? 'Loading...' : 'Next'} radius={'13px'} disable={isButtonDisable() || bookingStore.loadingAvailableCruises} onClick={buttonOnClick} />
      </div>
    </div>
  );
};

export default PublicCruiseContent;
