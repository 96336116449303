import React from 'react';
import cn from 'classnames';
import style from './cycleboatPartyBarge.module.scss';
import PropTypes from 'prop-types';
import Video from 'componenets/Video';

const CycleboatPartyBarge = ({ video, imagePoster }) => {
  return (
    <div className={cn(style.container)}>
      <div className={cn(style.inner)}>
        <div className={cn(style.uptitle)}>Chicago River Tours</div>
        <div className={cn(style.title)}>Cycleboat & Party Barge Tours</div>
        <div className={style.video}>
          <Video src={video} poster={imagePoster} />
        </div>
        <div className={cn(style.description)}>
          <p>Introducing the newest Chicago experience, the city’s first ever Cycleboat & Party Barge! This social, active adventure is the perfect way for friends, families, tourists, and locals to explore one of Chicago’s greatest summertime attractions.</p>
          <p>Parties can reserve the entire BYOB boat for up to 26 passengers – perfect for special occasions! Departing from the Riverwalk, passengers will be surrounded by the magnificent skyscrapers, historical bridges and bustling atmosphere.</p>
        </div>
      </div>
    </div>
  );
};

CycleboatPartyBarge.propTypes = {
  video: PropTypes.string,
  imagePoster: PropTypes.string,
};

export default CycleboatPartyBarge;
